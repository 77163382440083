<template>
    <!-- <div class="bg-abu-300 shadow-3" style="background-image: linear-gradient(120deg, #f5f0f0, #abdfd5);padding:0px;margin: 10px;border-radius: 8px;"> -->
    <div class="bg-abu-300 shadow-10 bg-gradku" style="padding:0px;margin: 10px;border-radius: 8px;">
      <div class="row">
        
        <div class="col-12"> 
          <div class="q-pa-md q-gutter-sm">
            <q-banner inline-actions rounded class="bg-brand abu-100 shadow-3">
              LEVEL AUTHENTICATION
              <template v-slot:action>  
                <q-btn size="md" @click="$store.state.mdl_add = true, getMenu()" class="bg-ijo-600" color="" icon="add" />
              </template>
            </q-banner>
          </div>      
        </div>   
        
  
  
        <!-- ============================== FILTER ============================== -->

        
  
    
        <div class="col-6"> 
          <div class="q-px-md q-pt-md"> 
 
          </div>      
        </div>

        <div class="col-6 col-md-6 col-xs-12 col-sm-12"> 
          <div class="q-px-md q-pt-md text-center"> 
                <q-input v-model="search" class="bg-white" color="primary" label-color="gray-16" outlined :dense="true" type="search" label="Pencarian">
                    <template v-slot:append> <q-icon name="search" /> </template>
                </q-input> 
              </div>  
        </div>
    
     
    
        <div class="col-12"> 
            <div class="q-pa-md">
              <q-markup-table style="width: 100%;" class="bg-abu-200">
              <thead class="bg-brand">
                <tr>
                  <th class="text-center  abu-100" style="width: 10%;">No</th>
                  <th class="text-left  abu-100" style="width: 80%;">Level Authentication</th>
                  <th class="text-center  abu-100" style="width: 10%;">Act</th>
                </tr>
              </thead>
    
              <tbody>
              <tr v-for="(data, index) in list_data" :key="data._id">
                <td class="text-center ijo-800">{{index+1}}.</td>
                <td class="text-left ">
                  <div>
                    <!-- <div class="ijo-800" style="font-size: 9pt;">NIK: 1657387687623</div> -->
                    <div class="ijo-900" style="text-transform: uppercase;"> <b>{{data.uraian}}  </b></div>
                  </div>
                </td>
                
                <td class="text-center">
                  <div class="q-gutter-xs">
                     
                    <q-btn round color="orange-5" icon="edit" size="sm" @click="$store.state.mdl_edit = true, selectData(data)">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Edit </q-tooltip>
                    </q-btn>
                    <q-btn round color="red-5" icon="delete" size="sm" @click="$store.state.mdl_del = true, selectData(data)">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Hapus </q-tooltip>
                    </q-btn>
                  </div>
                </td>
              </tr>
    
    
    
    
              </tbody>
              </q-markup-table>
            </div> 
        </div>
    
     
    
        <div class="col-12"> 
          <div class="q-px-md q-pt-md">
            <q-separator />
          </div>      
        </div>
    
    
      <div class="col-12">
        <div class="row q-py-md">
          <div class="col-12 col-md-12 col-sm-12 col-xs-12 q-pa-none flex flex-center">
              <q-pagination
                v-model="current"
                :max="5"
                input
                icon-first="skip_previous"
                icon-last="skip_next"
                icon-prev="fast_rewind"
                icon-next="fast_forward"
                color="brand"
              />
          </div>
          
 
    
        </div>
        
      </div>
  
  
  
  
  
      <!-- ============================== FILTER ============================== -->
      
      
    </div> 
  
  <MDL_ADD :modal="$store.state.mdl_add" :metode="addData"> 
    <template  v-slot:bodys>
      <div class="row q-gutter-sm">
 
        <div class="col-12"> 
            <div class="abu-900">Uraian  </div>
            <input v-model="form.uraian" class="f_select" type="text" >
            <!-- <pre>{{ list_menu }}</pre> -->
        </div>   

        <div class="col-12">
          <ul>
              <li v-for="data in list_menu" :key="data._id">
                  <span class="h_menu"> <b>{{data.nama}}</b> </span>
                  
                  <div v-if="data.type == 'single'">
                      <q-checkbox v-model="data.view" label="Lihat" color="cyan" />
                      <q-checkbox v-model="data.add" label="Tambah" color="teal" />
                      <q-checkbox v-model="data.edit" label="Edit" color="orange"/>
                      <q-checkbox v-model="data.remove" label="Hapus" color="red"/>
                      <q-checkbox v-model="data.approve" label="Approve" color="light-green-6"/>
                      <q-checkbox v-model="data.reject" label="Reject" color="deep-orange-10"/>
                  </div>

                  <ul>
                      <li v-for="data1 in data.submenu" :key="data1._id"  style="padding-top:10px">
                          {{data1.nama}}
                          <div v-if="data1.type == 'single'">
                              <q-checkbox v-model="data1.view" label="Lihat" color="cyan" />
                              <q-checkbox v-model="data1.add" label="Tambah" color="teal" />
                              <q-checkbox v-model="data1.edit" label="Edit" color="orange"/>
                              <q-checkbox v-model="data1.remove" label="Hapus" color="red"/>
                              <q-checkbox v-model="data1.approve" label="Approve" color="light-green-6"/>
                              <q-checkbox v-model="data1.reject" label="Reject" color="deep-orange-10"/>
                          </div>
                          <ul>
                              <li v-for="data2 in data1.submenu" :key="data2._id">
                                  {{data2.nama}}
                                  <div v-if="data2.type == 'single'">
                                      <q-checkbox v-model="data2.view" label="Lihat" color="cyan" />
                                      <q-checkbox v-model="data2.add" label="Tambah" color="teal" />
                                      <q-checkbox v-model="data2.edit" label="Edit" color="orange"/>
                                      <q-checkbox v-model="data2.remove" label="Hapus" color="red"/>
                                      <q-checkbox v-model="data2.approve" label="Approve" color="light-green-6"/>
                                      <q-checkbox v-model="data2.reject" label="Reject" color="deep-orange-10"/>
                                  </div>
                              </li>
                          </ul>
                      </li>
                  </ul> 
              </li>
          </ul>
        </div>

      </div>  
    </template> 
  </MDL_ADD>

  <MDL_EDT :modal="$store.state.mdl_edit" :metode="editData"> 
    <template  v-slot:bodys>
      <div class="row q-gutter-sm">
 
        <div class="col-12"> 
            <div class="abu-900">Uraian  </div>
            <input v-model="form.uraian" class="f_select" type="text" >
            <!-- <pre>{{ list_menu }}</pre> -->
        </div>   

        <div class="col-12">
          <ul>
              <li v-for="data in list_menu" :key="data._id">
                  <span class="h_menu"> <b>{{data.nama}}</b> </span>
                  
                  <div v-if="data.type == 'single'">
                      <q-checkbox v-model="data.view" label="Lihat" color="cyan" />
                      <q-checkbox v-model="data.add" label="Tambah" color="teal" />
                      <q-checkbox v-model="data.edit" label="Edit" color="orange"/>
                      <q-checkbox v-model="data.remove" label="Hapus" color="red"/>
                      <q-checkbox v-model="data.approve" label="Approve" color="light-green-6"/>
                      <q-checkbox v-model="data.reject" label="Reject" color="deep-orange-10"/>
                  </div>

                  <ul>
                      <li v-for="data1 in data.submenu" :key="data1._id"  style="padding-top:10px">
                          {{data1.nama}}
                          <div v-if="data1.type == 'single'">
                              <q-checkbox v-model="data1.view" label="Lihat" color="cyan" />
                              <q-checkbox v-model="data1.add" label="Tambah" color="teal" />
                              <q-checkbox v-model="data1.edit" label="Edit" color="orange"/>
                              <q-checkbox v-model="data1.remove" label="Hapus" color="red"/>
                              <q-checkbox v-model="data1.approve" label="Approve" color="light-green-6"/>
                              <q-checkbox v-model="data1.reject" label="Reject" color="deep-orange-10"/>
                          </div>
                          <ul>
                              <li v-for="data2 in data1.submenu" :key="data2._id">
                                  {{data2.nama}}
                                  <div v-if="data2.type == 'single'">
                                      <q-checkbox v-model="data2.view" label="Lihat" color="cyan" />
                                      <q-checkbox v-model="data2.add" label="Tambah" color="teal" />
                                      <q-checkbox v-model="data2.edit" label="Edit" color="orange"/>
                                      <q-checkbox v-model="data2.remove" label="Hapus" color="red"/>
                                      <q-checkbox v-model="data2.approve" label="Approve" color="light-green-6"/>
                                      <q-checkbox v-model="data2.reject" label="Reject" color="deep-orange-10"/>
                                  </div>
                              </li>
                          </ul>
                      </li>
                  </ul> 
              </li>
          </ul>
        </div>

      </div>  
    </template> 
  </MDL_EDT>

  <MDL_DEL :modal="$store.state.mdl_del" :metode="removeData"/>

 
  
  </div>
   
  
  </template>

<script>
  import FETCHING from "../../library/fetching";
  import UMUM from "../../library/umum";

  export default {
    data() {
      return {
        FETCHING  :FETCHING,
        UMUM      :UMUM,
        form : {
          _id : '',
          id : '',
          uraian : '',
          menu : [],
          createdAt : '',
        },
        current   :5,
        search    :'',

        list_menu :[],
        list_data :[],

        
      }
    },
    methods: {

      async getView(){ 
        this.$store.commit('loadingStart')
        var a = await FETCHING.fetch_view(this.$store.state.url.URL_LEVEL_USERS+'view')
        this.list_data = a
        this.$store.commit('loadingStop')
      },

      async getMenu(){
        // var a = this.
        this.$store.commit('loadingStart')
        var a = await FETCHING.fetch_view(this.$store.state.url.URL_MENU_LIST+'view1')
        this.list_menu = a
        this.$store.commit('loadingStop')
      },
      async addData(){
        this.$store.commit('loadingStart')
        this.form.createdAt = UMUM.NOW()
        this.form.menu = this.list_menu 
        var a = await FETCHING.fetchPost(this.$store.state.url.URL_LEVEL_USERS + "addData", 
              JSON.stringify({
                uraian    : this.form.uraian,
                id        : this.form.id,
                createdAt : this.form.createdAt,
                menu      : this.list_menu
              })
        )
        await UMUM.notifikasi(a)
        this.$store.commit('loadingStop') 
        this.getView(); 
      },
      async editData(){
        this.$store.commit('loadingStart')  
        var a = await FETCHING.fetchPost(this.$store.state.url.URL_LEVEL_USERS + "editData", 
              JSON.stringify({
                _id : this.form._id,
                uraian : this.form.uraian,
                id : this.form.id,
                createdAt : this.form.createdAt,
                menu : this.list_menu
                
              })
        )
        await UMUM.notifikasi(a)
        this.$store.commit('loadingStop') 
        this.getView(); 
      },
      async removeData(){
        this.$store.commit('loadingStart')  
        var a = await FETCHING.fetchPost(this.$store.state.url.URL_LEVEL_USERS + "removeData", 
              JSON.stringify(this.form)
        )
        await UMUM.notifikasi(a)
        this.$store.commit('loadingStop') 
        this.$store.state.mdl_del = false
        this.getView(); 
      },
    
    selectData(data){
      this.form._id                 = data._id
      this.form.id                  = data.id
      this.form.uraian              = data.uraian
      this.form.menu                = data.menu
      this.form.createdAt           = data.createdAt
      this.getMenuEdit(data.id);
    },

    async getMenuEdit(id){
      this.list_menu = await FETCHING.getEditMenuListLevelAkses(id);
      // console.log(this.list_menu)
    },

      


      
    },
    mounted() {
      this.getView()
      
    },
    
  }
</script>