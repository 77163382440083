var DataStore = require('../store');
var store  = DataStore.default


// const fetchPOST = (a) => {
//     console.log('Lib test = '+ a);
//     return a
// }

const fetch_view = async (url, bodyx) => {
    return new Promise((resolve) => {
      fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: bodyx
      })
      .then(res => res.json())
      .then(res_data => {
        // console.log(res_data)
        // store.state.listKabKota = res_data
        resolve(res_data)
      });
  
    })
  }
const fetch_postx = async (url, bodyx) => {
    return new Promise((resolve) => {
      fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: bodyx
      })
      .then(res => res.json())
      .then(res_data => { 
        resolve(res_data)
      });
  
    })
  }
const fetch_getx = async (url, bodyx) => {
    return new Promise((resolve) => {
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        }
      })
      .then(res => res.json())
      .then(res_data => {
        // console.log(res_data)
        // store.state.listKabKota = res_data
        resolve(res_data)
      });
  
    })
  }

const fetchPost = async (url, bodyx) => {
    return new Promise((resolve) => {
      fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: bodyx
      })
    //   .then(res => res.json())
      .then(res_data => {
        // console.log(res_data)
        // store.state.listKabKota = res_data
        resolve(res_data)
      });
  
    })
}

const fetch_postx_file = async (url, bodyx) => {
  return new Promise((resolve) => {
    fetch(url, {
      method: "POST",
      headers: {
        // "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body: bodyx
    })
    .then(res => res.json())
    .then(res_data => { 
      resolve(res_data)
    });

  })
}

const getEditMenuListLevelAkses = async (menu_levelUsers_id) => {
    return new Promise(resolve =>{
        fetch(store.state.url.URL_LEVEL_USERS + "getOne", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              menu_levelUsers_id: menu_levelUsers_id,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              // console.log(res_data.message);
              var isi = res_data
              if (isi.message == "Tidak ter-Authorisasi") { 
                  localStorage.removeItem("token");
                  localStorage.removeItem("profile");
                  localStorage.removeItem("menu");
                  localStorage.removeItem("listmenu");
              }else{ 
                  resolve(res_data)
              }
                
        });
    })
}

const getEditMenuListLevelAkses_Login = async (menu_levelUsers_id) => {
 
  return new Promise(resolve =>{
      fetch(store.state.url.URL_LEVEL_USERS + "getOneLogin", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            menu_levelUsers_id: menu_levelUsers_id,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              resolve(res_data)
      });
  })
}

module.exports = {
    fetchPost : fetchPost,
    fetch_view : fetch_view,
    fetch_postx : fetch_postx,
    fetch_getx : fetch_getx,
    fetch_postx_file : fetch_postx_file,
    getEditMenuListLevelAkses : getEditMenuListLevelAkses,
    getEditMenuListLevelAkses_Login : getEditMenuListLevelAkses_Login,
}