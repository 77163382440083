<template>
    <div> 
        <q-dialog v-model="modalx" persistent>
            <q-card style="width: 700px; max-width: 80vw;"> 
                <div class="fit row wrap justify-center items-center content-start">
                    <div class="col-11">
                        <div class="q-pa-md text-center bg-primary">
                            <span class="q-ma-md text-white">TAMBAH ANGGOTA KELUARGA</span>
                        </div>
                    </div>
                    <div class="col-1">
                        <div clickable class="q-pa-md text-center bg-primary">
                            <a @click="modalx" href="javascript:void(0)" style="text-decoration: none;">
                                <span class="q-ma-md text-white"><b>x</b></span>
                            </a>
                        </div>
                    </div>

                </div>
                <q-separator />
                <form v-on:submit.prevent="metode"> 
                    <q-card-section>
                        <slot name="bodys"></slot> 
                    </q-card-section>
                    <q-separator />
        
                    <q-card-actions align="center">
                        <q-btn size="sm" label="Tambah" color="primary" type="submit" />
                        <q-btn size="sm" label="Kembali" color="negative" @click="modalx"   />
                    </q-card-actions>
                    
                </form>
            </q-card>
        </q-dialog>    
    </div>
</template>

<script>
export default { 
  props:['metode', 'modalx'],
  data(){
    return{
        modal   :   false,
        modalx  :   false,
    }
  }
  
}
</script>