<template>
    <div> 
        <q-dialog v-model="$store.state.mdl_edit" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">

                <div class="col-11">
                    <div class="q-pa-md text-center bg-oranye-500">
                        <span class="q-ma-md text-white">EDIT</span>
                    </div>
                </div>

                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-oranye-500">
                        <a @click="$store.state.mdl_edit=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>

            <q-separator />
            
            <form v-on:submit.prevent="metode"> 
                <q-card-section>
                    <slot name="bodys"></slot> 
                </q-card-section>
                <q-separator />
    
                <q-card-actions align="center">
                    <q-btn size="sm" label="Edit" color="oranye-500" type="submit" style="width: 60px;" />
                    <q-btn size="sm" label="Kembali" color="negative" style="width: 60px;" @click="$store.state.mdl_edit = false"   />
                </q-card-actions>
                
            </form>
        </q-card>
        </q-dialog>    
    </div>
</template>

<script>
export default { 
  props:['metode'],
  data(){
    return{
        modal   :   false,

        list_menu : [
            {
                id           : '1', 
                router       : '/menu',
                nama         : 'Setting',
                type         : 'single',
                icon_color   : '#3a3a3a',
                text_color   : '#3a3a3a',
                icon         : '',
                color        : '#3a3a3a',
                kategori     : 'menu',
                urutan       : 1,
                path         : false,
                createdAt    : '',
            }
            
        ],
    }
  }
  
}
</script>