<template>
<q-layout view="lHh Lpr lFf">
    <q-page-container>
      <q-page class="flex flex-center bg-pict" >
        <q-card class="q-pa-md shadow-2 my_card" bordered style="opacity: 0.8;width: 452px;">
            <!-- <div class="row">
                <div class="col-12 text-center">
                    <q-img :src="'img/simponi2.png'" spinner-color="white" style="height: 190px; max-width: 100%" />
                </div>
            </div> -->
          <q-card-section class="text-center">
            <q-img :src="'logokonkep.png'" spinner-color="white" style="height: 100%; max-width: 100px;" />
              <div class="text-grey-9 text-h5 text-weight-bold">SIMPONI</div>
              <div class="text-grey-8">SISTEM INFORMASI PELAYANAN ONLINE DUKCAPIL WAWONII BANGKIT</div> 
            <!-- <div class="text-grey-9 text-h5 text-weight-bold">Login</div>
            <div class="text-grey-8">Masuk di bawah untuk mengakses akun Anda</div>  -->
          </q-card-section>

            <div class="text-center" v-if="Loadingku">
                <q-circular-progress
                    indeterminate
                    size="90px"
                    :thickness="0.2"
                    color="light-blue-8"
                    center-color="grey-8"
                    track-color="transparent"
                    class="q-ma-md"
                />
                <br>
                <span class="h_loading">LOADING ...</span>
            </div>

          <q-card-section v-if="!Loadingku">
            <q-input dense outlined v-model="form.username" label="Username"></q-input>
            <q-input dense outlined class="q-mt-md" v-model="form.password" type="password" label="Password"></q-input>
            <q-banner rounded v-if="errorMessage" dense inline-actions class="text-white bg-red q-mt-md" style="">
                <span class="txkecil" style="font-weight:600">{{errorMessage}}</span>
            </q-banner>
          </q-card-section>
          <q-card-section>
            <q-btn @click="login()" style="border-radius: 8px;" color="dark" rounded size="md" label="Login" no-caps class="full-width"></q-btn>
          </q-card-section>
          <q-card-section class="text-center q-pt-none">
            <!-- <div class="text-grey-8">Belum memiliki akun?
              <a href="javascript:void(0)" @click="register()" class="text-dark text-weight-bold" style="text-decoration: none">Register.</a></div> -->
          </q-card-section>

        </q-card>
      </q-page>
    </q-page-container>
  </q-layout>

 
</template>

<script>
  import FETCHING from "../../library/fetching";
  import UMUM from "../../library/umum";

  import Joi from "joi";

    const userSchema = Joi.object({
      username: Joi.string().regex(/^[a-zA-Z0-9_]*$/).min(3).max(50).required(),
      password: Joi.string().min(6).required()
    });

export default {
    name:'login',
    data() {
        return {
            FETCHING    : FETCHING,
            UMUM        : UMUM,
            test            :'',
            form : {
                username    : '',
                password    : '',
            },
            Loadingku       : false,
            errorMessage    : '',
            list_menu       : [],
        }
    },
    methods: { 
        validUser: function(){
            const { error, value } = userSchema.validate(this.form);
            if (error) {
                if (error.details[0].message.includes("username")) {
                    // console.log("Error dalam validasi:", error.details[0].message);
                    this.errorMessage = "Username tidak valid";
                    return false
                } else {
                    // console.log("Error dalam validasi:", error.details[0].message);
                    this.errorMessage = "Password tidak valid";
                    return false
                }
            } else {
                // console.log("Data pengguna valid:", value);
                return true;
            } 
        },

        async login(){ 
            this.errorMessage = ''
            if(this.validUser()){
                this.$store.commit("loadingStart")

                // console.log("Tombol login ini di jalankan");

                const body = {
                    username : this.form.username,
                    password : this.form.password
                } 
                fetch(this.$store.state.url.AUTH_LOGIN, {
                    method : 'POST',
                    headers : {
                    'content-type' : 'application/json',
                    },
                    body : JSON.stringify(body),
                }).then((response) => {
                    
                    // console.log(response);
                    
                        if (response.ok) {
                            this.$store.commit("loadingStart");
                            
                            this.Loadingku = true;
                            return response.json();
                        }
                        
                        return response.json().then(error => {
                            this.$store.commit("loadingStart");
                            // this.$store.commit("shoWLoading");
                            this.Loadingku = true;
                            throw new Error(error.message);
                        });
                })
                .then(async (result) => {
                    // console.log("=============================");
                    // console.log(result);
                    // menyimpan token yang tergenerate dari server
                    localStorage.token = result.token;
                    localStorage.profile = JSON.stringify(result.profile);


                    // console.log(result.profile.profile)

                    var lev = result.profile.profile


                    this.list_menu = await this.FETCHING.getEditMenuListLevelAkses_Login(lev.level);
                    localStorage.menu = JSON.stringify(this.list_menu); 
                    

                    setTimeout(() => {
                    // this.$store.commit("hideLoading")
                    this.$store.commit("loadingStop")
                    

                    this.Loadingku = false;
                    // location.reload(this.$router.push('/ktp'));

                    // ========= kalau berhasil ======== >
                    location.reload(this.$router.push('/'));
                    // this.$router.push('/menu') 
                    this.$store.state.hideBar = true
                    this.$store.state.sidebarx = true 

                    }, 1000);
                })
                .catch(error => {
                    setTimeout(() => {
                    this.Loadingku = false;
                    // this.$store.commit("hideLoading")
                    this.$store.commit("loadingStop")

                    this.errorMessage = error.message;
                    }, 1000);
                });
            }

            // ========= kalau berhasil ======== >
            // this.$router.push('/menu') 
            // this.$store.state.hideBar = true
            // this.$store.state.sidebarx = true 
        },

        register(){ 
            this.$router.push('/register')  
        },
    },
    mounted() {
        this.$store.state.hideBar = false
        this.$store.state.sidebarx = false 
    },
}

</script>

<style scoped>
    .bg-pict {
        background-image: url("https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA4L3Jhd3BpeGVsb2ZmaWNlMjBfYV9jbG9zZV91cF9vZl9hX2NvbG9yZnVsX2ZsdWlkX2Fic3RyYWN0X3BhbGVfd18xYTg4YWI2Ny1jMzdmLTQ2ZTctYjk5OC1hMjI2N2U3YmI4ODBfMi5qcGc.jpg");
        background-color: #cccccc; 
        background-size: cover; 
    }
</style>