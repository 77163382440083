<template>
    <div> 
        <q-dialog v-model="$store.state.mdl_lihat_file" persistent>
            <q-card style="width: 700px; max-width: 80vw;"> 
                <div class="fit row wrap justify-center items-center content-start">
                    <div class="col-11">
                        <div class="q-pa-md text-center bg-primary">
                            <span class="q-ma-md text-white">TAMBAH</span>
                        </div>
                    </div>
                    <div class="col-1">
                        <div clickable class="q-pa-md text-center bg-primary">
                            <a href="javascript:void(0)" style="text-decoration: none;" v-close-popup>
                                <span class="q-ma-md text-white"><b>x</b></span>
                            </a>
                        </div>
                    </div>

                </div>
                <q-separator />
 
                    <q-card-section style="max-height: 600px">

                        <!-- <object :data="$store.state.url.URL_APP+'uploads/'+file" type="application/pdf" width="100%" height="600px">
                            <p>Apabila file tidak langsung terbuka, sliahkan klik <a :href="$store.state.url.URL_APP+'uploads/'+file"> link ini untuk ke PDF!</a></p>
                        </object> -->

                        <q-pdfviewer type="html5" :src="$store.state.url.URL_APP+'uploads/'+filex" />
                    </q-card-section>
                    <q-separator />
        
                    <q-card-actions align="center">
                        <!-- <q-btn size="sm" label="Tambah" color="primary" type="submit" /> -->
                        <q-btn size="sm" label="Kembali" color="negative"  v-close-popup  />
                    </q-card-actions>
                    
   
            </q-card>
        </q-dialog>    
    </div>
</template>

<script>
export default { 
  props:['filex'],
  data(){
    return{
        modal   :   false,
    }
  }
  
}
</script>