<template>
    <!-- <div class="bg-abu-300 shadow-3" style="background-image: linear-gradient(120deg, #f5f0f0, #abdfd5);padding:0px;margin: 10px;border-radius: 8px;"> -->
    <div class="bg-abu-300 shadow-10 bg-gradku" style="padding:0px;margin: 10px;border-radius: 8px;">
      <div class="row">
        
        <div class="col-12"> 
          <div class="q-pa-md q-gutter-sm">
            <q-banner inline-actions rounded class="bg-brand abu-100 shadow-3">
              Registrasi User
              <template v-slot:action>  
                <q-btn size="md" @click="$store.state.mdl_add = true" class="bg-ijo-600" color="" icon="add" />
              </template>
            </q-banner>
          </div>      
        </div>   
        
  
  
        <!-- ============================== FILTER ============================== -->
       
        
  
    
        <div class="col-6"> 
            
            <div class="q-px-md q-pt-md text-center"> 
              <!-- <div>Cari</div> -->
              <q-input v-model="filter.cari_value" @keyup="getView()" class="bg-white" color="primary" label-color="gray-16" outlined :dense="true" type="search" label="Pencarian">
                <template v-slot:append> <q-icon name="search" /> </template>
              </q-input> 
          </div>      
        </div>
        <div class="col-6"> 
            <div class="q-px-md q-pt-md text-center"> 
                <!-- <div>Level User: {{ selected }}</div> -->
                <select class="f_select" v-model="filter.cari_level" @change="getView()" style="width: 100%;">
                    <!-- <option v-for="data in options" :value="data.value" :key="data.value" :disabled="false" >{{data.uraian}}</option>  -->
                    <option value="">               Level User              </option>
                    <option value="kabupaten">      Admin Kabupaten         </option>
                    <option value="verifikator">    Admin Verifikator       </option> 
                    <option value="pemohon">        Admin Pemohon           </option>
                    <option value="vertikal">       Admin Pemohon Vertikal  </option>                    
                </select>
            </div>  
        </div>
    
     
    
        <div class="col-12"> 
            <div class="q-pa-md">
              <q-markup-table style="width: 100%;" class="bg-abu-200">
              <thead class="bg-brand">
                <tr>
                  <th class="text-center  abu-100" style="width: 10%;">No</th>
                  <th class="text-left  abu-100" style="width: 20%;">Nama</th>
                  <th class="text-center  abu-100" style="width: 20%;">Username</th>
                  <th class="text-center  abu-100" style="width: 20%;">Email</th>
                  <th class="text-center  abu-100" style="width: 20%;">Kelas User</th>
                  <th class="text-center  abu-100" style="width: 10%;">Act</th>
                </tr>
              </thead>
    
              <tbody>
              <tr v-for="(data, index) in list_data">
                <td class="text-center ijo-800">{{index+1}}.</td>
                <td class="text-left ">
                  <div>
                    <div class="ijo-900" style="text-transform: uppercase;"> <b>{{data.nama}} </b></div>
                  </div>
                </td>
                <td class="text-right ijo-900">{{data.username}}</td>
                <td class="text-right ijo-900">{{data.email}}</td>

                <td class="text-left ijo-900 text-uppercase">
                    {{data.kelas}}
                </td>

                <td class="text-center">
                    <div class="q-gutter-xs">
 
                    <q-btn round color="blue-5" icon="vpn_key" size="sm" @click="selectData(data), mdl_password = true">
                        <q-tooltip class="bg-indigo" :offset="[0, -60]"> Ganti Password </q-tooltip>
                    </q-btn>
                    <q-btn round color="orange-5" icon="edit" size="sm" @click="selectData(data), $store.state.mdl_edit = true">
                        <q-tooltip class="bg-indigo" :offset="[0, -60]"> Edit </q-tooltip>
                    </q-btn>
                    <q-btn round color="red-5" icon="delete" size="sm" @click="$store.state.mdl_del = true">
                        <q-tooltip class="bg-indigo" :offset="[0, -60]"> Hapus </q-tooltip>
                    </q-btn>
                    </div>
              </td>
              </tr>
    
    
    
    
              </tbody>
              </q-markup-table>
            </div> 
        </div>
    
     
    
        <div class="col-12"> 
          <div class="q-px-md q-pt-md">
            <q-separator />
          </div>      
        </div>
    
    
      <div class="col-12">
        <div class="row q-py-md">
          <div class="col-12 col-md-12 col-sm-12 col-xs-12 q-pa-none flex flex-center">
              <q-pagination
                v-model="current"
                :max="5"
                input
                icon-first="skip_previous"
                icon-last="skip_next"
                icon-prev="fast_rewind"
                icon-next="fast_forward"
                color="brand"
              />
          </div>
    
        </div>
        
      </div>
  
  
  
  
  
      <!-- ============================== FILTER ============================== -->
      
      
    </div> 
  
  
    <!-- ================================= MODAL =================================  -->
  
    <MDL_ADD :modal="$store.state.mdl_add" :metode="addData">
  
   
      
      <template  v-slot:bodys>
        <div class="row ">
  
          <!-- ================ TUJUAN PERMOHONAN ================ -->
          <div class="col-12 q-px-md"><span class="abu-800">Nama Lengkap</span></div>
          <div class="col-12 q-px-md">
            <input class="f_select" v-model="form.nama"  label="Nama Lengkap" placeholder="Nama Lengkap"></input>
          </div>

          <div class="col-12 q-px-md"><span class="abu-800">Level Authentikasi Menu</span></div>
          <div class="col-12 q-px-md">
              <select class="f_select" v-model="form.level" style="width: 100%;">
                <option value="">Pilih Level Authentikasi Menu</option>
                <option v-for="data in list_level" :value="data.id" :key="data.id" >{{data.uraian}}</option> 
              </select>
          </div>

          <div class="col-12 q-px-md"><span class="abu-800">Level Kelas User</span></div>
          <div class="col-12 q-px-md">
            <select class="f_select" v-model="form.kelas" style="width: 100%;">
                <option value="">               Level User              </option>
                <option value="kabupaten">      Admin Kabupaten         </option>
                <option value="verifikator">    Admin Verifikator       </option> 
                <option value="pemohon">        Admin Pemohon           </option>
                <option value="vertikal">       Admin Pemohon Vertikal  </option>
            </select>
          </div>


        <div class="col-12 q-px-md" v-if="form.kelas !=''">
            
            <div class="row">
                <div class="col-6 q-px-xs" v-if="form.kelas == 'kabupaten' || form.kelas == 'verifikator' || form.kelas == 'pemohon' || form.kelas == 'vertikal' "> 
                    <span class="abu-800">Provinsi</span>
                    <select class="f_select" v-model="form.m_provinsi" style="width: 100%;" @change="loadListData">
                        <option value="">           Pilih Provinsi              </option>
                        <option v-for="(item, index) in l_prov" :key="item.m_provinsi" :value="item.m_provinsi">  {{item.uraian}}         </option> 
                    </select>
                </div>
                <div class="col-6 q-px-xs" v-if="form.kelas == 'kabupaten' || form.kelas == 'verifikator' || form.kelas == 'pemohon' || form.kelas == 'vertikal'"> 
                    <span class="abu-800">Kabupaten</span>
                    <select class="f_select" v-model="form.m_kabupaten" style="width: 100%;" @change="loadListData">
                        <option value="">           Pilih Kabupaten              </option> 
                        <option v-for="(item, index) in l_kab" :key="item.m_kabupaten" :value="item.m_kabupaten">  {{item.uraian}}         </option> 
                    </select>
                </div>
                <div class="col-6 q-px-xs" v-if="form.kelas == 'verifikator' || form.kelas == 'pemohon'"> 
                    <span class="abu-800">Kecamatan</span>
                    <select class="f_select" v-model="form.m_kecamatan" style="width: 100%;" @change="loadListData">
                        <option value="">           Pilih Kabupaten              </option>
                        <option v-for="(item, index) in l_kec" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                    </select>
                </div>
                <div class="col-6 q-px-xs" v-if="form.kelas == 'pemohon'"> 
                    <span class="abu-800">Desa / Kelurahan</span>
                    <select class="f_select" v-model="form.m_desa_kelurahan" style="width: 100%;">
                        <option value="">           Pilih Desa / Kelurahan              </option>
                        <option v-for="(item, index) in l_deskel" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                    </select>
                </div>
            </div>
            
          </div>

          <div class="col-12 q-px-md"><span class="abu-800">Username</span></div>
          <div class="col-12 q-px-md">
            <input class="f_select" v-model="form.username" type="username" label="Username" placeholder="Username"></input>
          </div>

          <div class="col-12 q-px-md"><span class="abu-800">Email</span></div>
          <div class="col-12 q-px-md">
            <input class="f_select" v-model="form.email"  label="Email" placeholder="Email"></input>
          </div>

          <div class="col-12 q-px-md"><span class="abu-800">Nomor Hp / Whatsapp</span></div>
          <div class="col-12 q-px-md">
            <input class="f_select" v-model="form.hp" type="text" label="Nomor Hp" placeholder="081xxxxxxxxx"></input>
          </div>

          <div class="col-12 q-px-md"><span class="abu-800">Password</span></div>
          <div class="col-12 q-px-md">
            <input class="f_select" v-model="form.password" type="password" label="Password" placeholder="Password"></input>
          </div>

          <div class="col-12 q-px-md"><span class="abu-800">Confirm Password</span></div>
          <div class="col-12 q-px-md">
            <input class="f_select" v-model="form.confirmPassword" type="password" label="Confirm Password" placeholder="Confirm Password"></input>

            <q-banner rounded v-if="err_pass == false" dense inline-actions class="text-white bg-red q-mt-md" style="">
                <span class="txkecil" style="font-weight:600">{{errorMessage}} </span>
            </q-banner>
          </div>


          <div class="col-12 q-px-md">
            <hr>
          </div>
 
  
        </div>  
      </template>
  
    </MDL_ADD>
  
    <MDL_EDT :modal="$store.state.mdl_edit" :metode="editData">
  
   
      
      <template  v-slot:bodys>
        <div class="row ">
  
          <!-- ================ TUJUAN PERMOHONAN ================ -->
          <div class="col-12 q-px-md"><span class="abu-800">Nama Lengkap</span></div>
          <div class="col-12 q-px-md">
            <input class="f_select" v-model="form.nama"  label="Nama Lengkap" placeholder="Nama Lengkap"></input>
          </div>

          <div class="col-12 q-px-md"><span class="abu-800">Level Authentikasi Menu</span></div>
          <div class="col-12 q-px-md">
              <select class="f_select" v-model="form.level" style="width: 100%;">
                <option value="">Pilih Level Authentikasi Menu</option>
                <option v-for="data in list_level" :value="data.id" :key="data.id" >{{data.uraian}}</option> 
              </select>
          </div>

          <div class="col-12 q-px-md"><span class="abu-800">Level Kelas User</span></div>
          <div class="col-12 q-px-md">
            <select class="f_select" v-model="form.kelas" style="width: 100%;">
                <option value="">               Level User              </option>
                <option value="kabupaten">      Admin Kabupaten         </option>
                <option value="verifikator">    Admin Verifikator       </option> 
                <option value="pemohon">        Admin Pemohon           </option>
                <option value="vertikal">       Admin Pemohon Vertikal  </option>
            </select>
          </div>


        <div class="col-12 q-px-md" v-if="form.kelas !=''">
            
            <div class="row">
                <div class="col-6 q-px-xs" v-if="form.kelas == 'kabupaten' || form.kelas == 'verifikator' || form.kelas == 'pemohon' || form.kelas == 'vertikal' "> 
                    <span class="abu-800">Provinsi</span>
                    <select class="f_select" v-model="form.m_provinsi" style="width: 100%;" @change="loadListData">
                        <option value="">           Pilih Provinsi              </option>
                        <option v-for="(item, index) in l_prov" :key="item.m_provinsi" :value="item.m_provinsi">  {{item.uraian}}         </option> 
                    </select>
                </div>
                <div class="col-6 q-px-xs" v-if="form.kelas == 'kabupaten' || form.kelas == 'verifikator' || form.kelas == 'pemohon' || form.kelas == 'vertikal'"> 
                    <span class="abu-800">Kabupaten</span>
                    <select class="f_select" v-model="form.m_kabupaten" style="width: 100%;" @change="loadListData">
                        <option value="">           Pilih Kabupaten              </option> 
                        <option v-for="(item, index) in l_kab" :key="item.m_kabupaten" :value="item.m_kabupaten">  {{item.uraian}}         </option> 
                    </select>
                </div>
                <div class="col-6 q-px-xs" v-if="form.kelas == 'verifikator' || form.kelas == 'pemohon'"> 
                    <span class="abu-800">Kecamatan</span>
                    <select class="f_select" v-model="form.m_kecamatan" style="width: 100%;" @change="loadListData">
                        <option value="">           Pilih Kabupaten              </option>
                        <option v-for="(item, index) in l_kec" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                    </select>
                </div>
                <div class="col-6 q-px-xs" v-if="form.kelas == 'pemohon'"> 
                    <span class="abu-800">Desa / Kelurahan</span>
                    <select class="f_select" v-model="form.m_desa_kelurahan" style="width: 100%;">
                        <option value="">           Pilih Desa / Kelurahan              </option>
                        <option v-for="(item, index) in l_deskel" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                    </select>
                </div>
            </div>
            
          </div>

          <div class="col-12 q-px-md"><span class="abu-800">Username</span></div>
          <div class="col-12 q-px-md">
            <input class="f_select" v-model="form.username" type="username" label="Username" placeholder="Username"></input>
          </div>

          <div class="col-12 q-px-md"><span class="abu-800">Email</span></div>
          <div class="col-12 q-px-md">
            <input class="f_select" v-model="form.email"  label="Email" placeholder="Email"></input>
          </div>

          <div class="col-12 q-px-md"><span class="abu-800">Nomor Hp / Whatsapp</span></div>
          <div class="col-12 q-px-md">
            <input class="f_select" v-model="form.hp" type="text" label="Nomor Hp" placeholder="081xxxxxxxxx"></input>
          </div>



          <div class="col-12 q-px-md">
            <hr>
          </div>
 
  
        </div>  
      </template>
  
    </MDL_EDT>
  
    <MDL_DEL :modal="$store.state.mdl_del" :metode="klikOk"/>

    <q-dialog v-model="mdl_password" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-primary">
                        <span class="q-ma-md text-white">GANTI PASSWORD</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-primary">
                        <a @click="mdl_password = false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <!-- <q-separator /> -->
            <form v-on:submit.prevent="editPassword()"> 
                <q-card-section>

                  <div class="col-12 q-px-md"><span class="abu-800">Password</span></div>
                  <div class="col-12 q-px-md">
                    <input class="f_select" v-model="form.password" type="password" label="Password" placeholder="Password"></input>
                  </div>

                  <div class="col-12 q-px-md"><span class="abu-800">Confirm Password</span></div>
                  <div class="col-12 q-px-md">
                    <input class="f_select" v-model="form.confirmPassword" type="password" label="Confirm Password" placeholder="Confirm Password"></input>

                    <q-banner rounded v-if="err_pass == false" dense inline-actions class="text-white bg-red q-mt-md" style="">
                        <span class="txkecil" style="font-weight:600">{{errorMessage}} </span>
                    </q-banner>
                  </div>


                </q-card-section>
                
                <q-separator />

                <q-card-actions align="center">
                    <q-btn size="sm" label="Tambah" color="primary" type="submit" />
                    <q-btn size="sm" label="Kembali" color="negative" @click="mdl_password = false"   />
                </q-card-actions>
                
            </form>
        </q-card>
    </q-dialog>   
   
  
    <!-- ================================= MODAL =================================  -->
  
  </div>
   
  
  </template>
  
  <style></style>
  
  <script>
   
    import FETCHING from "../../library/fetching";
    import UMUM from "../../library/umum";
    import Joi from "joi";

    const userSchema = Joi.object({
      username: Joi.string().regex(/^[a-zA-Z0-9_]*$/).min(3).max(13).required(),
      password: Joi.string().min(6).required()
    });

    export default {
    name: 'HelloWorld',
    components: {
        
    },

    data() {
        return {
        FETCHING:FETCHING,
        UMUM:UMUM,
        mdl_password:false,

        
        search:'',

        model: '',
        text: '', 
        selected: '', 
        current:5,
        filter_status : '', 
        mdl_del : true, 

        form    :{
            id                   : "",
            nama                 : "",
            level                : "",
            kelas                : "",
            username             : "",
            email                : "",
            hp                   : "",
            password             : "",
            confirmPassword      : "", 
            m_provinsi           : "",
            m_kabupaten          : "",
            m_kecamatan          : "",
            m_desa_kelurahan     : "",
        },
        list_data   :[],
        list_level  :[],

        l_prov      :[],
        l_kab       :[],
        l_kec       :[],
        l_deskel    :[],

        filter:{
            data_ke:1,
            page_limit:8,
            cari_value:'',
            cari_level:'',
        },
        err_pass:true, 

        value: { name: 'Vue.js', language: 'JavaScript' },

        options:[
            {value:'semua',           uraian:'Semua'},
            {value:'kabupaten',       uraian:'Kabupaten'},
            {value:'kecamatan',       uraian:'Kecamatan'},
            {value:'pemohon',         uraian:'Pemohon'},
            {value:'vertikal',        uraian:'Admin Pemohon Vertikal'}
        ],
        opt_filter: [
            { id:0, name: '- Filter Data -', },
            { id:1, name: 'Menunggu', },
            { id:2, name: 'Kembalikan',},
            { id:3, name: 'Di Proses', }, 
        ],
        akun:{
            id:'asajkshjkad',
            level:'administrator',  
        },


        }
    },
    methods: { 
        validUser: function(){
            if (this.form.password !== this.form.confirmPassword) {
                this.errorMessage = "Password harus sama. !";
                this.err_pass = false
                return false;
            }else{

                const { error, value } = userSchema.validate({
                    username:this.form.username,
                    password:this.form.password
                });
                if (error) {
                    if (error.details[0].message.includes("username")) { 
                        this.errorMessage = "Username tidak valid";
                        this.err_pass = false
                        return false
                    // } else if (error.details[0].message.includes("password")) { 
                    } else { 
                        this.errorMessage = "Password tidak valid";
                        console.log(error.details[0].message);
                        this.err_pass = false
                        return false
                    }
                } else {
                    this.err_pass = true 
                    return true;
                } 

            }
        },

        klikOk(){
        // var a = this.check_form()

        if (this.validUser()) {
            // console.log(a);
            console.log(this.form);
            console.log('berhasil');
        }else{
            console.log('gagal');

        }
        },

        async getView(){  
        this.$store.commit('loadingStart')
        var a = await FETCHING.fetch_view(this.$store.state.url.URL_REGISTRASI + 'view_contoh',
        JSON.stringify({
                data_ke         : this.filter.data_ke,
                page_limit      : this.filter.page_limit,
                cari_value      : this.filter.cari_value,
                cari_byLevel    : this.filter.cari_level
            })
        )
        this.list_data = a.data
        // console.log(a);
        this.$store.commit('loadingStop')
        },

        async addData(){  
            if (this.validUser()) { 
                this.$store.commit('loadingStart')
                var a = await FETCHING.fetch_postx(this.$store.state.url.URL_REGISTRASI+'signup', JSON.stringify(this.form))         
                this.$store.state.mdl_add = false
                await UMUM.notifikasi(a)
                this.$store.commit('loadingStop') 
                this.getView();
            }else{
                console.log('gagal'); 
            }            

        }, 

        async editData(){   
                this.$store.commit('loadingStart')
                var a = await FETCHING.fetch_postx(this.$store.state.url.URL_REGISTRASI+'editData', JSON.stringify(this.form))         
                this.$store.state.mdl_edit = false
                await UMUM.notifikasi(a)
                this.$store.commit('loadingStop') 
                this.getView(); 

        },

        async editPassword(){   
                this.$store.commit('loadingStart')
                var a = await FETCHING.fetch_postx(this.$store.state.url.URL_REGISTRASI+'editPassword', JSON.stringify(this.form))         
                this.mdl_password = false
                await UMUM.notifikasi(a)
                this.$store.commit('loadingStop') 
                this.getView(); 

        },
        
        selectData(data){
          console.log(data);
          this.form.id                  = data.id
          this.form.nama                = data.nama
          this.form.level               = data.level
          this.form.kelas               = data.kelas
          this.form.username            = data.username
          this.form.email               = data.email
          this.form.hp                  = data.hp 
          this.form.password            = data.password
          this.form.confirmPassword     = data.confirmPassword
          this.form.m_provinsi          = data.provinsi
          this.form.m_kabupaten         = data.kabupaten
          this.form.m_kecamatan         = data.kecamatan
          this.form.m_desa_kelurahan    = data.desa_kelurahan     

          this.loadListData()     
        },
        // selectDataPass(data){
        //   // console.log(data);
        //   this.form.id                  = data.id 
        //   this.form.password            = data.password
        //   this.form.confirmPassword     = data.confirmPassword     
        // },

        async getLevelAuth(){
            // var a = this.
            this.$store.commit('loadingStart')
            var a = await FETCHING.fetch_getx(this.$store.state.url.URL_LEVEL_USERS+'list')
            console.log(a);
            this.list_level = a
            this.$store.commit('loadingStop')
        },        

        async loadListData(){
            this.$store.commit('loadingStart')
            this.list_level     = await FETCHING.fetch_getx(this.$store.state.url.URL_LEVEL_USERS + 'list')
            this.l_prov         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.l_kab          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.form.m_provinsi }))
            this.l_kec          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.form.m_kabupaten }))
            this.l_deskel       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.form.m_kecamatan }))
            this.$store.commit('loadingStop') 
        },

        check_form(){
            if (this.form.password == this.form.confirmPassword) {
                this.err_pass = false
                return false
            } else {
                this.err_pass = true
                return true
            }
        },

    },
    mounted() {
        this.getView()
        this.loadListData()
        
    },
}
  
  </script>
  