<template>
    <div> 
        <q-dialog v-model="$store.state.mdl_del" persistent>
        <q-card> 
            <div class="q-pa-md text-center bg-negative">
            <span class="q-ma-md text-white">Hapus</span>
            </div>
            <q-separator />
            <q-card-section class="row  text-center">
                <div class="col-12 text-center items-center">
                <q-avatar icon="delete_forever" color="negative" text-color="white" />
                </div>
            
                <div class="col-12 text-center items-center">
                <div >Yakin anda akan menghaus data ini.?</div> 
                </div>
            </q-card-section>
            <q-separator />

            <q-card-actions align="center">
            <q-btn  label="Ya" color="primary" @click="metode" style="width: 100px;" />
            <q-btn  label="Tidak" color="negative" @click="$store.state.mdl_del = false"  style="width: 100px;" />
            </q-card-actions>
        </q-card>
        </q-dialog>    
    </div>
</template>

<script>
export default { 
  props:['metode'],
  data(){
    return{
        modal   :   false,
    }
  }
  
}
</script>