<template>
    <!-- <div class="bg-abu-300 shadow-3" style="background-image: linear-gradient(120deg, #f5f0f0, #abdfd5);padding:0px;margin: 10px;border-radius: 8px;"> -->
    <div class="bg-abu-300 shadow-10 bg-gradku" style="padding:0px;margin: 10px;border-radius: 8px;">
      <div class="row">
        
        <div class="col-12"> 
          <div class="q-pa-md q-gutter-sm">
            <q-banner inline-actions rounded class="bg-brand abu-100 shadow-3">
              Kabupaten / Kota
              <template v-slot:action>  
                <q-btn size="md" @click="$store.state.mdl_add = true" class="bg-ijo-600" color="" icon="add" />
              </template>
            </q-banner>
          </div>      
        </div>   
        
  
  
        <!-- ============================== FILTER ============================== -->
     
    
        <div class="col-6"> 
          <div class="q-px-md q-pt-md"> 
  
          </div>      
        </div>
        <div class="col-6"> 
            <div class="q-px-md q-pt-md text-center"> 
                <q-input v-model="filter.cari_value" @keyup="getView()" class="bg-white" color="primary" label-color="gray-16" outlined :dense="true" type="search" label="Pencarian">
                    <template v-slot:append> <q-icon name="search" /> </template>
                </q-input> 
            </div>
        </div>
    
     
    
        <div class="col-12"> 
            <div class="q-pa-md">
              <q-markup-table style="width: 100%;" class="bg-abu-200">
              <thead class="bg-brand">
                <tr>
                  <th class="text-center  abu-100" style="width: 10%;">No</th>
                  <th class="text-left  abu-100" style="width: 10%;">Provinsi</th>
                  <th class="text-left  abu-100" style="width: 10%;">Kode</th>
                  <th class="text-left  abu-100" style="width: 60%;">Kabupaten</th>
                  <!-- <th class="text-center  abu-100" style="width: 10%;">Tanggal</th> -->
                  <!-- <th class="text-center  abu-100" style="width: 20%;">Status</th> -->
                  <th class="text-center  abu-100" style="width: 10%;">Act</th>
                </tr>
              </thead>
    
              <tbody>
              <tr v-for="(data, index) in list_data">
                <td class="text-center ijo-800">{{index+1}}.</td>
                <td class="text-left ijo-800">{{data.m_provinsi.uraian}}</td>
                <td class="text-left ijo-800">{{data.m_kabupaten.kd_kabupaten}}</td>
                <td class="text-left ">
                  <div>
                    <!-- <div class="ijo-800" style="font-size: 9pt;">NIK: 1657387687623</div> -->
                    <div class="ijo-900" style="text-transform: uppercase;"> <b>{{data.m_kabupaten.uraian}}  </b></div>
                  </div>
                </td> 
  
                <td class="text-center">
                  <div class="q-gutter-xs">
                    <q-btn round color="orange-5" icon="edit" size="sm" @click="$store.state.mdl_edit = true, selectData(data)">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Edit </q-tooltip>
                    </q-btn>
                    <q-btn round color="red-5" icon="delete" size="sm" @click="$store.state.mdl_del = true, selectData(data)">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Hapus </q-tooltip>
                    </q-btn>
                  </div>
                </td>
              </tr>
    
    
    
    
              </tbody>
              </q-markup-table>
            </div> 
        </div>
    
     
    
        <div class="col-12"> 
          <div class="q-px-md q-pt-md">
            <q-separator />
          </div>      
        </div>
    
    
      <div class="col-12">
        <div class="row q-py-md">
          <div class="col-12 col-md-12 col-sm-12 col-xs-12 q-pa-none flex flex-center">
              <q-pagination
                @click="getView()"
                v-model="filter.data_ke"
                :max="filter.page_last"
                :max-pages="4"
                input
                icon-first="skip_previous"
                icon-last="skip_next"
                icon-prev="fast_rewind"
                icon-next="fast_forward"
                color="brand"
              />
          </div>
          
    
        </div>
        
      </div>
  
  
  
  
  
      <!-- ============================== FILTER ============================== -->
      
      
    </div> 
  
  
    <!-- ================================= MODAL =================================  -->
  
    <MDL_ADD :modal="$store.state.mdl_add" :metode="addData">
  
      <template  v-slot:bodys>
        <div class="row "> 

            <div class="col-12" > 
                <span class="abu-800">Provinsi</span>
                <select class="f_select" v-model="form.m_provinsi" style="width: 100%;" @change="loadListData">
                    <option value="">           Pilih Provinsi              </option>
                    <option v-for="(item, index) in l_prov" :key="item.m_provinsi" :value="item.m_provinsi">  {{item.uraian}}         </option> 
                </select>
            </div>

            <!-- <div class="col-12" > 
                <span class="abu-800">Kabupaten</span>
                <select class="f_select" v-model="form.m_kabupaten" style="width: 100%;" @change="loadListData">
                    <option value="">           Pilih Kabupaten              </option> 
                    <option v-for="(item, index) in l_kab" :key="item.m_kabupaten" :value="item.m_kabupaten">  {{item.uraian}}         </option> 
                </select>
            </div>
            <div class="col-12" > 
                <span class="abu-800">Kecamatan</span>
                <select class="f_select" v-model="form.m_kecamatan" style="width: 100%;" @change="loadListData">
                    <option value="">           Pilih Kabupaten              </option>
                    <option v-for="(item, index) in l_kec" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                </select>
            </div>
            <div class="col-12" > 
                <span class="abu-800">Desa / Kelurahan</span>
                <select class="f_select" v-model="form.m_desa_kelurahan" style="width: 100%;">
                    <option value="">           Pilih Desa / Kelurahan              </option>
                    <option v-for="(item, index) in l_deskel" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                </select>
            </div> -->


            <div class="col-12">
            <div class="">
                <div>Kode SHDK</div>
                <input class="f_select" v-model="form.kd_kabupaten" type="text" placeholder="Kode SHDK">
            </div>
            </div>
  
            <div class="col-12">
            <div class="">
                <div>Uraian</div>
                <input class="f_select" v-model="form.uraian" type="text" placeholder="Uraian">
            </div>
            </div> 

 

         

        </div>  
      </template>
  
    </MDL_ADD>
  
    <MDL_EDT :modal="$store.state.mdl_edit" :metode="editData"> 
        <template  v-slot:bodys>
        <div class="row "> 

            <div class="col-12" > 
                <span class="abu-800">Provinsi</span>
                <select class="f_select" v-model="form.m_provinsi" style="width: 100%;" @change="loadListData">
                    <option value="">           Pilih Provinsi              </option>
                    <option v-for="(item, index) in l_prov" :key="item.m_provinsi" :value="item.m_provinsi">  {{item.uraian}}         </option> 
                </select>
            </div>

            <!-- <div class="col-12" > 
                <span class="abu-800">Kabupaten</span>
                <select class="f_select" v-model="form.m_kabupaten" style="width: 100%;" @change="loadListData">
                    <option value="">           Pilih Kabupaten              </option> 
                    <option v-for="(item, index) in l_kab" :key="item.m_kabupaten" :value="item.m_kabupaten">  {{item.uraian}}         </option> 
                </select>
            </div>
            <div class="col-12" > 
                <span class="abu-800">Kecamatan</span>
                <select class="f_select" v-model="form.m_kecamatan" style="width: 100%;" @change="loadListData">
                    <option value="">           Pilih Kabupaten              </option>
                    <option v-for="(item, index) in l_kec" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                </select>
            </div>
            <div class="col-12" > 
                <span class="abu-800">Desa / Kelurahan</span>
                <select class="f_select" v-model="form.m_desa_kelurahan" style="width: 100%;">
                    <option value="">           Pilih Desa / Kelurahan              </option>
                    <option v-for="(item, index) in l_deskel" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                </select>
            </div> -->


            <div class="col-12">
            <div class="">
                <div>Kode SHDK</div>
                <input class="f_select" v-model="form.kd_kabupaten" type="text" placeholder="Kode SHDK">
            </div>
            </div>
  
            <div class="col-12">
            <div class="">
                <div>Uraian</div>
                <input class="f_select" v-model="form.uraian" type="text" placeholder="Uraian">
            </div>
            </div> 

 

         

        </div>  
      </template>
    </MDL_EDT>
  
    <MDL_DEL :modal="$store.state.mdl_del" :metode="removeData"/>
   
  
    <!-- ================================= MODAL =================================  -->
  
  </div>
   
  
  </template>
  
  
  
  <script>
   
    import FETCHING from "../../library/fetching";
    import UMUM from "../../library/umum";
    import uniqid from 'uniqid';
  
    export default {
        name: 'HelloWorld',
        components: {
    },
  
    data() {
        return {
            FETCHING    :FETCHING,
            UMUM        :UMUM,
        search          :'',
        model           : '',
        text            : '', 
        selected        : '', 
        current         :5,
        filter_status   : 0, 
        mdl_del         : true, 
  
        list_data       :[],
  
        filter:{
            data_ke:1,
            page_limit:10,
            cari_value:'',
            cari_level:'',
            page_last: 0,
        },
  
        form:{
              id : '',
              m_provinsi : '',
              m_kabupaten : '',
              kd_kabupaten : '',
              uraian : '',
              createdAt : '',
              uniqid : ''
        },

        l_prov        :[],
        l_kab         :[],
        l_kec         :[],
        l_deskel      :[],
  
        
        value           : { name: 'Vue.js', language: 'JavaScript' },
        options         : [
            { id:1, name: 'Vue.js',  language: 'JavaScript' },
            { id:1, name: 'Rails',   language: 'Ruby' },
            { id:1, name: 'Sinatra', language: 'Ruby' },
            { id:1, name: 'Laravel', language: 'PHP' },
            { id:1, name: 'Phoenix', language: 'Elixir' },
        ],
  
        opt_filter: [
            { id:0, name: '- Filter Data -', },
            { id:1, name: 'Menunggu', },
            { id:2, name: 'Kembalikan',},
            { id:3, name: 'Di Proses', }, 
        ],
  
        akun:{
            id:'asajkshjkad',
            level:'administrator',
        }
        }
    },
    methods: { 
        klikOk(){
            console.log('Ok Ok');
        },
  
        async getView(){  
            this.$store.commit('loadingStart')
            var a = await FETCHING.fetch_view(this.$store.state.url.URL_DATAMASTER_KAB_KOTA+'view', JSON.stringify({
                data_ke     : this.filter.data_ke,
                page_limit  : this.filter.page_limit,
                cari_value  : this.filter.cari_value
            })
            )
            this.list_data          = a.data
            this.filter.page_last   = a.jmlData;
            console.log(a.data);
            this.$store.commit('loadingStop')
        },
  
        async addData(){  
            var ID = uniqid();
            this.form.id        = ID;
            this.form.uniqid    = ID;
            this.form.m_kabupaten    = this.form.kd_kabupaten;
            this.form.createdAt = UMUM.NOW();
  
            console.log(this.form);
            this.$store.commit('loadingStart')
            var a = await FETCHING.fetch_postx(this.$store.state.url.URL_DATAMASTER_KAB_KOTA+'addData', JSON.stringify(this.form))         
            this.$store.state.mdl_add = false
            await UMUM.notifikasi(a)
            this.$store.commit('loadingStop') 
            this.getView();
        }, 
        async editData(){  
            this.form.m_kabupaten    = this.form.kd_kabupaten;
            this.$store.commit('loadingStart')
            var a = await FETCHING.fetch_postx(this.$store.state.url.URL_DATAMASTER_KAB_KOTA+'editData', JSON.stringify(this.form))         
            this.$store.state.mdl_add = false
            await UMUM.notifikasi(a)
            this.$store.commit('loadingStop') 
            this.getView();
        }, 
  
        async removeData(){  
            this.$store.commit('loadingStart')
            var a = await FETCHING.fetch_postx(this.$store.state.url.URL_DATAMASTER_KAB_KOTA+'removeData', JSON.stringify({ uniqid:this.form.uniqid })) 
            this.$store.commit('loadingStop')
            await UMUM.notifikasi(a)
            this.$store.state.mdl_del = false
            this.getView();  
        },         

        async loadListData(){
            this.$store.commit('loadingStart')
            this.l_prov         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.l_kab          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.form.m_provinsi }))
            this.l_kec          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.form.m_kabupaten }))
            this.l_deskel       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.form.m_kecamatan }))
            this.$store.commit('loadingStop') 
        },
  
        selectData(data){ 
            this.form.id                        = data.m_kabupaten.id
            this.form.m_provinsi                = data.m_kabupaten.m_provinsi
            this.form.m_kabupaten               = data.m_kabupaten.m_kabupaten
            this.form.kd_kabupaten              = data.m_kabupaten.kd_kabupaten
            this.form.uraian                    = data.m_kabupaten.uraian
            this.form.createdAt                 = data.m_kabupaten.createdAt
            this.form.uniqid                    = data.m_kabupaten.uniqid
        },
        
  
  
    },
    mounted() {
        this.getView()
        this.loadListData()
    },
    }
  
  </script>
  
  
   