<template>
  <!-- <div class="bg-abu-300 shadow-3" style="background-image: linear-gradient(120deg, #f5f0f0, #abdfd5);padding:0px;margin: 10px;border-radius: 8px;"> -->
  <div class="bg-abu-300 shadow-10 bg-gradku" style="padding:0px;margin: 10px;border-radius: 8px;">
    <div class="row">
      
      <div class="col-12"> 
        <div class="q-pa-md q-gutter-sm">
          <q-banner inline-actions rounded class="bg-brand abu-100 shadow-3">
            
            

          </q-banner>
        </div>      
      </div>   

      


      <!-- ============================== FILTER ============================== -->
      <div class="col-md-3 col-sm-12 col-xs-12 q-px-md q-pt-md" > 
          <span class="abu-800">Provinsi {{ filter.prov }}</span>
          <select class="f_select" v-model="filter.prov" style="width: 100%;" @change="loadListData(), getView()">
              <option value="" :disabled="true">           Pilih Provinsi              </option>
              <option v-for="(item, index) in f_prov" :key="item.m_provinsi" :value="item.m_provinsi" :disabled="true">  {{item.uraian}}         </option> 
          </select>
      </div>

      <div class="col-md-3 col-sm-12 col-xs-12 q-px-md q-pt-md" > 
          <span class="abu-800">Kabupaten {{ filter.kab }}</span>
          <select class="f_select" v-model="filter.kab" style="width: 100%;" @change="loadListData(), getView()">
              <option value="" :disabled="true">           Pilih Kabupaten / Kota             </option> 
              <option v-for="(item, index) in f_kab" :key="item.m_kabupaten" :value="item.m_kabupaten" :disabled="true">  {{item.uraian}}         </option> 
          </select>
      </div>

      <div class="col-md-3 col-sm-12 col-xs-12 q-px-md q-pt-md" > 
          <span class="abu-800">Kecamatan</span>
          <select class="f_select" v-model="filter.kec" style="width: 100%;" @change="loadListData(), getView()">
              <option value="">           Pilih Kecamatan              </option>
              <option v-for="(item, index) in f_kec" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
          </select>
      </div>
      <div class="col-md-3 col-sm-12 col-xs-12 q-px-md q-pt-md" > 
          <span class="abu-800">Desa / Kelurahan</span>
          <select class="f_select" v-model="filter.deskel" style="width: 100%;" @change="loadListData(), getView()">
              <option value="">           Pilih Desa / Kelurahan              </option>
              <option v-for="(item, index) in f_deskel" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
          </select>
      </div>
      <!-- ============================== FILTER ============================== -->  
      <div class="col-6"> 
          <div class="q-px-md q-pt-md"> 
              <q-input v-model="filter.cari_value" @keyup="getView()" class="bg-white" color="primary" label-color="gray-16" outlined :dense="true" type="search" label="Pencarian">
                  <template v-slot:append> <q-icon name="search" /> </template>
              </q-input> 
          </div>      
      </div>
      <div class="col-6"> 
          <div class="q-px-md q-pt-md text-center"> 
                  <select class="f_select" v-model="filter.status" style="width: 100%;" @change="getView()">
                      <option value="0" :disabled="false">Menunggu</option>
                      <option value="1" :disabled="false">Proses</option>
                      <option value="2" :disabled="false">Dikembalikan</option> 
                  </select>
          </div>  
      </div>
    <!-- ============================== FILTER ============================== -->
   
    <div class="col-6 col-sm-12 col-xs-12 col-md-6"> 
          <div class="row no-wrap items-center q-ma-md q-pa-sm bg-grey-9 text-white rounded-borders">
              <q-avatar style="font-size: 80px;">
                <q-icon name="badge" />
              </q-avatar>
              <div class="q-px-md" style="font-size: 14px;">
                <b>KTP</b>
              </div>
              <q-space />
              <div>
                 <b style="font-size: 30px;">{{list_data.ktp}}</b>
              </div>
            </div>
    </div>

    <!-- ============================== KK ============================== -->
    <div class="col-6 col-sm-12 col-xs-12 col-md-6"> 
          <div class="row no-wrap items-center q-ma-md q-pa-sm bg-grey-9 text-white rounded-borders">
              <q-avatar style="font-size: 80px;">
                <q-icon name="assignment" />
              </q-avatar>
              <div class="q-px-md" style="font-size: 14px;">
                <b>Kartu Keluarga Baru</b>
              </div>
              <q-space />
              <div>
                 <b style="font-size: 30px;">{{list_data.kk1}}</b>
              </div>
            </div>
    </div>

    <div class="col-6 col-sm-12 col-xs-12 col-md-6"> 
          <div class="row no-wrap items-center q-ma-md q-pa-sm bg-grey-9 text-white rounded-borders">
              <q-avatar style="font-size: 80px;">
                <q-icon name="assignment" />
              </q-avatar>
              <div class="q-px-md" style="font-size: 14px;">
                <b>Perubahan Kartu Keluarga Baru Warga Negara Indonesia</b>
              </div>
              <q-space />
              <div>
                 <b style="font-size: 30px;">{{list_data.kk2}}</b>
              </div>
            </div>
    </div>

    <div class="col-6 col-sm-12 col-xs-12 col-md-6"> 
          <div class="row no-wrap items-center q-ma-md q-pa-sm bg-grey-9 text-white rounded-borders">
              <q-avatar style="font-size: 80px;">
                <q-icon name="assignment" />
              </q-avatar>
              <div class="q-px-md" style="font-size: 14px;">
                <b>Kartu Keluarga Baru Orang Asing Yang Memiliki Izin Tinggal Tetap</b>
              </div>
              <q-space />
              <div>
                 <b style="font-size: 30px;">{{list_data.kk3}}</b>
              </div>
            </div>
    </div>
    <!-- ============================== KK ============================== -->
    <!-- ============================== PPD ============================== -->
    <div class="col-6 col-sm-12 col-xs-12 col-md-6"> 
          <div class="row no-wrap items-center q-ma-md q-pa-sm bg-grey-9 text-white rounded-borders">
              <q-avatar style="font-size: 80px;">
                <q-icon name="summarize" />
              </q-avatar>
              <div class="q-px-md" style="font-size: 14px;">
                <b>Perpindahan Antar Kabupaten/Kota Antar Provinsi</b>
              </div>
              <q-space />
              <div>
                 <b style="font-size: 30px;">{{list_data.ppd1}}</b>
              </div>
            </div>
    </div>

    <div class="col-6 col-sm-12 col-xs-12 col-md-6"> 
          <div class="row no-wrap items-center q-ma-md q-pa-sm bg-grey-9 text-white rounded-borders">
              <q-avatar style="font-size: 80px;">
                <q-icon name="summarize" />
              </q-avatar>
              <div class="q-px-md" style="font-size: 14px;">
                <b>Perpindahan Antar Desa/Kelurahan Dalam Satu Kecamatan</b>
              </div>
              <q-space />
              <div>
                 <b style="font-size: 30px;">{{list_data.ppd2}}</b>
              </div>
            </div>
    </div>

    <div class="col-6 col-sm-12 col-xs-12 col-md-6"> 
          <div class="row no-wrap items-center q-ma-md q-pa-sm bg-grey-9 text-white rounded-borders">
              <q-avatar style="font-size: 80px;">
                <q-icon name="summarize" />
              </q-avatar>
              <div class="q-px-md" style="font-size: 14px;">
                <b>Perpindahan Dalam Satu Desa/Kelurahan</b>
              </div>
              <q-space />
              <div>
                 <b style="font-size: 30px;">{{list_data.ppd3}}</b>
              </div>
            </div>
    </div>

    <!-- ============================== PPD ============================== -->

    <!-- ============================== KETERANGAN KELAHIRAN ============================== -->
    <div class="col-6 col-sm-12 col-xs-12 col-md-6"> 
          <div class="row no-wrap items-center q-ma-md q-pa-sm bg-grey-9 text-white rounded-borders">
              <q-avatar style="font-size: 80px;">
                <q-icon name="child_care" />
              </q-avatar>
              <div class="q-px-md" style="font-size: 14px;">
                <b>Surat Keterangan Kelahiran</b>
              </div>
              <q-space />
              <div>
                 <b style="font-size: 30px;">{{list_data.lahir1}}</b>
              </div>
            </div>
    </div>

    <div class="col-6 col-sm-12 col-xs-12 col-md-6"> 
          <div class="row no-wrap items-center q-ma-md q-pa-sm bg-grey-9 text-white rounded-borders">
              <q-avatar style="font-size: 80px;">
                <q-icon name="child_care" />
              </q-avatar>
              <div class="q-px-md" style="font-size: 14px;">
                <b>Surat Keterangan Kelahiran (Anak Yang Tidak Diketahui Asal-Usulnya)</b>
              </div>
              <q-space />
              <div>
                 <b style="font-size: 30px;">{{list_data.lahir2}}</b>
              </div>
            </div>
    </div>
    <!-- ============================== KETERANGAN KELAHIRAN ============================== -->

    <!-- ============================== KEMATIAN ============================== -->
    <div class="col-6 col-sm-12 col-xs-12 col-md-6"> 
          <div class="row no-wrap items-center q-ma-md q-pa-sm bg-grey-9 text-white rounded-borders">
              <q-avatar style="font-size: 80px;">
                <q-icon name="history_edu" />
              </q-avatar>
              <div class="q-px-md" style="font-size: 14px;">
                <b>Pelaporan Lahir Mati (Bayi Dalam Kandungan)</b>
              </div>
              <q-space />
              <div>
                 <b style="font-size: 30px;">{{list_data.mati1}}</b>
              </div>
            </div>
    </div>

    <div class="col-6 col-sm-12 col-xs-12 col-md-6"> 
          <div class="row no-wrap items-center q-ma-md q-pa-sm bg-grey-9 text-white rounded-borders">
              <q-avatar style="font-size: 80px;">
                <q-icon name="history_edu" />
              </q-avatar>
              <div class="q-px-md" style="font-size: 14px;">
                <b>Pelaporan Kematian</b>
              </div>
              <q-space />
              <div>
                 <b style="font-size: 30px;">{{list_data.mati2}}</b>
              </div>
            </div>
    </div>
    <!-- ============================== KEMATIAN ============================== -->




    <!-- ============================== PPD ============================== -->
    
     
    
   
  
      <div class="col-12"> 
        <div class="q-px-md q-pt-md">
          <q-separator />
        </div>      
      </div>
      
    <!-- ============================== FILTER ============================== -->
    
    
  </div> 


  <!-- ================================= MODAL =================================  -->
 
  <!-- ================================= MODAL =================================  -->

</div>
 

</template>



<script>
 
  import FETCHING from "../library/fetching";
  import UMUM from "../library/umum";
  import uniqid from 'uniqid';

  export default {
      name: 'HelloWorld',
      components: {
  },

  data() {
      return {
        FETCHING    :FETCHING,
        UMUM        :UMUM,

        search          :'',

        list_data       :[],

        filter:{
            data_ke:1,
            page_limit:10,
            cari_value:'',
            cari_level:'',
            page_last: 0,

            prov      :'74',
            kab       :'7412',
            kec       :'',
            deskel    :'',

            status    :'0',
        },

          form:{
              id : '',
              m_provinsi      :     '74',
              m_kabupaten : '7412',
              m_kecamatan : '',
              m_desa_kelurahan : '',
              modul                       :'ktp',
              permohonan_ktp              : '', 
              file                        : null, 
              nama                        : '', 
              no_kk                       : '', 
              nik                         : '', 
              alamat                      : '', 
              rt                          : '', 
              rw                          : '', 
              kode_pos                    : '', 
              status                      : 0,
              keterangan                  : 0,
              keterangan_deskel           : '',
              keterangan_kecamatan        : '',
              keterangan_kabupaten        : '',
              status_deskel               : 0,
              status_kecamatan            : 0,
              status_kabupaten            : 0,
              createdBy                   : '',
              createdAt                   : '',
              createdAt_deskel            : '',
              createdAt_kecamatan         : '',
              createdAt_kabupaten         : '',
              file_terbitan               : null,
              emailPemohon                : '',
              email_file                  : null,
              email_fileOld               : '',
              email_from                  : '',
              email_to                    : '',
              email_subject               : '',
              email_html                  : '',
              file_lampiran               : null,
              file_lampiranOld            : null
          },

          form_view : {
              uraian_1_provinsi : "",
              uraian_1_kabupaten : "",
              uraian_1_kecamatan : "",
              uraian_1_desa : "",    
          },

        l_prov        :[],
        l_kab         :[],
        l_kec         :[],
        l_deskel      :[], 

          f_prov          : [],
          f_kab           : [],
          f_kec           : [],
          f_deskel        : [],

      }
  },
  methods: { 
      klikOk(){
          console.log('Ok Ok');
      },

      async getView(){  
          this.$store.commit('loadingStart')
          var a = await FETCHING.fetch_view(this.$store.state.url.URL_DASHBOARD+'view', JSON.stringify({
              data_ke     : this.filter.data_ke,
              page_limit  : this.filter.page_limit,
              cari_value  : this.filter.cari_value,
              status      : this.filter.status,

              f_prov      : this.filter.prov,
              f_kab       : this.filter.kab,
              f_kec       : this.filter.kec,
              f_des       : this.filter.deskel,

          })
          )
          this.list_data          = a
          console.log(a);
          this.$store.commit('loadingStop')
      },
      

      async loadListData(){
          this.$store.commit('loadingStart') 
          this.f_prov         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
          this.f_kab          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.filter.prov }))
          this.f_kec          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.filter.kab }))
          this.f_deskel       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.filter.kec }))
          this.$store.commit('loadingStop') 
      },
      

      checkStatus(data){
          if (data == 2) {
              // return 'bg-deep-orange-4'
          } else if (data == 1) {
              // return 'bg-teal-2'
          }else{

          }
      },

      checkKelasUser(data){
          var prof = JSON.parse(localStorage.profile) 
          var kelas = prof.profile.kelas

          if (kelas == data) {
              return true
          } 
          // else if (kelas == 'administrator') {
          //     return true
          // }
          // else if (kelas == 'pemohon') {
          //     return true
          // }
          else{
              return false

          }
      },

      cek_btn_editDel(data){  
          var a = data.status 
          console.log();
          // var status_kab = data.status_kabupaten  

          var prof = JSON.parse(localStorage.profile) 
          var kelas = prof.profile.kelas

          if (kelas == 'vertikal' || kelas == 'pemohon') {
              if (a == 1) {
                  return true
              }else{
                  return false
              }
          }
      },

      bukaTab(linknya){
          window.open(linknya);
      },

      lihatFilex(filename){
          // console.log(filename);
          var ext = filename.split('.').pop().toLowerCase(); 
          if (ext == "pdf") {
              this.$store.state.pdfx = true
              this.$store.state.filex = filename

              // return true
          } else {
              this.$store.state.gambarx = false
              this.$store.state.filex = filename 
          } 

      },
      clear_file(){

          this.$store.state.filex = ''
      },
       

      


  },
  mounted() {
      this.getView()
      this.loadListData()
      this.checkKelasUser()
  },
  }

</script>


 