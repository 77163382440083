<template>
    <q-layout view="lHh Lpr lFf" >
        <q-page-container >
          <q-page class="flex flex-center bg-pict">
            <q-card class="q-pa-md shadow-2 my_card" bordered style="opacity: 0.8;width: 352px;" >
              <q-card-section class="text-center">
                <div class="text-grey-9 text-h5 text-weight-bold">REGISTRASI</div>
                <div class="text-grey-8">Apabila Belum Memiliki Akun Silahkan Input Data Anda </div>
              </q-card-section>
              <q-card-section>
                <q-input dense outlined class="q-mt-md" v-model="form.username" label="Nama Lengkap"></q-input>
                <q-input dense outlined class="q-mt-md" v-model="form.username" label="Email"></q-input>
                <q-input dense outlined class="q-mt-md" v-model="form.username" label="Nomor HP"></q-input>
                <q-input dense outlined class="q-mt-md" v-model="form.username" label="Username"></q-input>
                <q-input dense outlined class="q-mt-md" v-model="form.username" type="password" label="Password"></q-input>
                <q-input dense outlined class="q-mt-md" v-model="form.username" type="password" label="Retype Password"></q-input>
              </q-card-section>
              <q-card-section>
                <q-btn @click="login()" style="border-radius: 8px;" color="dark" rounded size="md" label="Login" no-caps class="full-width"></q-btn>
              </q-card-section>
              <q-card-section class="text-center q-pt-none">
                <div class="text-grey-8">Sudah Memiliki Akun?
                  <a href="javascript:void(0)" @click="login()" class="text-dark text-weight-bold" style="text-decoration: none">Login</a></div>
              </q-card-section>
    
            </q-card>
          </q-page>
        </q-page-container>
      </q-layout>
    
     
    </template>
    
    <script>
    export default {
        name:'login',
        data() {
            return {
                test:'',
                form:{
                    id : '',
                    username :'',
                    password :'',                    
                    email : '',
                    level :  '',
                    nama : '',
                    kelas : '',
                    hp : '',
                    provinsi :'74',
                    kabupaten :'7412',
                    kecamatan : '',
                    desa_kelurahan : '',
                },
                
            }
        },
        methods: { 
            login(){ 
                this.$router.push('/login')  
            },
        },
        mounted() {
            this.$store.state.hideBar = false
            this.$store.state.sidebarx = false 
        },
    }
    </script>

<style scoped>
.bg-pict {
 background-image: url("https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA4L3Jhd3BpeGVsb2ZmaWNlMjBfYV9jbG9zZV91cF9vZl9hX2NvbG9yZnVsX2ZsdWlkX2Fic3RyYWN0X3BhbGVfd18xYTg4YWI2Ny1jMzdmLTQ2ZTctYjk5OC1hMjI2N2U3YmI4ODBfMi5qcGc.jpg");
 background-color: #cccccc; 
 background-size: cover; 
}
</style>
     