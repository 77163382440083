<template>
    <q-drawer
        v-model="$store.state.sidebarx"
        show-if-above
        :width="300"
        :breakpoint="400"
        
        style="background: linear-gradient(0deg, #353535 0%, #353535 100%);"
      >
        <q-scroll-area style="height: calc(100% - 150px); margin-top: 150px; border-right: 1px solid #ffff">
          <q-list bordered padding separator>
            <!-- ================= level menu =================  -->
            <template v-for="data in list_menu">
              
              <q-item :to="data.router"  clickable v-ripple v-if="data.type == 'single' && data.view == true" exact :key="data._id">
                <q-item-section avatar>
                  <q-icon class="text-white" name="inbox" />
                </q-item-section> 
                <q-item-section class="abu-100" style="font-size: 9pt;font-weight: 700;">
                  {{data.nama}}
                </q-item-section>
              </q-item> 


              <q-expansion-item :content-inset-level="0.5" expand-separator v-if="data.type == 'double' && checkLength(data.submenu)" :key="data._id">
                <template v-slot:header> 
                  <q-item-section avatar>
                    <q-icon class="text-white" :name="data.icon" color="abu-300" text-color="teal-7"/>
                  </q-item-section> 

                  <q-item-section class="abu-100" style="font-size: 9pt;font-weight: 700;">
                    {{data.nama}}
                  </q-item-section> 
                </template>

                <template  v-for="data1 in data.submenu">
                  <q-item :to="data1.router" v-if="data1.view == true" :content-inset-level="1" clickable v-ripple> 
                    <q-item-section avatar>
                      <q-icon class="text-white"    style="font-size: 10pt;" name="radio_button_unchecked" color="abu-300" text-color="teal-7"/>
                    </q-item-section> 
                    <q-item-section class="abu-100" style="font-size: 9pt;font-weight: 700;"> {{data1.nama}} </q-item-section>
                  </q-item> 
                </template>
  

            </q-expansion-item>
            </template>


            <!-- <q-expansion-item :content-inset-level="0.5" expand-separator>
              <template v-slot:header> 
                <q-item-section avatar>
                  <q-icon class="text-white" name="settings" color="abu-300" text-color="teal-7"/>
                </q-item-section> 

                <q-item-section class="abu-100" style="font-size: 9pt;font-weight: 700;">
                  Setting
                </q-item-section> 
              </template>

              <q-item :to="'/menu'" :content-inset-level="1" clickable v-ripple> 
                <q-item-section avatar>
                  <q-icon class="text-white"    style="font-size: 10pt;" name="radio_button_unchecked" color="abu-300" text-color="teal-7"/>
                </q-item-section> 
                <q-item-section class="abu-100" style="font-size: 9pt;font-weight: 700;"> MENU </q-item-section>
              </q-item> 
              
              <q-item :to="'/level_auth'" :content-inset-level="1" clickable v-ripple> 
                <q-item-section avatar>
                  <q-icon class="text-white"    style="font-size: 10pt;" name="radio_button_unchecked" color="abu-300" text-color="teal-7"/>
                </q-item-section> 
                <q-item-section class="abu-100" style="font-size: 9pt;font-weight: 700;"> LEVEL AUTHENTICATION </q-item-section>
              </q-item> 

            </q-expansion-item> -->
            <!-- ================= level menu =================  -->
         
  
            <q-separator/>

            <q-item clickable v-ripple @click="logOut()" >
              <q-item-section avatar>
                <q-icon color="red-10" name="logout" />
                <!-- <q-avatar icon="logout" color="brand" text-color="white" /> -->
              </q-item-section>

              <q-item-section class="text-red-8">
                <b>Logout</b>
              </q-item-section>
            </q-item>  

          </q-list>
        </q-scroll-area>

        <q-img class="absolute-top" src="img/material.png" style="height: 150px">
          <div class="absolute-bottom bg-transparent">
            <q-avatar size="56px" class="q-mb-sm">
              <img src="https://cdn.quasar.dev/img/boy-avatar.png">
            </q-avatar>
            <div class="text-weight-bold">{{nama}}</div>
            <div>{{email}}</div>
          </div>
        </q-img>
    </q-drawer>
</template>

<script>
    import FETCHING from "../library/fetching";
export default {
  data() {
    return {
      FETCHING:FETCHING,

      nama:'',
      email:'',

      list_menu:[],
    }
  },
  methods: {
    logOut(){
      localStorage.removeItem("token");
      localStorage.removeItem("profile");
      localStorage.removeItem("menu");
      localStorage.removeItem("listmenu");
      this.$router.push("/login");
    },

  checkLength(data){
      var panjang = data.length
      //  return panjang
        var falsx = false 
      data.forEach(element => {
          if (element.view == true) {
              falsx = true                
          }
      });
      return falsx
    },
    // getMenu(){
    //   return new Promise((resolve) => {
    //     var a = JSON.parse(localStorage.menu)
    //     setTimeout(() => {
    //       resolve(a)
    //     },1000)
    //   })
    // }, 
    async loadMenu(){
      // var a = await this.getMenu()
      // this.list_menu = a 

      var profile     = localStorage.profile
      // console.log(profile);
      if (profile == undefined) {
        console.log('Takada profile undefined');
        
      } else { 
        var profilex = JSON.parse(profile)
        var level_user  = profilex.profile.level
        this.list_menu = await this.FETCHING.getEditMenuListLevelAkses(level_user);
        // var isi = this.list_menu
        // if (isi.message == '"Tidak ter-Authorisasi"') {
        //   console.log('this.list_menu');
        //   // this.$router.push('/login')  
        // }
        
      }
       
    },

    checkUser(){
      var profile = localStorage.profile
      if (profile == '' || profile == null || profile == undefined) {
        // console.log('User Tidak Di temukan');        
        this.nama     = ''
        this.email      = ''
      }else{

        var profilex = JSON.parse(localStorage.profile)
        // console.log('User Di temukan');     
        // console.log(profile.profile.nama);   
        // console.log(profile.profile.email);   
        this.nama       = profilex.profile.nama
        this.email        = profilex.profile.email
      }

    },
  },
 

  mounted() {
    // var a = localStorage.menu
    // this.list_menu = JSON.parse(localStorage.menu)
    this.loadMenu()
    this.checkUser()
    // var profile = JSON.parse(localStorage.profile)
    // var level_user = (profile.profile.level)
    // console.log(profile.profile.level);
  },

}
</script>