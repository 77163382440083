import { createStore } from 'vuex'
import Loading from 'quasar/src/plugins/Loading.js';import LoadingBar from 'quasar/src/plugins/LoadingBar.js';import Notify from 'quasar/src/plugins/Notify.js'; 
import router from "../router";



// var URL = 'http://localhost:5012/';
// var URL = 'http://103.127.99.25:5012/';
var URL = 'https://serversimponi.konkep.com/';


export default createStore({
  state: {

    
    url: {
      URL_APP         : URL,
      URL_APPX        : URL,
      AUTH            : URL + "auth/signup",
      AUTH_LOGIN      : URL + "auth/login",
      URL_MENU_LIST   : URL + 'api/v1/menuList/',
      URL_LEVEL_USERS : URL + 'api/v1/levelUsers/',
      URL_REGISTRASI  : URL + 'api/v1/pengaturan_registrasi/',

      URL_GET_WILAYAH : URL + 'api/v1/get_wilayah/',

      URL_MASTER_PENDIDIKAN     : URL + 'api/v1/m_list_pendidikan/',
      URL_MASTER_PEKERJAAN      : URL + 'api/v1/m_list_pekerjaan/',
      URL_MASTER_AGAMA          : URL + 'api/v1/m_list_agama/',
      URL_MASTER_SHDK           : URL + 'api/v1/m_list_SHDK/',

      URL_DATAMASTER_WILAYAH            : URL + 'api/v1/m_provinsi/',
      URL_DATAMASTER_KAB_KOTA           : URL + 'api/v1/m_kabupaten/',
      URL_DATAMASTER_KECAMATAN          : URL + 'api/v1/m_kecamatan/',
      URL_DATAMASTER_DES_KEL            : URL + 'api/v1/m_desa_kelurahan/',

      URL_PERMOHONAN_KTP                : URL + 'api/v1/permohonanKTP/',

      URL_PERMOHONAN_KELAHIRAN        : URL + 'api/v1/permohonanKELAHIRAN/',
      URL_PERMOHONAN_KELAHIRAN_AYTDAU : URL + 'api/v1/permohonanKELAHIRAN_aytdau/',

      // LAPOR KEMATIAN
      URL_PELAPORAN_LAHIR_MATI  : URL + 'api/v1/pelaporan_LAHIR_MATI/',
      URL_PELAPORAN_MATI        : URL + 'api/v1/pelaporan_MATI/',
      // LAPOR KEMATIAN

      URL_PERMOHONAN_KK_BARU    : URL + 'api/v1/permohonan_KK_baru/',
      URL_PERMOHONAN_KK_WNI     : URL + 'api/v1/permohonan_KK_WNI/',
      URL_PERMOHONAN_KK_WNA     : URL + 'api/v1/permohonan_KK_WNA/',

      URL_PERMOHONAN_PPD1       : URL + 'api/v1/permohonan_PPD1/',
      URL_PERMOHONAN_PPD2       : URL + 'api/v1/permohonan_PPD2/',
      URL_PERMOHONAN_PPD3       : URL + 'api/v1/permohonan_PPD3/',

      URL_DASHBOARD: URL + 'api/v1/dashboard/',


    },

    list_status : [
      {id:0, name:'Mengunggu'},
      {id:1, name:'Proses'},
      {id:2, name:'Dikembalikan'},
    ],

    // CONFIG MODAL
    sidebarx    :false,

    mdl_add       :false,
    mdl_edit      :false,
    mdl_del       :false,
    mdl_view      :false,
    mdl_history   :false,
    mdl_verifikasi_kec   :false,
    mdl_kembalikan_kec   :false,
    mdl_verifikasi_kab   :false,
    mdl_kembalikan_kab   :false,
    mdl_lihat_file       :false,

    //  PROPERTIES 

    filex : '',
    gambarx : false,
    pdfx    : false,

    //  PROPERTIES 
    
    // LIST_DATA
    pendidikkan : [],
    pekerjaan   : [],
    agama       : [],
    shdk        : [],

    nama_hari : ['Senin','Selasa','Rabu','Kamis','Jumat','Sabtu','Minggu'],

    l_pekerjaan   : [],
    l_agama       : [],
    l_pendidikan  : [],
    l_shdk        : [],
    // LIST_DATA

    menus:[],

    hideBar     :true,
  },
  getters: {
  },

  mutations: {

    loadingStart(){
      Loading.show()
    },

    loadingStop(){
      Loading.hide()
    },

    notif_berhasil(){
      // Notify
      Notify.create({
        message: '========== BERHASIL ========== ',
        color:'primary',
        textColor:'white',
        position:'center',
      }) 
    },

    notif_gagal(){
      // Notify
      Notify.create({
        message: '========== Gagal ========== ',
        color:'warning',
        textColor:'white',
        position:'center',
      }) 
    }, 


  },
  actions: {
    
  },
  modules: {
  }
})
