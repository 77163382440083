<template>
    <!-- <div class="bg-abu-300 shadow-3" style="background-image: linear-gradient(120deg, #f5f0f0, #abdfd5);padding:0px;margin: 10px;border-radius: 8px;"> -->
    <div class="bg-abu-300 shadow-10 bg-gradku" style="padding:0px;margin: 10px;border-radius: 8px;">
      <div class="row">
        
        <div class="col-12"> 
          <div class="q-pa-md q-gutter-sm">
            <q-banner inline-actions rounded class="bg-brand abu-100 shadow-3">
                Dalam Satu Desa/Kelurahan
              <template v-slot:action>  
                <q-btn size="md" @click="$store.state.mdl_add = true, loadListData()" class="bg-ijo-600" color="" icon="add" v-show="checkKelasUser('pemohon') || checkKelasUser('vertikal') || checkKelasUser('administrator')" />
              </template>
            </q-banner>
          </div>      
        </div>   
        
  
  
        <!-- ============================== FILTER ============================== -->
        <div class="col-md-3 col-sm-12 col-xs-12 q-px-md q-pt-md" > 
            <span class="abu-800">Provinsi</span>
            <select class="f_select" v-model="filter.prov" style="width: 100%;" @change="loadListData(), getView()">
                <option value="" :disabled="true">           Pilih Provinsi              </option>
                <option v-for="(item, index) in f_prov" :key="item.m_provinsi" :value="item.m_provinsi" :disabled="true">  {{item.uraian}}         </option> 
            </select>
        </div>

        <div class="col-md-3 col-sm-12 col-xs-12 q-px-md q-pt-md" > 
            <span class="abu-800">Kabupaten</span>
            <select class="f_select" v-model="filter.kab" style="width: 100%;" @change="loadListData(), getView()">
                <option value="" :disabled="true">           Pilih Kabupaten / Kota             </option> 
                <option v-for="(item, index) in f_kab" :key="item.m_kabupaten" :value="item.m_kabupaten" :disabled="true">  {{item.uraian}}         </option> 
            </select>
        </div>

        <div class="col-md-3 col-sm-12 col-xs-12 q-px-md q-pt-md" > 
            <span class="abu-800">Kecamatan</span>
            <select class="f_select" v-model="filter.kec" style="width: 100%;" @change="loadListData(), getView()">
                <option value="">           Pilih Kecamatan              </option>
                <option v-for="(item, index) in f_kec" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
            </select>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12 q-px-md q-pt-md" > 
            <span class="abu-800">Desa / Kelurahan</span>
            <select class="f_select" v-model="filter.deskel" style="width: 100%;" @change="loadListData(), getView()">
                <option value="">           Pilih Desa / Kelurahan              </option>
                <option v-for="(item, index) in f_deskel" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
            </select>
        </div>
        <!-- ============================== FILTER ============================== -->  
        <div class="col-6"> 
            <div class="q-px-md q-pt-md"> 
                <q-input v-model="filter.cari_value" @keyup="getView()" class="bg-white" color="primary" label-color="gray-16" outlined :dense="true" type="search" label="Pencarian">
                    <template v-slot:append> <q-icon name="search" /> </template>
                </q-input> 
            </div>      
        </div>
        <div class="col-6"> 
            <div class="q-px-md q-pt-md text-center"> 
                    <select class="f_select" v-model="filter.status" style="width: 100%;" @change="getView()">
                        <option value="0" :disabled="false">Menunggu</option>
                        <option value="1" :disabled="false">Proses</option>
                        <option value="2" :disabled="false">Dikembalikan</option> 
                    </select>
            </div>  
        </div>
      <!-- ============================== FILTER ============================== -->
     
      <div class="col-12"> 
          <div class="q-pa-md">
            <q-markup-table style="width: 100%;" class="bg-abu-200">
            <thead class="bg-brand">
              <tr>
                <th class="text-center  abu-100" style="width: 10%;">No</th>
                <th class="text-left  abu-100" style="width: 50%;">Nama / NIK</th>
                <th class="text-center  abu-100" style="width: 10%;">Tanggal</th>
                <th class="text-center  abu-100" style="width: 20%;">Status</th>
                <th class="text-center  abu-100" style="width: 10%;">Act</th>
              </tr>
            </thead>
  
            <tbody>
            <tr v-for="(data, index) in list_data" >
              <td class="text-center ijo-800">{{index+1}}.</td>
              <td class="text-left ">
                <div>
                  <div class="ijo-800" style="font-size: 9pt;">NO KK : {{data.nik}}</div>
                  <div class="ijo-900" style="text-transform: uppercase;"> <b>{{data.nama}}  </b></div>
                </div>
              </td>
              <td class="text-right ijo-900">{{UMUM.tglConvert(data.createdAt).tgl}}</td>
              <td class="text-center">
                <div class="q-gutter-xs">
                    <!-- pemohon  -->
                    <q-btn round color="oranye-500" icon="pending" size="sm" v-if="data.status === 0" v-show="checkKelasUser('pemohon') || checkKelasUser('vertikal') || checkKelasUser('administrator')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Menunggu di proses </q-tooltip>
                    </q-btn>
  
                    <q-btn round color="ijo-600" icon="sms" size="sm" v-if="data.status === 1" v-show="checkKelasUser('pemohon') || checkKelasUser('vertikal') || checkKelasUser('administrator')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Telah di verifikasi </q-tooltip>
                    </q-btn>
                    
                    <q-btn round color="merah-600" icon="sms" size="sm" v-if="data.status === 2" v-show="checkKelasUser('pemohon') || checkKelasUser('vertikal') || checkKelasUser('administrator')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]" > Dikembalikan </q-tooltip>
                    </q-btn>
                    <!-- pemohon  -->
                    
                    <!-- verifikator  -->
                    <q-btn round color="oranye-500" icon="pending" size="sm" v-if="data.status_kecamatan === 0"  v-show="checkKelasUser('verifikator')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Menunggu di proses </q-tooltip>
                    </q-btn>
  
                    <q-btn round color="ijo-600" icon="sms" size="sm" v-if="data.status_kecamatan === 1"  v-show="checkKelasUser('verifikator')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Telah di verifikasi </q-tooltip>
                    </q-btn>
                    
                    <q-btn round color="merah-600" icon="sms" size="sm" v-if="data.status_kecamatan === 2"  v-show="checkKelasUser('verifikator')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]" > Dikembalikan </q-tooltip>
                    </q-btn>
                    
                    <!-- verifikator  -->
                    
                    <!-- kabupaten  -->
                    <q-btn round color="oranye-500" icon="pending" size="sm" v-if="data.status_kabupaten === 0"  v-show="checkKelasUser('kabupaten')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Menunggu di proses </q-tooltip>
                    </q-btn>
  
                    <q-btn round color="ijo-600" icon="sms" size="sm" v-if="data.status_kabupaten === 1"  v-show="checkKelasUser('kabupaten')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Telah di verifikasi </q-tooltip>
                    </q-btn>
                    
                    <q-btn round color="merah-600" icon="sms" size="sm" v-if="data.status_kabupaten === 2"  v-show="checkKelasUser('kabupaten')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]" > Dikembalikan </q-tooltip>
                    </q-btn>
                    <!-- kabupaten  -->
                    <!-- File Lampiran Email / Hasil  -->
                     
                    <!-- <q-btn round size="sm" v-if="data.email_file == '' || data.email_file == 'null' || data.email_file == null || data.email_file == undefined " @click="bukaTab($store.state.url.URL_APP+'uploads/'+data.email_file)" title="File Lampiran Dari Kabupaten" icon="attach_file" color="blue-8" class="text-white" />  -->
                    <span v-if="data.email_file == '' || data.email_file == 'null' || data.email_file == null || data.email_file == undefined "></span>
                    <q-btn round size="sm" v-else @click="bukaTab($store.state.url.URL_APP+'uploads/'+data.email_file)" title="File Lampiran Dari Kabupaten" icon="attach_file" color="blue-8" class="text-white" /> 

                    <!-- File Lampiran Email / Hasil  -->

                  
                </div>
              </td>
              <td class="text-center">
                <div class="q-gutter-xs">
                  <q-btn round color="teal-5" icon="timeline" size="sm" @click="$store.state.mdl_history = true, selectData(data)">
                    <q-tooltip class="bg-indigo" :offset="[0, -60]"> SOP History Dokumen </q-tooltip>
                  </q-btn>
                  <q-btn round color="blue-5" icon="visibility" size="sm" @click="$store.state.mdl_view = true, selectData(data)">
                    <q-tooltip class="bg-indigo" :offset="[0, -60]"> Lihat </q-tooltip>
                  </q-btn>
                  <q-btn round color="orange-5" :disable="cek_btn_editDel(data)" icon="edit" size="sm" @click="$store.state.mdl_edit = true, selectData(data)" v-show="checkKelasUser('pemohon') || checkKelasUser('vertikal')">
                    <q-tooltip class="bg-indigo" :offset="[0, -60]"> Edit </q-tooltip>
                  </q-btn>
                  <q-btn round color="red-5"  :disable="cek_btn_editDel(data)" icon="delete" size="sm" @click="$store.state.mdl_del = true, selectData(data)" v-show="checkKelasUser('pemohon') || checkKelasUser('vertikal')">
                    <q-tooltip class="bg-indigo" :offset="[0, -60]"> Hapus </q-tooltip>
                  </q-btn>
                </div>
              </td>
            </tr>
  
  
  
  
            </tbody>
            </q-markup-table>
          </div> 
      </div>
     
    
        <div class="col-12"> 
          <div class="q-px-md q-pt-md">
            <q-separator />
          </div>      
        </div>
    
    
      <div class="col-12">
        <div class="row q-py-md">
          <div class="col-12 col-md-12 col-sm-12 col-xs-12 q-pa-none flex flex-center">
              <q-pagination
                @click="getView()"
                v-model="filter.data_ke"
                :max="filter.page_last"
                :max-pages="4"
                input
                icon-first="skip_previous"
                icon-last="skip_next"
                icon-prev="fast_rewind"
                icon-next="fast_forward"
                color="brand"
              />
          </div>
          
    
        </div>
        
      </div>
  
  
  
  
  
      <!-- ============================== FILTER ============================== -->
      
      
    </div> 
  
  
    <!-- ================================= MODAL =================================  -->
  
    <MDL_ADD :modal="$store.state.mdl_add" :metode="addData">
        <template  v-slot:bodys>
            <div class="row"> 

                <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                    <div class="q-px-sm">
                        <span class="abu-800">Pemerintah Provinsi</span>
                        <select class="f_select" v-model="form.m_provinsi" style="width: 100%;" @change="loadListData()">
                            <option value="" :disabled="true">           Pilih Provinsi              </option>
                            <option v-for="(item, index) in l_prov" :key="item.m_provinsi" :value="item.m_provinsi" :disabled="true">  {{item.uraian}}         </option> 
                        </select>
                    </div>
                </div>

                <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                    <div class="q-px-sm">
                        <span class="abu-800">Pemerintah Kabupaten / Kota</span>
                        <select class="f_select" v-model="form.m_kabupaten" style="width: 100%;" @change="loadListData()">
                            <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                            <option v-for="(item, index) in l_kab" :key="item.m_kabupaten" :value="item.m_kabupaten" :disabled="true">  {{item.uraian}}         </option> 
                        </select>
                    </div>
                </div>

                <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                    <div class="q-px-sm">
                        <span class="abu-800">Kecamatan</span>
                        <select class="f_select" v-model="form.m_kecamatan" style="width: 100%;" @change="loadListData()" required>
                            <option value="">           Pilih Kecamatan              </option>
                            <option v-for="(item, index) in l_kec" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                        </select>
                    </div>
                </div>

                <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                    <div class="q-px-sm">
                        <span class="abu-800">Desa / Kelurahan {{ form.m_desa_kelurahan }}</span>
                        <select class="f_select" v-model="form.m_desa_kelurahan" style="width: 100%;" required>
                            <option value="">           Pilih Desa / Kelurahan              </option>
                            <option v-for="(item, index) in l_deskel" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                        </select>
                    </div>
                </div> 

                <div class="col-12  col-md-12 col-sm-12 col-xs-12">
                    <div class="q-px-sm">
                        
                        
                        <!-- ===================== BAYI ===================== -->
                        <br>


                        <div rounded class="bg-cyan-8 q-my-md">
                            <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b> DATA DAERAH ASAL </b></div>
                        </div>

                        <div  class="abu-800 text-labels">Nomor Kartu Keluarga</div>
                        <input  class="f_select" v-model="form.no_kk" type="text" placeholder="Nomor Kartu Keluarga" required>

                        <div  class="abu-800 text-labels">Nama Kepala Keluarga</div>
                        <input  class="f_select" v-model="form.nama_kepalaKeluarga" type="text" placeholder="Nama Kepala Keluarga" required>

                        <div  class="abu-800 text-labels">Alamat</div>
                        <input  class="f_select" v-model="form.alamat" type="text" placeholder="Alamat" required>
                        
                        <div class="row">
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Provinsi</span>
                                    <select class="f_select" v-model="form.provinsi" style="width: 100%;" @change="loadListData()">
                                        <option value="" :disabled="true">           Pilih Provinsi              </option>
                                        <option v-for="(item, index) in l_prov1" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                                    </select>
                                </div>
                            </div>
                
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Kabupaten / Kota</span>
                                    <select class="f_select" v-model="form.kabupaten" style="width: 100%;" @change="loadListData()">
                                        <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                        <option v-for="(item, index) in l_kab1" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                                    </select>
                                </div>
                            </div>
                
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Kecamatan</span>
                                    <select class="f_select" v-model="form.kecamatan" style="width: 100%;" @change="loadListData()" required>
                                        <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                        <option v-for="(item, index) in l_kec1" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                                    </select>
                                </div>
                            </div>
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Desa / Kelurahan </span>
                                    <select class="f_select" v-model="form.desa_kelurahan" style="width: 100%;" required>
                                        <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                        <option v-for="(item, index) in l_deskel1" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div  class="abu-800 text-labels">RT</div>
                        <input  class="f_select" v-model="form.rt" type="text" placeholder="RT" required>

                        <div  class="abu-800 text-labels">RW</div>
                        <input  class="f_select" v-model="form.rw" type="text" placeholder="RW" required>

                        <div  class="abu-800 text-labels">Kode Pos</div>
                        <input  class="f_select" v-model="form.kode_pos" type="text" placeholder="Kode Pos" required>

                        <div  class="abu-800 text-labels">Nomor Telepon</div>
                        <input  class="f_select" v-model="form.no_telp" type="text" placeholder="Nomor Telepon" required>

                        <div  class="abu-800 text-labels">NIK Pemohon</div>
                        <input  class="f_select" v-model="form.nik" type="text" placeholder="NIK Pemohon" required>

                        <div  class="abu-800 text-labels">Nama Pemohon</div>
                        <input  class="f_select" v-model="form.nama" type="text" placeholder="Nama Pemohon" required>

                        <div rounded class="bg-cyan-8 q-my-md">
                            <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b> DATA KEPINDAHAN </b></div>
                        </div>
                        
                        <div class="abu-800 text-labels">Alasan Pindah</div>
                        <select class="f_select" v-model="form.alasan_kepindahan" style="width: 100%;" required>
                            <option  value="" disabled>  - Pilih Alasan Pindah -          </option>  
                            <option  v-for="(item, index) in list_kepindahan" :key="index" :value="item.uraian"> {{item.uraian}} </option>
                        </select>
                        <div v-if="form.alasan_kepindahan == 'Lainnya'" class="abu-800 text-labels">Alasan Pindah Lainnya</div>
                        <input v-if="form.alasan_kepindahan == 'Lainnya'" class="f_select" v-model="form.alasan_kepindahan_lainnya" type="text" placeholder="Alasan Pindah Lainnya" required>

                        <div  class="abu-800 text-labels">Alamat Tujuan</div>
                        <input  class="f_select" v-model="form.alamat_tujuan" type="text" placeholder="Alamat Tujuan" required>
                        
                        <div class="row">
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Provinsi</span>
                                    <select class="f_select" v-model="form.provinsi_tujuan" style="width: 100%;" @change="loadListData()">
                                        <option value="" :disabled="true">           Pilih Provinsi              </option>
                                        <option v-for="(item, index) in l_prov2" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                                    </select>
                                </div>
                            </div>
                
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Kabupaten / Kota</span>
                                    <select class="f_select" v-model="form.kabupaten_tujuan" style="width: 100%;" @change="loadListData()">
                                        <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                        <option v-for="(item, index) in l_kab2" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                                    </select>
                                </div>
                            </div>
                
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Kecamatan</span>
                                    <select class="f_select" v-model="form.kecamatan_tujuan" style="width: 100%;" @change="loadListData()" required>
                                        <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                        <option v-for="(item, index) in l_kec2" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                                    </select>
                                </div>
                            </div>
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Desa / Kelurahan </span>
                                    <select class="f_select" v-model="form.desa_kelurahan_tujuan" style="width: 100%;" required>
                                        <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                        <option v-for="(item, index) in l_deskel2" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div  class="abu-800 text-labels">RT</div>
                        <input  class="f_select" v-model="form.rt_tujuan" type="text" placeholder="RT" required>

                        <div  class="abu-800 text-labels">RW</div>
                        <input  class="f_select" v-model="form.rw_tujuan" type="text" placeholder="RW" required>

                        <div  class="abu-800 text-labels">Kode Pos</div>
                        <input  class="f_select" v-model="form.kodePos_tujuan" type="text" placeholder="Kode Pos" required>

                        <div  class="abu-800 text-labels">Nomor Telepon</div>
                        <input  class="f_select" v-model="form.noTelp_tujuan" type="text" placeholder="Nomor Telepon" required>

                        <div class="abu-800 text-labels">Jenis Kepindahan</div>
                        <select class="f_select" v-model="form.jenis_kepindahan" style="width: 100%;" required>
                            <option  value="" disabled>  - Pilih Jenis Kepindahan -          </option>  
                            <option  v-for="(item, index) in list_jenis_kepindahan" :key="index" :value="item.uraian"> {{item.uraian}} </option>
                        </select>
                        
                        <div class="abu-800 text-labels">Status KK Bagi Yang Tidak Pindah</div>
                        <select class="f_select" v-model="form.status_kk_bagi_yang_tidak_pindah" style="width: 100%;" required>
                            <option  value="" disabled>  - Pilih Status KK Bagi Yang Tidak Pindah -          </option>  
                            <option  v-for="(item, index) in list_status_kk_bagi_yang_tidak_pindah" :key="index" :value="item.uraian"> {{item.uraian}} </option>
                        </select>
                        
                        <div class="abu-800 text-labels">Status KK Bagi Yang Pindah</div>
                        <select class="f_select" v-model="form.status_kk_bagi_yang_pindah" style="width: 100%;" required>
                            <option  value="" disabled>  - Pilih Status KK Bagi Yang Pindah -          </option>  
                            <option  v-for="(item, index) in list_status_kk_bagi_yang_pindah" :key="index" :value="item.uraian"> {{item.uraian}} </option>
                        </select>
                        
                        <!-- ================== -->


                        <!-- ===================== DATA ===================== -->
                        

                        <!-- ===================== BAYI ===================== -->
                    
                        
                        <div class="abu-800 text-labels">File Lampiran</div>
                        <q-file outlined v-model="form.file_lampiran" :dense="true" required>
                            <template v-slot:prepend> <q-icon name="attach_file" /> </template>
                        </q-file>

                        <div rounded class="bg-cyan-8 q-my-md">
                            <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b> </b></div>
                        </div>

                        <div class="row">
                            <div class="col-10">
                                <span>Anggota Keluarga Yang Pindah</span>
                            </div>
                            <div class="col-2 text-right">
                                <q-btn size="sm" class="bg-ijo-600" color="" icon="add" @click="mdl_add_kel = true, loadMasterAk()" />
                            </div>
                            <div rounded class="bg-cyan-8 q-my-md">
                                <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b> </b></div>
                            </div>

                            <div class="col-12">

                                <table id="table_view" style="width: 100%;">
                                    <tr class="bg-light-blue-9">
                                        <td class="text-white" colspan="7" width="100%">Anggota Keluarga</td> 
                                    </tr> 

                                    <tr class="bg-light-blue-7">
                                        <td class="text-white" style="font-size: 9pt;" width="45%">NAMA</td> 
                                        <td class="text-white" style="font-size: 9pt;" width="45%">NIK</td>  
                                        <td class="text-white text-center" style="font-size: 9pt;" width="10%">ACT</td>  
                                    </tr> 

                                    <tr class="" v-for="(item, index) in list_anggota_keluarga" :key="index">
                                        <td class="" style="font-size: 9pt;" >{{item.nama}}</td> 
                                        <td class="" style="font-size: 9pt;" >{{item.nik}}</td> 

                                        <td class="text-center" >
                                            <q-btn size="sm" class="bg-red-8" color="" icon="deleted" @click="delete_keluarga(item, index)" />
                                        </td> 
                                    </tr> 

                                </table>

                            </div>

                        </div>
                    </div>
                </div> 



            

            </div>  
        </template>
    </MDL_ADD>
  
    <MDL_EDT :modal="$store.state.mdl_edit" :metode="editData"> 
        <template  v-slot:bodys>
            <div class="row"> 

                <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                    <div class="q-px-sm">
                        <span class="abu-800">Pemerintah Provinsi</span>
                        <select class="f_select" v-model="form.m_provinsi" style="width: 100%;" @change="loadListData()">
                            <option value="" :disabled="true">           Pilih Provinsi              </option>
                            <option v-for="(item, index) in l_prov" :key="item.m_provinsi" :value="item.m_provinsi" :disabled="true">  {{item.uraian}}         </option> 
                        </select>
                    </div>
                </div>

                <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                    <div class="q-px-sm">
                        <span class="abu-800">Pemerintah Kabupaten / Kota</span>
                        <select class="f_select" v-model="form.m_kabupaten" style="width: 100%;" @change="loadListData()">
                            <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                            <option v-for="(item, index) in l_kab" :key="item.m_kabupaten" :value="item.m_kabupaten" :disabled="true">  {{item.uraian}}         </option> 
                        </select>
                    </div>
                </div>

                <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                    <div class="q-px-sm">
                        <span class="abu-800">Kecamatan</span>
                        <select class="f_select" v-model="form.m_kecamatan" style="width: 100%;" @change="loadListData()" required>
                            <option value="">           Pilih Kecamatan              </option>
                            <option v-for="(item, index) in l_kec" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                        </select>
                    </div>
                </div>
                <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                    <div class="q-px-sm">
                        <span class="abu-800">Desa / Kelurahan {{ form.m_desa_kelurahan }}</span>
                        <select class="f_select" v-model="form.m_desa_kelurahan" style="width: 100%;" required>
                            <option value="">           Pilih Desa / Kelurahan              </option>
                            <option v-for="(item, index) in l_deskel" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                        </select>
                    </div>
                </div>


                <div class="col-12  col-md-12 col-sm-12 col-xs-12">
                <div class="q-px-sm">
                    
                    
                    <!-- ===================== BAYI ===================== -->
                        <br>


                        <div rounded class="bg-cyan-8 q-my-md">
                            <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b> DATA DAERAH ASAL </b></div>
                        </div>

                        <div  class="abu-800 text-labels">Nomor Kartu Keluarga</div>
                        <input  class="f_select" v-model="form.no_kk" type="text" placeholder="Nomor Kartu Keluarga" required>

                        <div  class="abu-800 text-labels">Nama Kepala Keluarga</div>
                        <input  class="f_select" v-model="form.nama_kepalaKeluarga" type="text" placeholder="Nama Kepala Keluarga" required>

                        <div  class="abu-800 text-labels">Alamat</div>
                        <input  class="f_select" v-model="form.alamat" type="text" placeholder="Alamat" required>
                        
                        <div class="row">
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Provinsi</span>
                                    <select class="f_select" v-model="form.provinsi" style="width: 100%;" @change="loadListData()">
                                        <option value="" :disabled="true">           Pilih Provinsi              </option>
                                        <option v-for="(item, index) in l_prov1" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                                    </select>
                                </div>
                            </div>
                
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Kabupaten / Kota</span>
                                    <select class="f_select" v-model="form.kabupaten" style="width: 100%;" @change="loadListData()">
                                        <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                        <option v-for="(item, index) in l_kab1" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                                    </select>
                                </div>
                            </div>
                
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Kecamatan</span>
                                    <select class="f_select" v-model="form.kecamatan" style="width: 100%;" @change="loadListData()" required>
                                        <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                        <option v-for="(item, index) in l_kec1" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                                    </select>
                                </div>
                            </div>
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Desa / Kelurahan </span>
                                    <select class="f_select" v-model="form.desa_kelurahan" style="width: 100%;" required>
                                        <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                        <option v-for="(item, index) in l_deskel1" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div  class="abu-800 text-labels">RT</div>
                        <input  class="f_select" v-model="form.rt" type="text" placeholder="RT" required>

                        <div  class="abu-800 text-labels">RW</div>
                        <input  class="f_select" v-model="form.rw" type="text" placeholder="RW" required>

                        <div  class="abu-800 text-labels">Kode Pos</div>
                        <input  class="f_select" v-model="form.kode_pos" type="text" placeholder="Kode Pos" required>

                        <div  class="abu-800 text-labels">Nomor Telepon</div>
                        <input  class="f_select" v-model="form.no_telp" type="text" placeholder="Nomor Telepon" required>

                        <div  class="abu-800 text-labels">NIK Pemohon</div>
                        <input  class="f_select" v-model="form.nik" type="text" placeholder="NIK Pemohon" required>

                        <div  class="abu-800 text-labels">Nama Pemohon</div>
                        <input  class="f_select" v-model="form.nama" type="text" placeholder="Nama Pemohon" required>

                        <div rounded class="bg-cyan-8 q-my-md">
                            <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b> DATA KEPINDAHAN </b></div>
                        </div>
                        
                        <div class="abu-800 text-labels">Alasan Pindah</div>
                        <select class="f_select" v-model="form.alasan_kepindahan" style="width: 100%;" required>
                            <option  value="" disabled>  - Pilih Alasan Pindah -          </option>  
                            <option  v-for="(item, index) in list_kepindahan" :key="index" :value="item.uraian"> {{item.uraian}} </option>
                        </select>
                        <div v-if="form.alasan_kepindahan == 'Lainnya'" class="abu-800 text-labels">Alasan Pindah Lainnya</div>
                        <input v-if="form.alasan_kepindahan == 'Lainnya'" class="f_select" v-model="form.alasan_kepindahan_lainnya" type="text" placeholder="Alasan Pindah Lainnya" required>

                        <div  class="abu-800 text-labels">Alamat Tujuan</div>
                        <input  class="f_select" v-model="form.alamat_tujuan" type="text" placeholder="Alamat Tujuan" required>
                        
                        <div class="row">
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Provinsi</span>
                                    <select class="f_select" v-model="form.provinsi_tujuan" style="width: 100%;" @change="loadListData()">
                                        <option value="" :disabled="true">           Pilih Provinsi              </option>
                                        <option v-for="(item, index) in l_prov2" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                                    </select>
                                </div>
                            </div>
                
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Kabupaten / Kota</span>
                                    <select class="f_select" v-model="form.kabupaten_tujuan" style="width: 100%;" @change="loadListData()">
                                        <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                        <option v-for="(item, index) in l_kab2" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                                    </select>
                                </div>
                            </div>
                
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Kecamatan</span>
                                    <select class="f_select" v-model="form.kecamatan_tujuan" style="width: 100%;" @change="loadListData()" required>
                                        <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                        <option v-for="(item, index) in l_kec2" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                                    </select>
                                </div>
                            </div>
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                                <div class="q-px-sm">
                                    <span class="abu-800">Desa / Kelurahan </span>
                                    <select class="f_select" v-model="form.desa_kelurahan_tujuan" style="width: 100%;" required>
                                        <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                        <option v-for="(item, index) in l_deskel2" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div  class="abu-800 text-labels">RT</div>
                        <input  class="f_select" v-model="form.rt_tujuan" type="text" placeholder="RT" required>

                        <div  class="abu-800 text-labels">RW</div>
                        <input  class="f_select" v-model="form.rw_tujuan" type="text" placeholder="RW" required>

                        <div  class="abu-800 text-labels">Kode Pos</div>
                        <input  class="f_select" v-model="form.kodePos_tujuan" type="text" placeholder="Kode Pos" required>

                        <div  class="abu-800 text-labels">Nomor Telepon</div>
                        <input  class="f_select" v-model="form.noTelp_tujuan" type="text" placeholder="Nomor Telepon" required>

                        <div class="abu-800 text-labels">Jenis Kepindahan</div>
                        <select class="f_select" v-model="form.jenis_kepindahan" style="width: 100%;" required>
                            <option  value="" disabled>  - Pilih Jenis Kepindahan -          </option>  
                            <option  v-for="(item, index) in list_jenis_kepindahan" :key="index" :value="item.uraian"> {{item.uraian}} </option>
                        </select>
                        
                        <div class="abu-800 text-labels">Status KK Bagi Yang Tidak Pindah</div>
                        <select class="f_select" v-model="form.status_kk_bagi_yang_tidak_pindah" style="width: 100%;" required>
                            <option  value="" disabled>  - Pilih Status KK Bagi Yang Tidak Pindah -          </option>  
                            <option  v-for="(item, index) in list_status_kk_bagi_yang_tidak_pindah" :key="index" :value="item.uraian"> {{item.uraian}} </option>
                        </select>
                        
                        <div class="abu-800 text-labels">Status KK Bagi Yang Pindah</div>
                        <select class="f_select" v-model="form.status_kk_bagi_yang_pindah" style="width: 100%;" required>
                            <option  value="" disabled>  - Pilih Status KK Bagi Yang Pindah -          </option>  
                            <option  v-for="(item, index) in list_status_kk_bagi_yang_pindah" :key="index" :value="item.uraian"> {{item.uraian}} </option>
                        </select>
                        
                        <!-- ================== -->
                
                    
                    <div class="abu-800 text-labels">File Lampiran</div>
                    <q-file outlined v-model="form.file_lampiran" :dense="true">
                        <template v-slot:prepend> <q-icon name="attach_file" /> </template>
                    </q-file>

                    <div rounded class="bg-cyan-8 q-my-md">
                        <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b> </b></div>
                    </div>

                    <div class="row">
                        <div class="col-10">
                            <span>Anggota Keluarga Yang Pindah</span>
                        </div>
                        <div class="col-2 text-right">
                            <q-btn size="sm" class="bg-ijo-600" color="" icon="add" @click="mdl_edit_kel = true, loadMasterAk()" />
                        </div>
                        <div rounded class="bg-cyan-8 q-my-md">
                            <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b> </b></div>
                        </div>

                        <div class="col-12">

                            <table id="table_view" style="width: 100%;">
                                <tr class="bg-light-blue-9">
                                    <td class="text-white" colspan="7" width="100%">Anggota Keluarga</td> 
                                </tr> 

                                <tr class="bg-light-blue-7">
                                    <td class="text-white" style="font-size: 9pt;" width="45%">NAMA</td> 
                                    <td class="text-white" style="font-size: 9pt;" width="45%">NIK</td>  
                                    <td class="text-white text-center" style="font-size: 9pt;" width="10%">ACT</td>  
                                </tr> 

                                <tr class="" v-for="(item, index) in list_anggota_keluarga" :key="index">
                                    <td class="" style="font-size: 9pt;" >{{item.nama}}</td> 
                                    <td class="" style="font-size: 9pt;" >{{item.nik}}</td> 

                                    <td class="text-center" >
                                        <q-btn size="sm" class="bg-red-8" color="" icon="deleted" @click="mdl_del_kel = true,  hapusKeluarga(item, index)" />

                                    </td> 
                                </tr> 

                            </table>

                        </div>

                    </div>
                </div>
                </div> 



            

            </div>  
        </template>
    </MDL_EDT>
  
    <MDL_DEL :modal="$store.state.mdl_del" :metode="removeData"/>

 

    <q-dialog v-model="$store.state.mdl_view" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-primary">
                        <span class="q-ma-md text-white">LIHAT DATA</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-primary">
                        <a @click="$store.state.mdl_view=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <q-separator />
 
                <q-card-section>
                    <div class="q-px-xs q-py-md">
                        <table id="table_view" style="width: 100%;">
                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">WILAYAH PEMERINTAH</td> 
                            </tr> 
                            <tr>
                                <td width="50%">Pemerintah Provinsi</td>
                                <td width="50%">{{form_view.uraian_1_provinsi}}</td>
                            </tr> 
                            <tr>
                                <td>Pemerintah Kabupaten / Kota</td>
                                <td>{{form_view.uraian_1_kabupaten}}</td>
                            </tr> 
                            <tr>
                                <td>Kecamatan</td>
                                <td>{{form_view.uraian_1_kecamatan}}</td>
                            </tr> 
                            <tr>
                                <td>Desa / Kelurahan</td>
                                <td>{{form_view.uraian_1_desa}}</td>
                            </tr> 
                        </table>

                        <br>

                        <table id="table_view" style="width: 100%;">

                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">DATA DAERAH ASAL</td> 
                            </tr>
                            <tr>
                                <td>Nomor Kartu Keluarga</td>
                                <td>{{form.no_kk}}</td>
                            </tr>
                            <tr>
                                <td>Nama Kepala Keluarga</td>
                                <td>{{form.nama_kepalaKeluarga}}</td>
                            </tr>
                            <tr>
                                <td>Alamat</td>
                                <td>{{form.alamat}}</td>
                            </tr>
                                <tr>
                                    <td>Provinsi</td>
                                    <td>{{form_view.uraian_provinsi}}</td>
                                </tr>
                                <tr>
                                    <td>Kabupaten / Kota</td>
                                    <td>{{form_view.uraian_kabupaten}}</td>
                                </tr>
                                <tr>
                                    <td>Kecamatan</td>
                                    <td>{{form_view.uraian_kecamatan}}</td>
                                </tr>
                                <tr>
                                    <td>Desa / Kelurahan </td>
                                    <td>{{form_view.uraian_desa}}</td>
                                </tr>
                            <tr>
                                <td>RT </td>
                                <td>{{form.rt}}</td>
                            </tr>
                            <tr>
                                <td>RW </td>
                                <td>{{form.rw}}</td>
                            </tr>
                            <tr>
                                <td>Kode Pos </td>
                                <td>{{form.kode_pos}}</td>
                            </tr>
                            <tr>
                                <td>Nomor telepon </td>
                                <td>{{form.no_telp}}</td>
                            </tr>
                            <tr>
                                <td>NIK Pemohon </td>
                                <td>{{form.nik}}</td>
                            </tr>
                            <tr>
                                <td>Nama Pemohon</td>
                                <td>{{form.nama}}</td>
                            </tr>


                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">DATA KEPINDAHAN</td> 
                            </tr>
                            <tr>
                                <td>Alasan Pindah </td>
                                <td v-if="form.alasan_kepindahan == 'Lainnya'">{{form.alasan_kepindahan_lainnya}}</td>
                                <td v-else>{{form.alasan_kepindahan}}</td>
                            </tr>
                            <tr>
                                <td>Alamat </td>
                                <td>{{form.alamat_tujuan}}</td>
                            </tr>
                                <tr>
                                    <td>Provinsi</td>
                                    <td>{{form_view.uraian_provinsi2}}</td>
                                </tr>
                                <tr>
                                    <td>Kabupaten / Kota</td>
                                    <td>{{form_view.uraian_kabupaten2}}</td>
                                </tr>
                                <tr>
                                    <td>Kecamatan</td>
                                    <td>{{form_view.uraian_kecamatan2}}</td>
                                </tr>
                                <tr>
                                    <td>Desa / Kelurahan </td>
                                    <td>{{form_view.uraian_desa2}}</td>
                                </tr>

                            <tr>
                                <td>RT </td>
                                <td>{{form.rt_tujuan}}</td>
                            </tr>
                            <tr>
                                <td>RW </td>
                                <td>{{form.rw_tujuan}}</td>
                            </tr>
                            <tr>
                                <td>Kode Pos </td>
                                <td>{{form.kodePos_tujuan}}</td>
                            </tr>
                            <tr>
                                <td>Nomor Telepon </td>
                                <td>{{form.noTelp_tujuan}}</td>
                            </tr>
                            <tr>
                                <td>Jenis Kepindahan </td> 
                                <td>{{form.jenis_kepindahan}}</td>
                            </tr>
                            <tr>
                                <td>Status KK Bagi Yang Tidak Pindah </td> 
                                <td>{{form.status_kk_bagi_yang_tidak_pindah}}</td>
                            </tr>
                            <tr>
                                <td>Status KK Bagi Yang Pindah </td> 
                                <td>{{form.status_kk_bagi_yang_pindah}}</td>
                            </tr>

                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">LAMPIRAN</td> 
                            </tr>
                            <tr>
                                <td>Lampiran</td>
                                <td> 
                                    <q-btn color="blue-5" icon="source" size="sm" @click="$store.state.mdl_lihat_file=true, lihatFilex(form.file_lampiran)">
                                        <span class="q-ml-xs">lihat Lampiran</span>
                                        <q-tooltip class="bg-indigo" :offset="[0, -60]"> Lihat </q-tooltip>
                                    </q-btn>
                                </td>
                            </tr>
                        </table>

                        <br>

                        <table id="table_view" style="width: 100%;">
                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="4" width="100%">ANGGOTA KELUARGA YANG PINDAH</td> 
                            </tr> 
                            <tr>
                                <td class="text-center" width="10%">NO</td>
                                <td class="" width="30%">Nama</td>
                                <td class="" width="30%">NIK</td>
                                <td class="" width="30%">SHDK</td>
                            </tr> 
                            <tr v-for="(item, index) in list_anggota_keluarga" :key="index">
                                <td class="text-center">{{index+1}}</td>
                                <td>{{item.nama}}</td>
                                <td>{{item.nik}}</td>
                                <td v-if="item.shdk == 'Lainnya' ">{{item.shdk}}</td>
                                <td v-else>{{item.shdk}}</td>
                            </tr> 
                           
                        </table>

                        <br>
 
                    </div>

                </q-card-section>
                <q-separator />
    
                <q-card-actions align="right">
                    <div class="q-mx-xs q-gutter-xs" v-show="checkKelasUser('kabupaten') || checkKelasUser('administrator')">
                        <q-btn size="sm" label="Verifikasi Kabupaten" color="primary" @click="$store.state.mdl_verifikasi_kab = true" />
                        <q-btn size="sm" label="Tolak Kabupaten" color="negative" @click="$store.state.mdl_kembalikan_kab = true" />
                    </div>
                    <div class="q-mx-xs q-gutter-xs" v-show="checkKelasUser('verifikator') || checkKelasUser('administrator')">
                        <q-btn size="sm" :disable="form.status_kabupaten == 2" label="Verifikasi Kecamatan" color="primary"  @click="$store.state.mdl_verifikasi_kec= true" />
                        <q-btn size="sm" :disable="form.status_kabupaten == 2" label="Tolak Kecamatan" color="negative"  @click="$store.state.mdl_kembalikan_kec= true" />
                    </div>
                    <div class="q-mx-xs q-gutter-xs">
                        <q-btn size="sm" label="Kembali" color="negative" @click="$store.state.mdl_view = false"   />
                    </div>
                </q-card-actions>
                
   
        </q-card>
    </q-dialog>    
    
    <q-dialog v-model="$store.state.mdl_history" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-primary">
                        <span class="q-ma-md text-white">HISTORY</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-primary">
                        <a @click="$store.state.mdl_history=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <q-separator />
 
                <q-card-section>
                    <div class="q-px-lg q-py-md">
                        <q-timeline color="secondary">
                        <q-timeline-entry heading class="">
                            Timeline SOP
                        </q-timeline-entry>

                         

                        <q-timeline-entry icon="check">
                            <template v-slot:title>
                            INPUT DATA
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt).tgl}} |
                                {{UMUM.tglConvert(form.createdAt).time}}
                            </template>

                            <div>
                                Tahap pengunputan permohonan berhasil.
                            </div>
                        </q-timeline-entry>

                        <!-- ==================== -->

                        <q-timeline-entry icon="hourglass_empty" color="orange-8" v-if="form.status_kecamatan == 0">
                            <template v-slot:title>
                            TAHAPAN KECAMATAN
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt_kecamatan).tgl}} |
                                {{UMUM.tglConvert(form.createdAt_kecamatan).time}} 
                            </template>

                            <div>
                                Menunggu verifikasi kecamatan.
                            </div>
                        </q-timeline-entry>
                        
                        <q-timeline-entry icon="check" color="blue-8" v-if="form.status_kecamatan == 1" >
                            <template v-slot:title>
                            TAHAPAN KECAMATAN
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt_kecamatan).tgl}} |
                                {{UMUM.tglConvert(form.createdAt_kecamatan).time}} 
                            </template>

                            <div>
                                Data Telah Diverifikasi. <br>
                                {{ form.keterangan_kecamatan }}
                            </div>
                        </q-timeline-entry>

                        <q-timeline-entry icon="close" color="red-8" v-if="form.status_kecamatan == 2" >
                            <template v-slot:title>
                                TAHAPAN KECAMATAN
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt_kecamatan).tgl}} |
                                {{UMUM.tglConvert(form.createdAt_kecamatan).time}} 
                            </template>

                            <div>
                                Dikembalikan. <br>
                                {{ form.keterangan_kecamatan }}

                            </div>
                        </q-timeline-entry> 

                        <!-- ================================ -->

                        <q-timeline-entry icon="hourglass_empty" color="orange-8" v-if="form.status_kabupaten == 0">
                            <template v-slot:title>
                            TAHAPAN KABUPATEN
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt_kabupaten).tgl}} |
                                {{UMUM.tglConvert(form.createdAt_kabupaten).time}} 
                            </template>

                            <div>
                                Menunggu verifikasi kabupaten
                            </div>
                        </q-timeline-entry>
                        
                        <q-timeline-entry icon="check" color="blue-8" v-if="form.status_kabupaten == 1" >
                            <template v-slot:title>
                            TAHAPAN KABUPATEN
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt_kabupaten).tgl}} |
                                {{UMUM.tglConvert(form.createdAt_kabupaten).time}} 
                            </template>

                            <div>
                                Data Telah Diverifikasi. <br>
                                {{form.keterangan_kabupaten}}
                            </div>
                        </q-timeline-entry>

                        <q-timeline-entry icon="close" color="red-8" v-if="form.status_kabupaten == 2" >
                            <template v-slot:title>
                                TAHAPAN KABUPATEN
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt_kabupaten).tgl}} |
                                {{UMUM.tglConvert(form.createdAt_kabupaten).time}} 
                            </template>

                            <div>
                                Dikembalikan. <br>
                                {{ form.keterangan_kabupaten }}
                            </div>
                        </q-timeline-entry> 


                        
                        </q-timeline>
                    </div>

                </q-card-section>
                <q-separator />
    
                <q-card-actions align="center">
                    <!-- <q-btn size="sm" label="Tambah" color="primary" type="submit" /> -->
                    <q-btn size="sm" label="Kembali" color="negative" @click="$store.state.mdl_history = false"   />
                </q-card-actions>
                
   
        </q-card>
    </q-dialog>    
    
    <q-dialog v-model="$store.state.mdl_verifikasi_kec" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-primary">
                        <span class="q-ma-md text-white">VERIFIKASI</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-primary">
                        <a @click="$store.state.mdl_verifikasi_kec=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <q-separator />
 
                <q-card-section>
                    <div class="q-px-lg q-py-md">
                        <div class="abu-800 text-labels">Masukan Informasi Verifikasi</div>
                        <div class="abu-800 text-labels q-mb-xs">* Informasi ini berupa keterangan dari verifikator yang akan di lihat oleh pemohon dan verifikator</div>
                        <q-input v-model="form.keterangan_kecamatan" filled type="textarea" style="width:100%"/>
                    </div>
                </q-card-section>
                <q-separator />
    
                <q-card-actions align="center">
                    <q-btn size="sm" label="Kirim" color="primary" type="submit" @click="editDataKecamatan('diterima')" />
                    <q-btn size="sm" label="Kembali" color="negative" @click="$store.state.mdl_verifikasi_kec = false"   />
                </q-card-actions>
                
   
        </q-card>
    </q-dialog>    

    <q-dialog v-model="$store.state.mdl_kembalikan_kec" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-negative">
                        <span class="q-ma-md text-white">KEMBALIKAN</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-negative">
                        <a @click="$store.state.mdl_kembalikan_kec=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <q-separator />
 
                <q-card-section>
                    
                    <div class="q-px-lg q-py-md">
                        <div class="abu-800 text-labels">Masukan Informasi Pengembalian</div>
                        <div class="abu-800 text-labels q-mb-xs">* Informasi ini berupa keterangan dari verifikator mengapa data di kembalikan yang akan di lihat oleh pemohon</div>
                        <q-input v-model="form.keterangan_kecamatan" filled type="textarea" style="width:100%"/>
                    </div>

                </q-card-section>
                <q-separator />
    
                <q-card-actions align="center">
                    <q-btn size="sm" label="Tambah" color="primary" @click="editDataKecamatan('dikembalikan')" />
                    <q-btn size="sm" label="Kembali" color="negative" @click="$store.state.mdl_kembalikan_kec = false"   />
                </q-card-actions>
                
   
        </q-card>
    </q-dialog>    
   

    <q-dialog v-model="$store.state.mdl_verifikasi_kab" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-primary">
                        <span class="q-ma-md text-white">VERIFIKASI</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-primary">
                        <a @click="$store.state.mdl_verifikasi_kab=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <q-separator />
 
                <q-card-section>
                    <div class="q-px-lg q-py-md">
                        <div class="abu-800 text-labels">Masukan Informasi Verifikasi</div>
                        <div class="abu-800 text-labels q-mb-xs">* Informasi ini berupa keterangan dari verifikator yang akan di lihat oleh pemohon</div>
                        <q-input v-model="form.keterangan_kabupaten" filled type="textarea" style="width:100%"/>
                        
                        <!-- <div class="abu-800 text-labels">Masukan Informasi Verifikasi</div>
                        <q-input v-model="form.keterangan_kabupaten" filled type="textarea" style="width:100%"/> -->
                        
                        <div class="abu-800 text-labels">Masukan File PDF</div> 
                        <q-file outlined square :dense="true" v-model="form.email_file" label="Upload Foto KTP" filled counter>
                            <template v-slot:prepend>
                                <q-icon name="attach_file" />
                            </template>
                        </q-file>  

                        <!-- <div class="abu-800 text-labels">Masukan pesan yang akan di kirim ke email pemohon</div>
                        <q-input v-model="form.email_html" filled type="textarea" style="width:100%"/>  -->

                    </div>
                </q-card-section>
                <q-separator />
    
                <q-card-actions align="center">
                    <q-btn size="sm" label="Kirim" color="primary" type="submit" @click="editDataKabupaten('diterima')" />
                    <q-btn size="sm" label="Kembali" color="negative" @click="$store.state.mdl_verifikasi_kab = false"   />
                </q-card-actions>
                
   
        </q-card>
    </q-dialog>    

    <q-dialog v-model="$store.state.mdl_kembalikan_kab" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-negative">
                        <span class="q-ma-md text-white">KEMBALIKAN</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-negative">
                        <a @click="$store.state.mdl_kembalikan_kab=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <q-separator />
 
                <q-card-section>
                    
                    <div class="q-px-lg q-py-md">
                        <div class="abu-800 text-labels">Masukan Informasi Pengembalian</div>
                        <div class="abu-800 text-labels q-mb-xs">* Informasi ini berupa keterangan dari verifikator mengapa data di kembalikan yang akan di lihat oleh pemohon</div>
                        <q-input v-model="form.keterangan_kabupaten" filled type="textarea" style="width:100%"/>
                    </div>

                </q-card-section>
                <q-separator />
    
                <q-card-actions align="center">
                    <q-btn size="sm" label="Tambah" color="primary" @click="editDataKabupaten('dikembalikan')" />
                    <q-btn size="sm" label="Kembali" color="negative" @click="$store.state.mdl_kembalikan_kab = false"   />
                </q-card-actions>
                
   
        </q-card>
    </q-dialog>    
    

    <q-dialog v-model="$store.state.mdl_lihat_file" persistent>
            <q-card style="width: 700px; max-width: 80vw;"> 
                <div class="fit row wrap justify-center items-center content-start">
                    <div class="col-11">
                        <div class="q-pa-md text-center bg-primary">
                            <span class="q-ma-md text-white">LIHAT FILE</span>
                        </div>
                    </div>
                    <div class="col-1">
                        <div clickable class="q-pa-md text-center bg-primary">
                            <a href="javascript:void(0)" style="text-decoration: none;" v-close-popup>
                                <span class="q-ma-md text-white"><b>x</b></span>
                            </a>
                        </div>
                    </div>

                </div>
                <q-separator />
 
                    <q-card-section style="height: 630px !important; width: auto">   
                        <q-img v-if="$store.state.gambarx" :src="$store.state.url.URL_APP+'uploads/'+$store.state.filex" spinner-color="white" style="height:auto; width: 100%" />
                        <object v-else :data="$store.state.url.URL_APP+'uploads/'+$store.state.filex" type="application/pdf" width="100%" height="600px">
                            <p>Apabila file tidak langsung terbuka, sliahkan klik <a :href="$store.state.url.URL_APP+'uploads/'+$store.state.filex"> link ini untuk ke PDF!</a></p>
                        </object> 
                    </q-card-section>
                    <q-separator />
        
                    <q-card-actions align="center"> 
                        <q-btn size="sm" label="Kembali" color="negative"  v-close-popup  />
                    </q-card-actions> 
            </q-card>
    </q-dialog> 

    <q-dialog v-model="mdl_add_kel" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-primary">
                        <span class="q-ma-md text-white">TAMBAH ANGGOTA KELUARGA</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-primary">
                        <a @click="mdl_add_kel = false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <!-- <q-separator /> -->
            <form v-on:submit.prevent="addKeluarga()"> 
                <q-card-section>

                    <div  class="abu-800 text-labels">Nomor</div>
                    <input  class="f_select" v-model="anggota_keluarga.nomor" type="number" placeholder="Nama Lengkap Pemohon" required>
                    <div  class="abu-800 text-labels">NIK</div>
                    <input  class="f_select" v-model="anggota_keluarga.nik" type="text" placeholder="Nama Lengkap Pemohon" required>
                    <div  class="abu-800 text-labels">Nama</div>
                    <input  class="f_select" v-model="anggota_keluarga.nama" type="text" placeholder="Nama Lengkap Pemohon" required>
                    <div  class="abu-800 text-labels">Jenis Kelamin</div>
                    
                    
                    <div  class="abu-800 text-labels">Status Hubungan Dalam Keluarga </div>
                    <select class="f_select" v-model="anggota_keluarga.shdk" style="width: 100%;" required>
                        <option  value="" disabled>  - Pilih Status Hubungan Dalam Keluarga -          </option>  
                        <option v-for="(item, index) in list_shdk" :key="index" :value="item.uraian"> {{item.uraian}} </option>
                    </select>
                    <div v-if="anggota_keluarga.shdk == 'Lainnya'" class="abu-800 text-labels">Agama Lainnya</div>
                    <input v-if="anggota_keluarga.shdk == 'Lainnya'" class="f_select" v-model="anggota_keluarga.shdk_lainnya" type="text" placeholder="SHDK Lainnya" required>



                </q-card-section>
                
                <q-separator />

                <q-card-actions align="center">
                    <q-btn size="sm" label="Tambah" color="primary" type="submit" />
                    <q-btn size="sm" label="Kembali" color="negative" @click="mdl_add_kel = false"   />
                </q-card-actions>
                
            </form>
        </q-card>
    </q-dialog>   

    <q-dialog v-model="mdl_edit_kel" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-primary">
                        <span class="q-ma-md text-white">TAMBAH ANGGOTA KELUARGA</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-primary">
                        <a @click="mdl_add_kel = false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <!-- <q-separator /> -->
            <form v-on:submit.prevent="editKeluarga()"> 
                <q-card-section>

                    <div  class="abu-800 text-labels">Nomor</div>
                    <input  class="f_select" v-model="anggota_keluarga.nomor" type="number" placeholder="Nama Lengkap Pemohon" required>
                    <div  class="abu-800 text-labels">NIK</div>
                    <input  class="f_select" v-model="anggota_keluarga.nik" type="text" placeholder="Nama Lengkap Pemohon" required>
                    <div  class="abu-800 text-labels">Nama</div>
                    <input  class="f_select" v-model="anggota_keluarga.nama" type="text" placeholder="Nama Lengkap Pemohon" required>
                    <div  class="abu-800 text-labels">Jenis Kelamin</div>
                    
                    
                    <div  class="abu-800 text-labels">Status Hubungan Dalam Keluarga </div>
                    <select class="f_select" v-model="anggota_keluarga.shdk" style="width: 100%;" required>
                        <option  value="" disabled>  - Pilih Status Hubungan Dalam Keluarga -          </option>  
                        <option v-for="(item, index) in list_shdk" :key="index" :value="item.uraian"> {{item.uraian}} </option>
                    </select>
                    <div v-if="anggota_keluarga.shdk == 'Lainnya'" class="abu-800 text-labels">Agama Lainnya</div>
                    <input v-if="anggota_keluarga.shdk == 'Lainnya'" class="f_select" v-model="anggota_keluarga.shdk_lainnya" type="text" placeholder="SHDK Lainnya" required>



                </q-card-section>
                
                <q-separator />

                <q-card-actions align="center">
                    <q-btn size="sm" label="Tambah" color="primary" type="submit" />
                    <q-btn size="sm" label="Kembali" color="negative" @click="mdl_add_kel = false"   />
                </q-card-actions>
                
            </form>
        </q-card>
    </q-dialog>   

    <q-dialog v-model="mdl_del_kel" persistent>
        <q-card> 
            <div class="q-pa-md text-center bg-negative">
            <span class="q-ma-md text-white">Hapus</span>
            </div>
            <q-separator />
            <q-card-section class="row  text-center">
                <div class="col-12 text-center items-center">
                    <q-avatar icon="delete_forever" color="negative" text-color="white" />
                </div>
            
                <div class="col-12 text-center items-center">
                <div >Yakin anda akan menghaus data ini.?</div> 
                </div>
            </q-card-section>
            <q-separator />

            <q-card-actions align="center">
            <q-btn  label="Ya" color="primary" @click="hapusKeluarga" style="width: 100px;" />
            <q-btn  label="Tidak" color="negative" @click="mdl_del_kel = false"  style="width: 100px;" />
            </q-card-actions>
        </q-card>
    </q-dialog> 
  
    <!-- ================================= MODAL =================================  -->
  
  </div>
   
  
  </template>
  
  
  
  <script>
   
    import FETCHING from "../../library/fetching";
    import UMUM from "../../library/umum";
    import uniqid from 'uniqid';
  
    export default {
        name: 'HelloWorld',
        components: {
    },
  
    data() {
        return {
          mdl_add_kel:false,
          mdl_edit_kel:false,
          mdl_del_kel:false,

          FETCHING    :FETCHING,
          UMUM        :UMUM,
  
          search          :'',
  
          list_data       :[],
  
          filter:{
              data_ke     : 1,
              page_limit  : 10,
              cari_value  : '',
              cari_level  : '',
              page_last   :  0,

              prov      :'74',
              kab       :'7412',
              kec       :'',
              deskel    :'',

              status    :'0',
          },


          form: { 
                id:'',
                KKref:'',
                id_dari_form:'', 
                modul                         : 'ppd3',
                m_provinsi                    : '74',
                m_kabupaten                   : '7412',
                m_kecamatan                   : '',
                m_desa_kelurahan              : '',    

                no_kk                :'',
                nama_kepalaKeluarga  :'',
                alamat               :'',
                rt                   :'',
                rw                   :'',
                provinsi             :'',
                kabupaten            :'',
                kecamatan            :'',  
                desa_kelurahan       :'',
                kode_pos             :'',
                no_telp              :'', 

                nik                  :'',
                nama                 :'',

                alasan_kepindahan         :'',
                alasan_kepindahan_lainnya :'',

                alamat_tujuan         : '',
                rt_tujuan             : '',
                rw_tujuan             : '',
                provinsi_tujuan       :'',
                kabupaten_tujuan      :'',
                kecamatan_tujuan      :'',  
                desa_kelurahan_tujuan :'',
                kodePos_tujuan        :'',
                noTelp_tujuan         :'',


                jenis_kepindahan:'',
                status_kk_bagi_yang_tidak_pindah:'',
                status_kk_bagi_yang_pindah:'',

                shdk:'',
                shdk_lainnya:'', 


                status                      : 0,
                keterangan                  : 0,
                keterangan_deskel           : '',
                keterangan_kecamatan        : '',
                keterangan_kabupaten        : '',
                status_deskel               : 0,
                status_kecamatan            : 0,
                status_kabupaten            : 0,
                createdBy                   : '',
                createdAt                   : '',
                createdAt_deskel            : '',
                createdAt_kecamatan         : '',
                createdAt_kabupaten         : '',  

                file_terbitan                     : null,
                emailPemohon                      :'',
                email_file                        :null,
                email_fileOld                     :'',
                email_from                        :'',
                email_to                          :'',
                email_subject                     :'',
                email_html                        :'', 
                file              : null, 
                fileThumb         : null, 
                file_lampiran     :null,
                file_lampiranOld  : null, 
            //   ======== admin ==========

              
            
            },

            list_kepindahan:[ {uraian:'Pekerjaan'},{uraian:'Pendidikan'},{uraian:'Keamanan'},{uraian:'Kesehatan'},{uraian:'Perumahan'},{uraian:'Keluarga'},{uraian:'Lainnya'} ],
            list_jenis_kepindahan:[ {uraian:'Kep. Keluarga'},{uraian:'Kep. Keluarga dan Seluruh Angg. Keluarga '},{uraian:'Kep. Keluarga dan Sbg. Angg. Keluarga'},{uraian:'Angg. Keluarga'}, ],
            list_status_kk_bagi_yang_tidak_pindah:[ {uraian:'Numpang KK'},{uraian:'Membuat KK Baru'},{uraian:'Nomor KK Tetap'} ],
            list_status_kk_bagi_yang_pindah:[ {uraian:'Numpang KK'},{uraian:'Membuat KK Baru'},{uraian:'Nomor KK Tetap'} ],

            list_anggota_keluarga           : [], 
            anggota_keluarga: {
                nomor           :'',
                nik             : '',
                nama            : '',
                tglKTP_dari     : '',
                tglKTP_hingga   : '',  
                shdk            :'',
                shdk_lainnya    :'',
            },



            form_view : {
                uraian_1_provinsi          : "",
                uraian_1_kabupaten         : "",
                uraian_1_kecamatan         : "",
                uraian_1_desa              : "",    

                uraian_provinsi            :'',
                uraian_kabupaten           :'',
                uraian_kecamatan           :'',
                uraian_desa                :'',

                uraian_provinsi2            :'',
                uraian_kabupaten2           :'',
                uraian_kecamatan2           :'',
                uraian_desa2                :'',

                uraian_provinsi_tujuan            :'',
                uraian_kabupaten_tujuan           :'',
                uraian_kecamatan_tujuan           :'',
                uraian_desa_tujuan                :'',
            },
            
            f_prov          : [],
            f_kab           : [],
            f_kec           : [],
            f_deskel            : [],

            l_prov        :[],
            l_kab         :[],
            l_kec         :[],
            l_deskel      :[],  

            l_prov1             : [],
            l_kab1              : [],
            l_kec1              : [],
            l_deskel1               : [],

            l_prov2             : [],
            l_kab2              : [],
            l_kec2              : [],
            l_deskel2               : [],

            list_shdk                   : [],
            list_agama                  : [],
            list_pekerjaan              : [],
            list_pendidikan             : [],
  
        }
    },
    methods: { 
        klikOk(){
            console.log('Ok Ok');
        },

        addKeluarga(){
            var arr = this.list_anggota_keluarga; 
            arr.push(this.anggota_keluarga);

            this.anggota_keluarga = {
                nomor           :'',
                nik             : '',
                nama            : '',
                tglKTP_dari     : '',
                tglKTP_hingga   : '',  
                shdk            :'',
                shdk_lainnya    :'',             
            }
        },
        delete_keluarga(index){  
            this.list_anggota_keluarga.splice(index, 1);  
        // this.$store.commit('notif_berhasil_menghapus');        
        },

        async editKeluarga(){
            this.$store.commit('loadingStart')

            var a = this.form.KKref  
            this.anggota_keluarga.KKref = a 
            // console.log('aaaaa = ', a);

            var formData = new FormData();  
            formData.append("biodata", JSON.stringify(this.anggota_keluarga));
            formData.append("file", this.form.file);


            var a = await FETCHING.fetch_postx_file(this.$store.state.url.URL_PERMOHONAN_PPD3+'editData_AK', formData)         
              var arr = this.list_anggota_keluarga; 
              arr.push(this.anggota_keluarga);
              
              this.anggota_keluarga = {
                nomor           :'',
                nik             : '',
                nama            : '',
                tglKTP_dari     : '',
                tglKTP_hingga   : '',  
                shdk            :'',
                shdk_lainnya    :'',
              }

            this.mdl_edit_kel = false
            await UMUM.notifikasi(a)
            this.$store.commit('loadingStop') 
            this.getView();
        },
        async hapusKeluarga(data, index){
            // console.log('hapusKeluarga');
            // console.log(data);
            // console.log(index);
            this.$store.commit('loadingStart')
            
            var idk = data._id  
            var a = await FETCHING.fetch_postx(this.$store.state.url.URL_PERMOHONAN_PPD3+'hapus_keluarga', JSON.stringify({
                id : idk
            }))         
            this.list_anggota_keluarga.splice(index, 1); 

            this.mdl_edit_kel = false
            await UMUM.notifikasi(a)
            this.$store.commit('loadingStop') 
            this.getView();
        },
  
        async getView(){  
            this.$store.commit('loadingStart')
            var a = await FETCHING.fetch_view(this.$store.state.url.URL_PERMOHONAN_PPD3+'view', JSON.stringify({
                data_ke     : this.filter.data_ke,
                page_limit  : this.filter.page_limit,
                cari_value  : this.filter.cari_value,
                status      : this.filter.status,

                f_prov      : this.filter.prov,
                f_kab       : this.filter.kab,
                f_kec       : this.filter.kec,
                f_des       : this.filter.deskel,

            })
            )
            this.list_data          = a.data
            this.filter.page_last   = a.jmlData;
            console.log(a);
            this.$store.commit('loadingStop')
        },
  
        async addData(){  
            
            this.$store.commit('loadingStart')

            var formData = new FormData(); 
            formData.append("data", JSON.stringify(this.form));
            formData.append("biodata", JSON.stringify(this.list_anggota_keluarga)); 
            formData.append("file", this.form.file); 
            formData.append("file_lampiran", this.form.file_lampiran);

            var a = await FETCHING.fetch_postx_file(this.$store.state.url.URL_PERMOHONAN_PPD3+'addData', formData)         
            this.$store.state.mdl_add = false
            await UMUM.notifikasi(a)
            this.$store.commit('loadingStop') 
            this.getView();
        }, 

        async editData(){  
            this.$store.commit('loadingStart')
            var formData = new FormData(); 
            formData.append("data", JSON.stringify(this.form));
            formData.append("biodata", JSON.stringify(this.list_anggota_keluarga));
            formData.append("file", this.form.file); 
            formData.append("file_lampiran", this.form.file_lampiran);

            var a = await FETCHING.fetch_postx_file(this.$store.state.url.URL_PERMOHONAN_PPD3+'editData', formData)         
            this.$store.state.mdl_add = false
            await UMUM.notifikasi(a)
            this.$store.commit('loadingStop') 
            this.getView();
        }, 
  
        async removeData(){  
            console.log('removeData');
            this.$store.commit('loadingStart')
            var a = await FETCHING.fetch_postx(this.$store.state.url.URL_PERMOHONAN_PPD3+'removeData', JSON.stringify(this.form) ) 
            this.$store.commit('loadingStop')
            await UMUM.notifikasi(a)
            this.$store.state.mdl_del = false
            this.getView();  
        },
  
        async editDataKecamatan(status){  
            this.$store.commit('loadingStart')
            var a = await FETCHING.fetch_postx(this.$store.state.url.URL_PERMOHONAN_PPD3+'editDataKecamatan', JSON.stringify({
                id : this.form.id,
                keterangan_kecamatan : this.form.keterangan_kecamatan,
                createdAt_kecamatan : UMUM.NOW(),
                kategorix : status
            })) 
            this.$store.commit('loadingStop')
            await UMUM.notifikasi(a)
            this.$store.state.mdl_del = false
            this.getView();  
        },
        async editDataKabupaten(status){  
            this.form.email_html = this.form.keterangan_kabupaten
            this.$store.commit('loadingStart')

            var formData = new FormData();   
            formData.append("id", this.form.id);
            formData.append("keterangan_kabupaten", this.form.keterangan_kabupaten);
            formData.append("createdAt_kabupaten", UMUM.NOW());
            formData.append("kategorix", status);

            formData.append("email_file", this.form.email_file); 
            formData.append("email_fileOld", this.form.email_fileOld);  
            formData.append("email_to", this.form.email_to)
            formData.append("email_subject", this.form.email_subject)  
            formData.append("email_html", this.form.email_html)


            var a = await FETCHING.fetch_postx_file(this.$store.state.url.URL_PERMOHONAN_PPD3+'editDataKabupaten', formData) 
            this.$store.commit('loadingStop')
            
            
            await UMUM.notifikasi(a)
            this.$store.state.mdl_del = false
            this.getView();  
        },
  
        async loadListData(){
            this.$store.commit('loadingStart')
            
            this.f_prov         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.f_kab          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.filter.prov }))
            this.f_kec          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.filter.kab }))
            this.f_deskel       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.filter.kec }))

            this.l_prov         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.l_kab          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.form.m_provinsi }))
            this.l_kec          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.form.m_kabupaten }))
            this.l_deskel       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.form.m_kecamatan }))

            this.l_prov1         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.l_kab1          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.form.provinsi }))
            this.l_kec1          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.form.kabupaten }))
            this.l_deskel1       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.form.kecamatan }))

            this.l_prov2         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.l_kab2          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.form.provinsi_tujuan }))
            this.l_kec2          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.form.kabupaten_tujuan }))
            this.l_deskel2       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.form.kecamatan_tujuan }))
 
            

            this.$store.state.l_pekerjaan       = await FETCHING.fetch_getx(this.$store.state.url.URL_MASTER_PEKERJAAN + 'list')
            this.$store.state.l_agama           = await FETCHING.fetch_getx(this.$store.state.url.URL_MASTER_AGAMA + 'list1')
            this.$store.state.l_pendidikan      = await FETCHING.fetch_getx(this.$store.state.url.URL_MASTER_PENDIDIKAN + 'list')
            this.$store.state.l_shdk            = await FETCHING.fetch_getx(this.$store.state.url.URL_MASTER_SHDK + 'list')
            
            this.$store.commit('loadingStop') 
        },
        
        async loadMasterAk(){
            this.$store.commit('loadingStart')
            this.list_pekerjaan        = await FETCHING.fetch_getx(this.$store.state.url.URL_MASTER_PEKERJAAN + 'list')
            this.list_agama           = await FETCHING.fetch_getx(this.$store.state.url.URL_MASTER_AGAMA + 'list1')
            this.list_pendidikan       = await FETCHING.fetch_getx(this.$store.state.url.URL_MASTER_PENDIDIKAN + 'list')
            this.list_shdk            = await FETCHING.fetch_getx(this.$store.state.url.URL_MASTER_SHDK + 'list')
            this.$store.commit('loadingStop') 
        },
  
        selectData(data){ 
           console.log(data);
          //  m_desa_kelurahan

            this.form.id                          = data.id
            this.form.KKref                       = data.KKref
            this.form.id_dari_form                = data.id_dari_form
            this.form.modul                       = data.modul
            this.form.m_provinsi                  = data.m_provinsi
            this.form.m_kabupaten                 = data.m_kabupaten
            this.form.m_kecamatan                 = data.m_kecamatan
            this.form.m_desa_kelurahan            = data.m_desa_kelurahan

            this.form.no_kk                              = data.no_kk
            this.form.nama_kepalaKeluarga                = data.nama_kepalaKeluarga
            this.form.alamat                             = data.alamat
            this.form.rt                                 = data.rt
            this.form.rw                                 = data.rw
            this.form.provinsi                           = data.provinsi
            this.form.kabupaten                          = data.kabupaten
            this.form.kecamatan                          = data.kecamatan  
            this.form.desa_kelurahan                     = data.desa_kelurahan
            this.form.kode_pos                           = data.kode_pos
            this.form.no_telp                            = data.no_telp 
            this.form.nik                                = data.nik
            this.form.nama                               = data.nama
            this.form.alasan_kepindahan                  = data.alasan_kepindahan
            this.form.alasan_kepindahan_lainnya          = data.alasan_kepindahan_lainnya
            this.form.alamat_tujuan                      = data.alamat_tujuan,
            this.form.rt_tujuan                          = data.rt_tujuan,
            this.form.rw_tujuan                          = data.rw_tujuan,
            this.form.provinsi_tujuan                    = data.provinsi_tujuan
            this.form.kabupaten_tujuan                   = data.kabupaten_tujuan
            this.form.kecamatan_tujuan                   = data.kecamatan_tujuan  
            this.form.desa_kelurahan_tujuan              = data.desa_kelurahan_tujuan
            this.form.kodePos_tujuan                     = data.kodePos_tujuan
            this.form.noTelp_tujuan                      = data.noTelp_tujuan
            this.form.jenis_kepindahan                   = data.jenis_kepindahan
            this.form.status_kk_bagi_yang_tidak_pindah   = data.status_kk_bagi_yang_tidak_pindah
            this.form.status_kk_bagi_yang_pindah         = data.status_kk_bagi_yang_pindah
            this.form.shdk                               = data.shdk
            this.form.shdk_lainnya                       = data.shdk_lainnya 

            this.form.status                         = data.status
            this.form.keterangan                     = data.keterangan
            this.form.keterangan_deskel              = data.keterangan_deskel
            this.form.keterangan_kecamatan           = data.keterangan_kecamatan
            this.form.keterangan_kabupaten           = data.keterangan_kabupaten
            this.form.status_deskel                  = data.status_deskel
            this.form.status_kecamatan               = data.status_kecamatan
            this.form.status_kabupaten               = data.status_kabupaten
            this.form.createdBy                      = data.createdBy
            this.form.createdAt                      = data.createdAt
            this.form.createdAt_deskel               = data.createdAt_deskel
            this.form.createdAt_kecamatan            = data.createdAt_kecamatan
            this.form.createdAt_kabupaten            = data.createdAt_kabupaten
            this.form.file_terbitan                  = data.file_terbitan
            this.form.emailPemohon                   = data.emailPemohon
            this.form.email_file                     = data.email_file
            this.form.email_fileOld                  = data.email_fileOld
            this.form.email_from                     = data.email_from
            this.form.email_to                       = data.email_to
            this.form.email_subject                  = data.email_subject
            this.form.email_html                     = data.email_html
            this.form.file                           = data.file
            this.form.fileThumb                      = data.fileThumb
            this.form.file_lampiran                  = data.file_lampiran
            this.form.file_lampiranOld               = data.file_lampiranOld
            

            this.list_anggota_keluarga              = data.databio
            
            this.form_view.uraian_1_provinsi        = data.uraian_1_provinsi
            this.form_view.uraian_1_kabupaten       = data.uraian_1_kabupaten
            this.form_view.uraian_1_kecamatan       = data.uraian_1_kecamatan
            this.form_view.uraian_1_desa            = data.uraian_1_desa
            
            this.form_view.uraian_provinsi        = data.uraian_provinsi
            this.form_view.uraian_kabupaten       = data.uraian_kabupaten
            this.form_view.uraian_kecamatan       = data.uraian_kecamatan
            this.form_view.uraian_desa            = data.uraian_desa
            
            this.form_view.uraian_provinsi2        = data.uraian_provinsi2
            this.form_view.uraian_kabupaten2       = data.uraian_kabupaten2
            this.form_view.uraian_kecamatan2       = data.uraian_kecamatan2
            this.form_view.uraian_desa2            = data.uraian_desa2
  
            
  
            this.loadListData()
        },

        checkStatus(data){
            if (data == 2) {
                // return 'bg-deep-orange-4'
            } else if (data == 1) {
                // return 'bg-teal-2'
            }else{

            }
        },

        checkKelasUser(data){
            var prof = JSON.parse(localStorage.profile) 
            var kelas = prof.profile.kelas

            if (kelas == data) {
                return true
            } 
            else{
                return false

            }
        },

        cek_btn_editDel(data){  
            var a = data.status 
            console.log();
            // var status_kab = data.status_kabupaten  

            var prof = JSON.parse(localStorage.profile) 
            var kelas = prof.profile.kelas

            if (kelas == 'vertikal' || kelas == 'pemohon') {
                if (a == 1) {
                    return true
                }else{
                    return false
                }
            }
        },

        bukaTab(linknya){
            window.open(linknya);
        },

        lihatFilex(filename){
            // console.log(filename);
            var ext = filename.split('.').pop().toLowerCase(); 
            if (ext == "pdf") {
                this.$store.state.pdfx = true
                this.$store.state.filex = filename

                // return true
            } else {
                this.$store.state.gambarx = false
                this.$store.state.filex = filename 
            } 

        },
        
        isPdfOrImage(filename) {
        // Mendapatkan ekstensi file
            var ext = filename.split('.').pop().toLowerCase();

            // Array ekstensi gambar yang diizinkan
            var imageExts = ['jpg', 'jpeg', 'png', 'gif'];

            // Memeriksa apakah ekstensi adalah PDF atau gambar
            if (ext === 'pdf' ) {
                console.log(ext);
                return true;
            }else{
                console.log(ext);
                return false
            }
        }

        
  
  
    },
    mounted() {
        this.getView()
        this.loadListData()
        this.checkKelasUser()
    },
    }
  
  </script>
  
  
   