import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';import LoadingBar from 'quasar/src/plugins/LoadingBar.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';
import quasarUserOptions from './quasar-user-options'

// import 

const Vue = createApp(App)

import sidebars from './components/sidebars.vue'
Vue.component('sidebars', sidebars)

import MDL_ADD from './components/modal_add.vue'
Vue.component('MDL_ADD', MDL_ADD)
import MDL_DEL from './components/modal_del.vue'
Vue.component('MDL_DEL', MDL_DEL)
import MDL_EDT from './components/modal_edt.vue'
Vue.component('MDL_EDT', MDL_EDT)

import MDL_FILEX from './components/modal_lihat_file.vue'
Vue.component('MDL_FILEX', MDL_FILEX)

import MDL_ADD_K from './components/modal_add_keluarga.vue'
Vue.component('MDL_ADD_K', MDL_ADD_K)

import PDFViewer from 'pdf-viewer-vue'
Vue.component('PDFViewer', PDFViewer)

// Vue.component('')
.use(Quasar, {
    plugins: { 
        LoadingBar, Loading, Notify
    }, 
    },
    quasarUserOptions)
.use(store)
.use(router).mount('#app')
