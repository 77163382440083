<template>
    <!-- <div class="bg-abu-300 shadow-3" style="background-image: linear-gradient(120deg, #f5f0f0, #abdfd5);padding:0px;margin: 10px;border-radius: 8px;"> -->
    <div class="bg-abu-300 shadow-10 bg-gradku" style="padding:0px;margin: 10px;border-radius: 8px;">
      <div class="row">
        
        <div class="col-12"> 
          <div class="q-pa-md q-gutter-sm">
            <q-banner inline-actions rounded class="bg-brand abu-100 shadow-3">
              Menu
              <template v-slot:action>  
                <q-btn size="md" @click="$store.state.mdl_add = true" class="bg-ijo-600" color="" icon="add" />
              </template>
            </q-banner>
          </div>      
        </div>   
        
  
  
        <!-- ============================== FILTER ============================== -->

        
  
    
        <div class="col-6"> 
          <div class="q-px-md q-pt-md"> 
 
          </div>      
        </div>

        <div class="col-6 col-md-6 col-xs-12 col-sm-12"> 
          <div class="q-px-md q-pt-md text-center"> 
                <q-input v-model="filter.search" class="bg-white" color="primary" label-color="gray-16" outlined :dense="true" type="search" label="Pencarian">
                    <template v-slot:append> <q-icon name="search" /> </template>
                </q-input> 
              </div>  
        </div>
    
     
    
        <div class="col-12"> 
            <div class="q-pa-md">
              <!-- <pre style="font-size: 9px;">{{list_data}}</pre> -->
              <q-markup-table style="width: 100%;" separator="cell" class="bg-abu-200">
              <thead class="bg-brand">
                <tr>
                  <th class="text-center  abu-100" style="width: 5%;">No</th>
                  <th class="text-center  abu-100" style="width: 5%;">Urutan</th> 
                  <th class="text-left  abu-100" style="width: 60%;">Menu / Route</th>
                  <th class="text-center  abu-100" style="width: 20%;">Icon</th> 
                  <th class="text-center  abu-100" style="width: 10%;">Act</th>
                </tr>
              </thead>
    
              <tbody>
                <template v-for="(item1, index) in list_data" :key="item1._id" >
             
                    <tr>
                      <td class="text-center ijo-800"> {{index+1}}.</td>
                      <td class="text-center ijo-800"> <b>{{item1.urutan}}</b></td>
                      <td class="text-left ">
                        <div>
                          <div class="ijo-900" style="text-transform: uppercase;"> <b> {{item1.nama}}  </b></div>
                          <div v-if="item1.type == 'single'" class="ijo-800"  style="font-size: 9pt;">Route:  {{ item1.router }}</div>
                        </div>
                      </td>
                      <td class="text-center ijo-900">
                          <q-icon class="text-black" style="font-size: 14pt;" :name="item1.icon" color="abu-300" text-color="teal-7"/> 
                      </td> 
                      <td class="text-right">
                        <div class="q-gutter-xs"> 
                          <q-btn v-if="item1.type == 'double'" round color="blue-5" icon="add" size="sm" @click="mdl_add_sub=true, selectData(item1)">
                            <q-tooltip class="bg-indigo" :offset="[0, -60]"> Add Sub </q-tooltip>
                          </q-btn>
                          <q-btn round color="orange-5" icon="edit" size="sm" @click="$store.state.mdl_edit = true, selectData(item1)" >
                            <q-tooltip class="bg-indigo" :offset="[0, -60]"> Edit </q-tooltip>
                          </q-btn>
                          <q-btn round color="red-5" icon="delete" size="sm" @click="$store.state.mdl_del = true, selectData(item1)">
                            <q-tooltip class="bg-indigo" :offset="[0, -60]"> Hapus </q-tooltip>
                          </q-btn>
                        </div>
                      </td>
                    </tr>
                    
                    <!-- =================== sub menu ===================  -->
                    <template v-for="(item2, index) in item1.submenu" :key="item2._id" v-if="item1.type == 'double'">
                      <tr >
                        <td class="text-center ijo-800">  </td>
                        <td class="text-center ijo-800"> {{item2.urutan}}.</td>
                        <td class="text-left ">
                          <div>
                            <div class="ijo-900" style="text-transform: uppercase;"> <b> {{item2.nama}}  </b></div>
                            <div class="ijo-800" style="font-size: 9pt;">Route:  {{item2.router}} </div>
                          </div>
                        </td>
                        <td class="text-center ijo-900">
                            <q-icon class="text-black" style="font-size: 14pt;" name="radio_button_unchecked" color="abu-300" text-color="teal-7"/> 
                        </td> 
                        <td class="text-right">
                          <div class="q-gutter-xs">  
                            <q-btn round color="orange-5" icon="edit" size="sm" @click="mdl_edit_sub = true, selectSubData(item2) ">
                              <q-tooltip class="bg-indigo" :offset="[0, -60]"> Edit </q-tooltip>
                            </q-btn>
                            <q-btn round color="red-5" icon="delete" size="sm" @click="$store.state.mdl_del = true, selectData(item2)">
                              <q-tooltip class="bg-indigo" :offset="[0, -60]"> Hapus </q-tooltip>
                            </q-btn>
                          </div>
                        </td>
                      </tr> 
                    </template>
                    <!-- =================== sub menu ===================  -->
               
                </template>
    
    
    
              </tbody>
              </q-markup-table>
            </div> 
        </div> 
     
  
  
  
  
  
      <!-- ============================== FILTER ============================== -->


        <!-- ================================= MODAL =================================  -->

  <MDL_ADD :modal="$store.state.mdl_add" :metode="addData"> 
    <template  v-slot:bodys>
      <div class="row q-gutter-sm">

        <!-- ================ TUJUAN PERMOHONAN ================ -->
        <!-- <div class="col-12"><span class="abu-800">TUJUAN PERMOHONAN DI KIRIM</span></div> -->

        <div class="col-12"> 
            <div class="abu-900">Type Menu  </div>
            <select class="f_select" v-model="form.type" style="width: 100%;">
              <option value="single">Single</option>
              <option value="double">Double</option> 
            </select> 
        </div>  
        <div class="col-12" v-if="form.type == 'single'"> 
            <div class="abu-900">Router Path </div>
            <input v-model="form.router" class="f_select" type="text" >
        </div>  
        <div class="col-12"> 
            <div class="abu-900">Urutan  </div>
            <input v-model="form.urutan" class="f_select" type="text" >
        </div>  
        <div class="col-12"> 
            <div class="abu-900">Nama Menu </div>
            <input v-model="form.nama" class="f_select" type="text" >
        </div>  
        <div class="col-12"> 
            <div class="abu-900">Icon Color  </div>
            <input v-model="form.icon_color" class="f_select" type="text" >
        </div>  
        <div class="col-12"> 
            <div class="abu-900">Warna Huruf  </div>
            <input v-model="form.text_color" class="f_select" type="text" >
        </div>   
        <div class="col-12"> 
            <div class="abu-900">Icon  </div>
            <input v-model="form.icon" class="f_select" type="text" >
        </div>  
        <div class="col-12"> 
            <div class="abu-900">Color  </div>
            <input v-model="form.color" class="f_select" type="text" >
        </div>  
        
        
        




        <!-- ================ TUJUAN PERMOHONAN ================ -->

      </div>  
    </template> 
  </MDL_ADD>

  <MDL_EDT :modal="$store.state.mdl_edit" :metode="editData"> 
    <template  v-slot:bodys>
      <div class="row q-gutter-sm"> 
        <div class="col-12"> 
            <div class="abu-900">Type Menu  </div>
            <select class="f_select" v-model="form.type" style="width: 100%;">
              <option value="single">Single</option>
              <option value="double">Double</option> 
            </select> 
        </div>  
        <div class="col-12" v-if="form.type == 'single'"> 
            <div class="abu-900">Router Path </div>
            <input v-model="form.router" class="f_select" type="text" >
        </div>  
        <div class="col-12"> 
            <div class="abu-900">Urutan  </div>
            <input v-model="form.urutan" class="f_select" type="text" >
        </div>  
        <div class="col-12"> 
            <div class="abu-900">Nama Menu </div>
            <input v-model="form.nama" class="f_select" type="text" >
        </div>  
        <div class="col-12"> 
            <div class="abu-900">Icon Color  </div>
            <input v-model="form.icon_color" class="f_select" type="text" >
        </div>  
        <div class="col-12"> 
            <div class="abu-900">Warna Huruf  </div>
            <input v-model="form.text_color" class="f_select" type="text" >
        </div>   
        <div class="col-12"> 
            <div class="abu-900">Icon  </div>
            <input v-model="form.icon" class="f_select" type="text" >
        </div>  
        <div class="col-12"> 
            <div class="abu-900">Color  </div>
            <input v-model="form.color" class="f_select" type="text" >
        </div>  
        

      </div>  
    </template> 
  </MDL_EDT>
  
  <MDL_DEL :modal="$store.state.mdl_del" :metode="removeData"/>


  <q-dialog v-model="mdl_add_sub" persistent>
      <q-card style="width: 700px; max-width: 80vw;"> 
          <div class="fit row wrap justify-center items-center content-start">
              <div class="col-11">
                  <div class="q-pa-md text-center bg-primary">
                      <span class="q-ma-md text-white">TAMBAH SUBMENU</span>
                  </div>
              </div>
              <div class="col-1">
                  <div clickable class="q-pa-md text-center bg-primary">
                      <a @click="mdl_add_sub=false" href="javascript:void(0)" style="text-decoration: none;">
                          <span class="q-ma-md text-white"><b>x</b></span>
                      </a>
                  </div>
              </div>

          </div>
          <q-separator />
          <form v-on:submit.prevent="addSubData()"> 
              <q-card-section>
                <div class="row q-gutter-sm">
          
                  <div class="col-12"> 
                      <div class="abu-900">Router Path </div>
                      <input v-model="subform.router" class="f_select" type="text" >
                  </div>  

                  <div class="col-12"> 
                      <div class="abu-900">Urutan  </div>
                      <input v-model="subform.urutan" class="f_select" type="text" >
                  </div>  

                  <div class="col-12"> 
                      <div class="abu-900">Nama Menu </div>
                      <input v-model="subform.nama" class="f_select" type="text" >
                  </div>  
                  

                </div>  
              </q-card-section>
              <q-separator />
  
              <q-card-actions align="center">
                  <q-btn size="sm" label="Tambah" color="primary" type="submit" />
                  <q-btn size="sm" label="Kembali" color="negative" @click="mdl_add_sub = false"   />
              </q-card-actions>
              
          </form>
      </q-card>
  </q-dialog>    

  <q-dialog v-model="mdl_edit_sub" persistent>
      <q-card style="width: 700px; max-width: 80vw;"> 
          <div class="fit row wrap justify-center items-center content-start">
              <div class="col-11">
                  <div class="q-pa-md text-center bg-oranye-500">
                      <span class="q-ma-md text-white">UBAH SUBMENU</span>
                  </div>
              </div>
              <div class="col-1">
                  <div clickable class="q-pa-md text-center bg-oranye-500">
                      <a @click="mdl_edit_sub=false" href="javascript:void(0)" style="text-decoration: none;">
                          <span class="q-ma-md text-white"><b>x</b></span>
                      </a>
                  </div>
              </div>

          </div>
          <q-separator />
          <form v-on:submit.prevent="editSubData()"> 
              <q-card-section>
                <div class="row q-gutter-sm">
          
                  <div class="col-12"> 
                      <div class="abu-900">Router Path </div>
                      <input v-model="subform.router" class="f_select" type="text" >
                  </div>  

                  <div class="col-12"> 
                      <div class="abu-900">Urutan  </div>
                      <input v-model="subform.urutan" class="f_select" type="text" >
                  </div>  

                  <div class="col-12"> 
                      <div class="abu-900">Nama Menu </div>
                      <input v-model="subform.nama" class="f_select" type="text" >
                  </div>  
                  

                </div>  
              </q-card-section>
              <q-separator />
  
              <q-card-actions align="center">
                  <q-btn size="sm" label="Ubah" color="oranye-500" type="submit" style="width: 60px;" />
                  <q-btn size="sm" label="Kembali" color="negative" @click="mdl_edit_sub = false"  style="width: 60px;"  />
              </q-card-actions>
              
          </form>
      </q-card>
  </q-dialog>    
 


<!-- ================================= MODAL =================================  -->
    </div> 
  
  
 
  
  </div>
   
  
  </template>

  <script> 
import FETCHING from "../../library/fetching";
import UMUM from "../../library/umum";
  export default {
    data() {
        return {
            FETCHING:FETCHING,
            UMUM:UMUM,

            mdl_add_sub:false,
            mdl_edit_sub:false,

            filter:{
                search:'',
            },

            form : {
                _id : '',
                group : '',
                router : '/',
                nama : '',
                type : 'single',
                icon_color : 'white',
                text_color : '#3a3a3a',
                icon : 'home',
                color : 'blue-6',
                kategori : 'menu',
                urutan : 1,
                path : false
            },

            subform : {
                menu : '',
                router : '/',
                nama : '',
                type : 'single',
                kategori : 'submenu',
                path : false,
                urutan : 1,
            },

            subsubform : {
                menu : '',
                router : '/',
                nama : '',
                type : 'single',
                kategori : 'subsubmenu',
                path : false
            },
            testx:'',
            list_data:[],

        }
    },
    methods: {
      test(){ 

      },

      async getView(){  
        this.$store.commit('loadingStart')
        var a = await FETCHING.fetch_view(this.$store.state.url.URL_MENU_LIST+'view')
        this.list_data = a
        this.$store.commit('loadingStop')
      },

      async addData(){  
        this.$store.commit('loadingStart')
        var a = await FETCHING.fetch_postx(this.$store.state.url.URL_MENU_LIST+'addData', JSON.stringify(this.form))         
        this.$store.state.mdl_add = false
        await UMUM.notifikasi(a)
        this.$store.commit('loadingStop') 
        this.getView();
      }, 
      async addSubData(){  
        this.$store.commit('loadingStart')
        var a = await FETCHING.fetch_postx(this.$store.state.url.URL_MENU_LIST+'addData', JSON.stringify(this.subform))         
        this.$store.state.mdl_add = false
        await UMUM.notifikasi(a)
        this.$store.commit('loadingStop') 
        this.getView();
      }, 

      async editData(){  
        this.$store.commit('loadingStart')
        var a = await FETCHING.fetch_postx(this.$store.state.url.URL_MENU_LIST+'editData', JSON.stringify(this.form))         
        this.$store.state.mdl_edit = false
        await UMUM.notifikasi(a)
        this.$store.commit('loadingStop') 
        this.getView();
      }, 
      async editSubData(){  
        this.$store.commit('loadingStart')
        var a = await FETCHING.fetch_postx(this.$store.state.url.URL_MENU_LIST+'editData', JSON.stringify(this.subform))         
        this.$store.state.mdl_edit = false
        await UMUM.notifikasi(a)
        this.$store.commit('loadingStop') 
        this.getView();
      }, 

      async removeData(){  
        this.$store.commit('loadingStart')
        var a = await FETCHING.fetch_postx(this.$store.state.url.URL_MENU_LIST+'removeData', JSON.stringify({
          _id:this.form._id
        })) 
        this.$store.commit('loadingStop')
        await UMUM.notifikasi(a)
        this.$store.state.mdl_del = false
        this.getView();  
      }, 

      selectData(data){
        this.form._id           = data._id

        this.form.group         = data.group
        this.form.router        = data.router
        this.form.nama          = data.nama
        this.form.type          = data.type
        this.form.icon_color    = data.icon_color
        this.form.text_color    = data.text_color
        this.form.icon          = data.icon
        this.form.color         = data.color
        this.form.kategori      = data.kategori
        this.form.urutan        = data.urutan
        this.form.path          = data.path        

        this.subform.menu = data._id;
        // console.log(data);
      },
      selectSubData : function(data){
        this.subform = data;
        this.subsubform.menu = data._id;
      },
    },
    mounted() {
      this.getView()
    },
  }
  </script>