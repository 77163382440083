import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

import menu from '../views/setting/menu.vue'
import level_auth from '../views/setting/level_auth.vue'
import registrasi from '../views/setting/registrasi.vue'

import login from '../views/auth/login.vue'
import register from '../views/auth/register.vue'

import m_agama      from '../views/m_data/m_agama.vue'
import m_pekerjaan  from '../views/m_data/m_pekerjaan.vue'
import m_pendidikan from '../views/m_data/m_pendidikan.vue'
import m_shdk       from '../views/m_data/m_shdk.vue'

import m_prov       from '../views/m_wilayah/m_prov.vue'
import m_kab       from '../views/m_wilayah/m_kab.vue'
import m_kec       from '../views/m_wilayah/m_kec.vue'
import m_deskel       from '../views/m_wilayah/m_deskel.vue'


import ktp       from '../views/page/1_ktp.vue'

import kk1       from '../views/page/2_kk1.vue'
import kk2       from '../views/page/3_kk2.vue'
import kk3       from '../views/page/4_kk3.vue'

import ppd1       from '../views/page/5_ppd1.vue'
import ppd2       from '../views/page/6_ppd2.vue'
import ppd3       from '../views/page/7_ppd3.vue'

import lahir1       from '../views/page/8_lahir1.vue'
import lahir2       from '../views/page/9_lahir2.vue'

import mati1       from '../views/page/10_mati1.vue'
import mati2       from '../views/page/11_mati2.vue'

function loggedInRedirectDashboard(to, from, next) {
  if (localStorage.token) {
    next('/');
  } else {
    next();
  }
}

function isLoggedIn(to, from, next) {
  if (localStorage.token) {
    console.log('Saya masuk')
    next();
  } else {
    next('/login');
  }
}


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/menu',
    name: 'menu',
    component: menu,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/level_auth',
    name: 'level_auth',
    component: level_auth,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/registrasi',
    name: 'registrasi',
    component: registrasi,
    beforeEnter: isLoggedIn,
  },

  // ==========================
  {
    path: '/m_agama',
    name: 'm_agama',
    component: m_agama,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/m_pekerjaan',
    name: 'm_pekerjaan',
    component: m_pekerjaan,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/m_pendidikan',
    name: 'm_pendidikan',
    component: m_pendidikan,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/m_shdk',
    name: 'm_shdk',
    component: m_shdk,
    beforeEnter: isLoggedIn,
  },
  // ==========================
  {
    path: '/m_prov',
    name: 'm_prov',
    component: m_prov,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/m_kab',
    name: 'm_kab',
    component: m_kab,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/m_kec',
    name: 'm_kec',
    component: m_kec,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/m_deskel',
    name: 'm_deskel',
    component: m_deskel,
    beforeEnter: isLoggedIn,
  },
  
  // ==========================
  {
    path: '/ktp',
    name: 'ktp',
    component: ktp,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/kk1',
    name: 'kk1',
    component: kk1,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/kk2',
    name: 'kk2',
    component: kk2,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/kk3',
    name: 'kk3',
    component: kk3,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/ppd1',
    name: 'ppd1',
    component: ppd1,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/ppd2',
    name: 'ppd2',
    component: ppd2,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/ppd3',
    name: 'ppd3',
    component: ppd3,
    beforeEnter: isLoggedIn,
  },

  {
    path: '/lahir1',
    name: 'lahir1',
    component: lahir1,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/lahir2',
    name: 'lahir2',
    component: lahir2,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/mati1',
    name: 'mati1',
    component: mati1,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/mati2',
    name: 'mati2',
    component: mati2,
    beforeEnter: isLoggedIn,
  },
  // ==========================
  


  {
    path: '/login',
    name: 'login',
    component: login,
    beforeEnter: loggedInRedirectDashboard,
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  }
]




const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
