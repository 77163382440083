<template>
    <q-layout view="lHh Lpr lFf">

    <q-header v-show="$store.state.hideBar" elevated class="bg-brand">
      <q-toolbar>
        <q-btn
          flat dense
          @click="$store.state.sidebarx = !$store.state.sidebarx"
          aria-label="Menu" icon="menu"
        />

        <q-toolbar-title>
          SIMPONI
        </q-toolbar-title>

        <div>Quasar v{{ $q.version }}</div>
      </q-toolbar>
    </q-header>


    <sidebars v-show="$store.state.hideBar" />



    <q-page-container>
      <RouterView/>
    </q-page-container>

  </q-layout>
</template>

<script>
import { ref } from 'vue'
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'LayoutDefault',

  components: {
    HelloWorld
  },

  // setup () {
  //   return {
  //     leftDrawerOpen: ref(false)
  //   }
  // },
  data() {
    return {
      leftDrawerOpen:false
    }
  },
  computed:{ 
    // checkLogin() {
    //     console.log(this.$route.name);
    //     // console.log(this.$route);
    //     // if (this.$route.name === 'login' || this.$route.name === 'register') {
    //     //     return true;
    //     // } else {
    //     //     return false;
    //     // }
    // },
  },
  methods: {
    checkLogin() {
        // console.log(this.$route.name);
        // console.log(this.$route);
        // if (this.$route.name === 'login' || this.$route.name === 'register') {
        //     return true;
        // } else {
        //     return false;
        // }
    },
  },
  mounted() {
    // this.checkLogin()
  },
}
</script>
