<template>
    <!-- <div class="bg-abu-300 shadow-3" style="background-image: linear-gradient(120deg, #f5f0f0, #abdfd5);padding:0px;margin: 10px;border-radius: 8px;"> -->
    <div class="bg-abu-300 shadow-10 bg-gradku" style="padding:0px;margin: 10px;border-radius: 8px;">
      <div class="row">
        
        <div class="col-12"> 
          <div class="q-pa-md q-gutter-sm">
            <q-banner inline-actions rounded class="bg-brand abu-100 shadow-3">
                Surat Keterangan Kelahiran
              <template v-slot:action>  
                <q-btn size="md" @click="$store.state.mdl_add = true" class="bg-ijo-600" color="" icon="add" v-show="checkKelasUser('pemohon') || checkKelasUser('vertikal') || checkKelasUser('administrator')" />
              </template>
            </q-banner>
          </div>      
        </div>   
        
  
  
        <!-- ============================== FILTER ============================== -->
        <div class="col-md-3 col-sm-12 col-xs-12 q-px-md q-pt-md" > 
            <span class="abu-800">Provinsi {{ filter.prov }}</span>
            <select class="f_select" v-model="filter.prov" style="width: 100%;" @change="loadListData(), getView()">
                <option value="" :disabled="true">           Pilih Provinsi              </option>
                <option v-for="(item, index) in f_prov" :key="item.m_provinsi" :value="item.m_provinsi" :disabled="true">  {{item.uraian}}         </option> 
            </select>
        </div>

        <div class="col-md-3 col-sm-12 col-xs-12 q-px-md q-pt-md" > 
            <span class="abu-800">Kabupaten {{ filter.kab }}</span>
            <select class="f_select" v-model="filter.kab" style="width: 100%;" @change="loadListData(), getView()">
                <option value="" :disabled="true">           Pilih Kabupaten / Kota             </option> 
                <option v-for="(item, index) in f_kab" :key="item.m_kabupaten" :value="item.m_kabupaten" :disabled="true">  {{item.uraian}}         </option> 
            </select>
        </div>

        <div class="col-md-3 col-sm-12 col-xs-12 q-px-md q-pt-md" > 
            <span class="abu-800">Kecamatan</span>
            <select class="f_select" v-model="filter.kec" style="width: 100%;" @change="loadListData(), getView()">
                <option value="">           Pilih Kecamatan              </option>
                <option v-for="(item, index) in f_kec" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
            </select>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12 q-px-md q-pt-md" > 
            <span class="abu-800">Desa / Kelurahan</span>
            <select class="f_select" v-model="filter.deskel" style="width: 100%;" @change="loadListData(), getView()">
                <option value="">           Pilih Desa / Kelurahan              </option>
                <option v-for="(item, index) in f_deskel" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
            </select>
        </div>
        <!-- ============================== FILTER ============================== -->  
        <div class="col-6"> 
            <div class="q-px-md q-pt-md"> 
                <q-input v-model="filter.cari_value" @keyup="getView()" class="bg-white" color="primary" label-color="gray-16" outlined :dense="true" type="search" label="Pencarian">
                    <template v-slot:append> <q-icon name="search" /> </template>
                </q-input> 
            </div>      
        </div>
        <div class="col-6"> 
            <div class="q-px-md q-pt-md text-center"> 
                    <select class="f_select" v-model="filter.status" style="width: 100%;" @change="getView()">
                        <option value="0" :disabled="false">Menunggu</option>
                        <option value="1" :disabled="false">Proses</option>
                        <option value="2" :disabled="false">Dikembalikan</option> 
                    </select>
            </div>  
        </div>
      <!-- ============================== FILTER ============================== -->
     
      <div class="col-12"> 
          <div class="q-pa-md">
            <q-markup-table style="width: 100%;" class="bg-abu-200">
            <thead class="bg-brand">
              <tr>
                <th class="text-center  abu-100" style="width: 10%;">No</th>
                <th class="text-left  abu-100" style="width: 50%;">Nama Bayi</th>
                <th class="text-center  abu-100" style="width: 10%;">Tanggal</th>
                <th class="text-center  abu-100" style="width: 20%;">Status</th>
                <th class="text-center  abu-100" style="width: 10%;">Act</th>
              </tr>
            </thead>
  
            <tbody>
            <tr v-for="(data, index) in list_data" >
              <td class="text-center ijo-800">{{index+1}}.</td>
              <td class="text-left ">
                <div>
                  <!-- <div class="ijo-800" style="font-size: 9pt;">NO KK : {{data.no_kk}}</div> -->
                  <div class="ijo-900" style="text-transform: uppercase;"> <b>{{data.nama_bayi}}  </b></div>
                </div>
              </td>
              <td class="text-right ijo-900">{{UMUM.tglConvert(data.createdAt).tgl}}</td>
              <td class="text-center">
                <div class="q-gutter-xs">
                    <!-- pemohon  -->
                    <q-btn round color="oranye-500" icon="pending" size="sm" v-if="data.status === 0" v-show="checkKelasUser('pemohon') || checkKelasUser('vertikal') || checkKelasUser('administrator')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Menunggu di proses </q-tooltip>
                    </q-btn>
  
                    <q-btn round color="ijo-600" icon="sms" size="sm" v-if="data.status === 1" v-show="checkKelasUser('pemohon') || checkKelasUser('vertikal') || checkKelasUser('administrator')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Telah di verifikasi </q-tooltip>
                    </q-btn>
                    
                    <q-btn round color="merah-600" icon="sms" size="sm" v-if="data.status === 2" v-show="checkKelasUser('pemohon') || checkKelasUser('vertikal') || checkKelasUser('administrator')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]" > Dikembalikan </q-tooltip>
                    </q-btn>
                    <!-- pemohon  -->
                    
                    <!-- verifikator  -->
                    <q-btn round color="oranye-500" icon="pending" size="sm" v-if="data.status_kecamatan === 0"  v-show="checkKelasUser('verifikator')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Menunggu di proses </q-tooltip>
                    </q-btn>
  
                    <q-btn round color="ijo-600" icon="sms" size="sm" v-if="data.status_kecamatan === 1"  v-show="checkKelasUser('verifikator')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Telah di verifikasi </q-tooltip>
                    </q-btn>
                    
                    <q-btn round color="merah-600" icon="sms" size="sm" v-if="data.status_kecamatan === 2"  v-show="checkKelasUser('verifikator')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]" > Dikembalikan </q-tooltip>
                    </q-btn>
                    
                    <!-- verifikator  -->
                    
                    <!-- kabupaten  -->
                    <q-btn round color="oranye-500" icon="pending" size="sm" v-if="data.status_kabupaten === 0"  v-show="checkKelasUser('kabupaten')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Menunggu di proses </q-tooltip>
                    </q-btn>
  
                    <q-btn round color="ijo-600" icon="sms" size="sm" v-if="data.status_kabupaten === 1"  v-show="checkKelasUser('kabupaten')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]"> Telah di verifikasi </q-tooltip>
                    </q-btn>
                    
                    <q-btn round color="merah-600" icon="sms" size="sm" v-if="data.status_kabupaten === 2"  v-show="checkKelasUser('kabupaten')">
                      <q-tooltip class="bg-indigo" :offset="[0, -60]" > Dikembalikan </q-tooltip>
                    </q-btn>
                    <!-- kabupaten  -->
                    <!-- File Lampiran Email / Hasil  -->
                     
                    <!-- <q-btn round size="sm" v-if="data.email_file == '' || data.email_file == 'null' || data.email_file == null || data.email_file == undefined " @click="bukaTab($store.state.url.URL_APP+'uploads/'+data.email_file)" title="File Lampiran Dari Kabupaten" icon="attach_file" color="blue-8" class="text-white" />  -->
                    <span v-if="data.email_file == '' || data.email_file == 'null' || data.email_file == null || data.email_file == undefined "></span>
                    <q-btn round size="sm" v-else @click="bukaTab($store.state.url.URL_APP+'uploads/'+data.email_file)" title="File Lampiran Dari Kabupaten" icon="attach_file" color="blue-8" class="text-white" /> 

                    <!-- File Lampiran Email / Hasil  -->

                  
                </div>
              </td>
              <td class="text-center">
                <div class="q-gutter-xs">
                  <q-btn round color="teal-5" icon="timeline" size="sm" @click="$store.state.mdl_history = true, selectData(data)">
                    <q-tooltip class="bg-indigo" :offset="[0, -60]"> SOP History Dokumen </q-tooltip>
                  </q-btn>
                  <q-btn round color="blue-5" icon="visibility" size="sm" @click="$store.state.mdl_view = true, selectData(data)">
                    <q-tooltip class="bg-indigo" :offset="[0, -60]"> Lihat </q-tooltip>
                  </q-btn>
                  <q-btn round color="orange-5" :disable="cek_btn_editDel(data)" icon="edit" size="sm" @click="$store.state.mdl_edit = true, selectData(data)" v-show="checkKelasUser('pemohon') || checkKelasUser('vertikal')">
                    <q-tooltip class="bg-indigo" :offset="[0, -60]"> Edit </q-tooltip>
                  </q-btn>
                  <q-btn round color="red-5"  :disable="cek_btn_editDel(data)" icon="delete" size="sm" @click="$store.state.mdl_del = true, selectData(data)" v-show="checkKelasUser('pemohon') || checkKelasUser('vertikal')">
                    <q-tooltip class="bg-indigo" :offset="[0, -60]"> Hapus </q-tooltip>
                  </q-btn>
                </div>
              </td>
            </tr>
  
  
  
  
            </tbody>
            </q-markup-table>
          </div> 
      </div>
     
    
        <div class="col-12"> 
          <div class="q-px-md q-pt-md">
            <q-separator />
          </div>      
        </div>
    
    
      <div class="col-12">
        <div class="row q-py-md">
          <div class="col-12 col-md-12 col-sm-12 col-xs-12 q-pa-none flex flex-center">
              <q-pagination
                @click="getView()"
                v-model="filter.data_ke"
                :max="filter.page_last"
                :max-pages="4"
                input
                icon-first="skip_previous"
                icon-last="skip_next"
                icon-prev="fast_rewind"
                icon-next="fast_forward"
                color="brand"
              />
          </div>
          
    
        </div>
        
      </div>
  
  
  
  
  
      <!-- ============================== FILTER ============================== -->
      
      
    </div> 
  
  
    <!-- ================================= MODAL =================================  -->
  
    <MDL_ADD :modal="$store.state.mdl_add" :metode="addData">
        <template  v-slot:bodys>
        <div class="row"> 
  
            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                <div class="q-px-sm">
                    <span class="abu-800">Pemerintah Provinsi</span>
                    <select class="f_select" v-model="form.m_provinsi" style="width: 100%;" @change="loadListData()">
                        <option value="" :disabled="true">           Pilih Provinsi              </option>
                        <option v-for="(item, index) in l_prov" :key="item.m_provinsi" :value="item.m_provinsi" :disabled="true">  {{item.uraian}}         </option> 
                    </select>
                </div>
            </div>
  
            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                <div class="q-px-sm">
                    <span class="abu-800">Pemerintah Kabupaten / Kota</span>
                    <select class="f_select" v-model="form.m_kabupaten" style="width: 100%;" @change="loadListData()">
                        <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                        <option v-for="(item, index) in l_kab" :key="item.m_kabupaten" :value="item.m_kabupaten" :disabled="true">  {{item.uraian}}         </option> 
                    </select>
                </div>
            </div>
  
            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                <div class="q-px-sm">
                    <span class="abu-800">Kecamatan</span>
                    <select class="f_select" v-model="form.m_kecamatan" style="width: 100%;" @change="loadListData()" required>
                        <option value="">           Pilih Kecamatan              </option>
                        <option v-for="(item, index) in l_kec" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                    </select>
                </div>
            </div>
            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                <div class="q-px-sm">
                    <span class="abu-800">Desa / Kelurahan {{ form.m_desa_kelurahan }}</span>
                    <select class="f_select" v-model="form.m_desa_kelurahan" style="width: 100%;" required>
                        <option value="">           Pilih Desa / Kelurahan              </option>
                        <option v-for="(item, index) in l_deskel" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                    </select>
                </div>
            </div>
  
  
            <div class="col-12  col-md-12 col-sm-12 col-xs-12">
            <div class="q-px-sm">
                
                <div class="abu-800 text-labels">Nama Kepala Keluarga</div>
                <input class="f_select" v-model="form.nama_kepala_keluarga" type="text" placeholder="Nama Kepala Keluarga" required>

                <div class="abu-800 text-labels">Nomor Kartu Keluarga</div>
                <input class="f_select" v-model="form.no_kk" type="text" placeholder="Nomor Kartu Keluarga" required>

                
                <!-- ===================== BAYI ===================== -->
                <br>


                <div rounded class="bg-cyan-8 q-my-md">
                    <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b>DATA BAYI / ANAK</b></div>
                </div>

                <div class="abu-800 text-labels">Nama Bayi</div>
                <input class="f_select" v-model="form.nama_bayi" type="text" placeholder="Nama Bayi" required>

                <div class="abu-800 text-labels">Jenis Kelamin</div>
                <select class="f_select" v-model="form.jenis_kelamin_bayi" style="width: 100%;" required>
                    <option  value="" disabled>  Pilih Jenis Kelamin         </option>  
                    <option  value="Laki - Laki"> Laki - Laki         </option>  
                    <option  value="Perempuan">  Perempuan         </option>  
                </select>
                <!-- <input class="f_select" v-model="form.jenis_kelamin_bayi" type="text" placeholder="Nama Bayi" required> -->
                
                <div class="abu-800 text-labels">Tempat Dilahirkan</div>
                <select class="f_select" v-model="form.tmpt_lahir_bayi" style="width: 100%;" required>
                    <option  value="" disabled>  Pilih Tempat Dilahirkan         </option>  
                    <option value="RS/RB"> RS/RB</option>
                    <option value="Puskesmas"> Puskesmas</option> 
                    <option value="Polindes"> Polindes</option> 
                    <option value="Rumah"> Rumah</option> 
                    <option value="Lainnya"> Lainnya</option> 
                </select>
                
                <div class="abu-800 text-labels" v-if="form.tmpt_lahir_bayi == 'Lainnya'">Tempat Dilahirkan Lainnya</div>
                <input class="f_select" v-if="form.tmpt_lahir_bayi == 'Lainnya'" v-model="form.tmpt_lahir_bayi_lainnya" type="text" placeholder="Tempat Dilahirkan Lainnya" required>
                
                <div class="abu-800 text-labels">Tempat Kelahiran</div>
                <input class="f_select" v-model="form.tempat_kelahiran_bayi" type="text" placeholder="Tempat Kelahiran" required>
                
                <div class="abu-800 text-labels">Hari Lahir</div> 
                <select class="f_select" v-model="form.nama_hari" style="width: 100%;" required>
                    <option  value="" disabled>  Pilih Hari Lahir         </option>  
                    <option v-for="(namahari, index) in $store.state.nama_hari" :key="index" :value="namahari"> {{namahari}}</option>
                </select>

                <div class="abu-800 text-labels">Tanggal Lahir</div>
                <input class="f_select" v-model="form.tgl_lahir_bayi" type="date" placeholder="Tanggal Lahir" required>

                <div class="abu-800 text-labels">Pukul</div>
                <input class="f_select" v-model="form.pukul" type="time" placeholder="Pukul" required>

                <div class="abu-800 text-labels">Jenis Kelahiran</div>
                <select class="f_select" v-model="form.jenis_lahir"> 
                    <option >Tunggal</option>
                    <option >Kembar 2</option> 
                    <option >Kembar 3</option> 
                    <option >Kembar 4</option> 
                    <option >Lainnya</option> 
                </select>

                <div class="abu-800 text-labels" v-if="form.jenis_lahir == 'Lainnya'" >Jenis Kelahiran Lainnya</div>
                <input class="f_select" v-if="form.jenis_lahir == 'Lainnya'" v-model="form.jenis_lahir_lainnya" type="text" placeholder="Jenis Kelahiran Lainnya" required>

                <div class="abu-800 text-labels">Kelahiran Ke-</div>
                <input class="f_select" v-model="form.kelahiran_ke" type="number" placeholder="Kelahiran Ke-" required>

                <div class="abu-800 text-labels">Penolong Kelahiran</div>
                <select class="f_select" v-model="form.penolong_kelahiran"> 
                    <option >Dokter</option>
                    <option >Bidan/Perawat</option> 
                    <option >Dukun</option>  
                    <option >Lainnya</option> 
                </select>

                <div class="abu-800 text-labels" v-if="form.penolong_kelahiran == 'Lainnya' ">Penolong Kelahiran Lainnya</div>
                <input class="f_select" v-if="form.penolong_kelahiran == 'Lainnya' " v-model="form.penolong_kelahiran_lainnya" type="text" placeholder="Penolong Kelahiran Lainnya" required>

                <div class="abu-800 text-labels">Berat Bayi ({{ form.berat_bayi }}Kg)</div>
                <input class="f_select" v-model="form.berat_bayi" type="number" step="0.1" placeholder="Berat Bayi" required>

                <div class="abu-800 text-labels">Panjang Bayi ({{ form.panjang_bayi }}Cm)</div>
                <input class="f_select" v-model="form.panjang_bayi" type="number" step="0.1" placeholder="Panjang Bayi" required> 

                <!-- ===================== BAYI ===================== -->

                <!-- ===================== IBU ===================== -->
                <!-- <q-separator class="q-my-md" color="orange" inset /> -->
                <div rounded class="bg-cyan-8 q-my-md">
                    <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b>DATA IBU</b></div>
                </div>
                <!-- <q-separator class="q-my-md" color="orange" inset /> -->

                <div class="abu-800 text-labels">NIK Ibu</div>
                <input class="f_select" v-model="form.nik_ibu" type="text" placeholder="NIK Ibu" required>
                <div class="abu-800 text-labels">Nama Ibu</div>
                <input class="f_select" v-model="form.nama_ibu" type="text" placeholder="Nama Ibu" required>
                <div class="abu-800 text-labels">Tgl Lahir Ibu</div>
                <input class="f_select" v-model="form.tgl_lahir_ibu" type="date" placeholder="Tgl Lahir Ibu" required>
                <div class="abu-800 text-labels">Pekerjaan Ibu</div>
                <input class="f_select" v-model="form.pekerjaan_ibu" type="text" placeholder="Pekerjaan Ibu" required>
                
                <q-separator class="q-my-md" color="grey-12" inset />
                <div class="abu-800 text-labels">Alamat</div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Provinsi</span>
                            <select class="f_select" v-model="form.provinsi_ibu" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Provinsi              </option>
                                <option v-for="(item, index) in l_prov_ibu" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kabupaten / Kota</span>
                            <select class="f_select" v-model="form.kabupaten_ibu" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                <option v-for="(item, index) in l_kab_ibu" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kecamatan</span>
                            <select class="f_select" v-model="form.kecamatan_ibu" style="width: 100%;" @change="loadListData()" required>
                                <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                <option v-for="(item, index) in l_kec_ibu" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Desa / Kelurahan </span>
                            <select class="f_select" v-model="form.des_kel_ibu" style="width: 100%;" required>
                                <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                <option v-for="(item, index) in l_deskel_ibu" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                            </select>
                        </div>
                    </div>
                </div>



                <div class="abu-800 text-labels">Alamat Ibu</div>
                <input class="f_select" v-model="form.alamat_ibu" type="text" placeholder="Alamat Ibu" required>
                <div class="abu-800 text-labels">Kewarganegaraan Ibu</div>
                <select class="f_select" v-model="form.kewarganegaraan_ibu"> 
                    <option >Warga Negara Indonesia (WNI)</option> 
                    <option >Lainnya</option> 
                </select>

                <div v-if="form.kewarganegaraan_ibu == 'Lainnya' " class="abu-800 text-labels">Kewarganegaraan Ibu Lainnya</div>
                <input v-if="form.kewarganegaraan_ibu == 'Lainnya' " class="f_select" v-model="form.kewarganegaraan_ibu_lainnya" type="text" placeholder="kewarganegaraan_ibu_lainnya" required>
                <div class="abu-800 text-labels">Tgl Catatan Perkawinan</div>
                <input class="f_select" v-model="form.tgl_catatan_perkawinan" type="date" placeholder="tgl_catatan_perkawinan" required>                
                <!-- ===================== IBU ===================== -->

                <!-- ===================== AYAH ===================== -->
                <div rounded class="bg-cyan-8 q-my-md">
                    <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b>DATA AYAH</b></div>
                </div>

                <div class="abu-800 text-labels">NIK Ayah</div>
                <input class="f_select" v-model="form.nik_ayah" type="text" placeholder="NIK Ayah" required>
                <div class="abu-800 text-labels">Nama Ayah</div>
                <input class="f_select" v-model="form.nama_ayah" type="text" placeholder="Nama Ayah" required>
                <div class="abu-800 text-labels">Tgl Lahir Ayah</div>
                <input class="f_select" v-model="form.tgl_lahir_ayah" type="date" placeholder="Tgl Lahir Ayah" required>
                <div class="abu-800 text-labels">Pekerjaan Ayah</div>
                <input class="f_select" v-model="form.pekerjaan_ayah" type="text" placeholder="Pekerjaan Ayah" required>
                
                <q-separator class="q-my-md" color="grey-12" inset />
                <div class="abu-800 text-labels">Alamat</div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Provinsi</span>
                            <select class="f_select" v-model="form.provinsi_ayah" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Provinsi              </option>
                                <option v-for="(item, index) in l_prov_ayah" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kabupaten / Kota</span>
                            <select class="f_select" v-model="form.kabupaten_ayah" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                <option v-for="(item, index) in l_kab_ayah" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kecamatan</span>
                            <select class="f_select" v-model="form.kecamatan_ayah" style="width: 100%;" @change="loadListData()" required>
                                <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                <option v-for="(item, index) in l_kec_ayah" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Desa / Kelurahan </span>
                            <select class="f_select" v-model="form.des_kel_ayah" style="width: 100%;" required>
                                <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                <option v-for="(item, index) in l_deskel_ayah" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                            </select>
                        </div>
                    </div>
                </div>



                <div class="abu-800 text-labels">Alamat Ayah</div>
                <input class="f_select" v-model="form.alamat_ayah" type="text" placeholder="Alamat Ayah" required>
                <div class="abu-800 text-labels">Kewarganegaraan Ayah</div>
                <select class="f_select" v-model="form.kewarganegaraan_ayah"> 
                    <option >Warga Negara Indonesia (WNI)</option> 
                    <option >Lainnya</option> 
                </select>

                <div v-if="form.kewarganegaraan_ayah == 'Lainnya' " class="abu-800 text-labels">Kewarganegaraan Ayah Lainnya</div>
                <input v-if="form.kewarganegaraan_ayah == 'Lainnya' " class="f_select" v-model="form.kewarganegaraan_ayah_lainnya" type="text" placeholder="kewarganegaraan_ayah_lainnya" required>
                <!-- ===================== AYAH ===================== -->

                <!-- ===================== PELAPOR ===================== -->
                <div rounded class="bg-cyan-8 q-my-md">
                    <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b>DATA PELAPOR</b></div>
                </div>

                <div class="abu-800 text-labels">NIK Pelapor</div>
                <input class="f_select" v-model="form.nik_pelapor" type="text" placeholder="NIK Pelapor" required>
                <div class="abu-800 text-labels">Nama Pelapor</div>
                <input class="f_select" v-model="form.nama_pelapor" type="text" placeholder="Nama Pelapor" required>
                <div class="abu-800 text-labels">Umur Pelapor</div>
                <input class="f_select" v-model="form.umur_pelapor" type="number" placeholder="Umur Pelapor" required>
                <div class="abu-800 text-labels">Pekerjaan Pelapor</div>
                <input class="f_select" v-model="form.pekerjaan_pelapor" type="text" placeholder="Pekerjaan Pelapor" required>
                
                <q-separator class="q-my-md" color="grey-12" inset />
                <div class="abu-800 text-labels">Alamat</div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Provinsi</span>
                            <select class="f_select" v-model="form.provinsi_pelapor" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Provinsi              </option>
                                <option v-for="(item, index) in l_prov_pelapor" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kabupaten / Kota</span>
                            <select class="f_select" v-model="form.kabupaten_pelapor" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                <option v-for="(item, index) in l_kab_pelapor" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kecamatan</span>
                            <select class="f_select" v-model="form.kecamatan_pelapor" style="width: 100%;" @change="loadListData()" required>
                                <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                <option v-for="(item, index) in l_kec_pelapor" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Desa / Kelurahan </span>
                            <select class="f_select" v-model="form.des_kel_pelapor" style="width: 100%;" required>
                                <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                <option v-for="(item, index) in l_deskel_pelapor" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                            </select>
                        </div>
                    </div>
                </div>



                <div class="abu-800 text-labels">Alamat Pelapor</div>
                <input class="f_select" v-model="form.alamat_pelapor" type="text" placeholder="Alamat Pelapor" required>
                <!-- ===================== PELAPOR ===================== -->
                <!-- ===================== SAKSI 1 ===================== -->
                <div rounded class="bg-cyan-8 q-my-md">
                    <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b>DATA SAKSI 1</b></div>
                </div>
                <div class="abu-800 text-labels">NIK Saksi I</div>
                <input class="f_select" v-model="form.nik_saksi1" type="text" placeholder="NIK Saksi I" required>
                <div class="abu-800 text-labels">Nama Saksi I</div>
                <input class="f_select" v-model="form.nama_saksi1" type="text" placeholder="Nama Saksi I" required>
                <div class="abu-800 text-labels">Umur Saksi I</div>
                <input class="f_select" v-model="form.umur_saksi1" type="number" placeholder="Umur Saksi I" required>
                <div class="abu-800 text-labels">Pekerjaan Saksi I</div>
                <input class="f_select" v-model="form.pekerjaan_saksi1" type="text" placeholder="Pekerjaan Saksi I" required>
                
                <q-separator class="q-my-md" color="grey-12" inset />
                <div class="abu-800 text-labels">Alamat</div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Provinsi</span>
                            <select class="f_select" v-model="form.provinsi_saksi1" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Provinsi              </option>
                                <option v-for="(item, index) in l_prov_saksi1" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kabupaten / Kota</span>
                            <select class="f_select" v-model="form.kabupaten_saksi1" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                <option v-for="(item, index) in l_kab_saksi1" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kecamatan</span>
                            <select class="f_select" v-model="form.kecamatan_saksi1" style="width: 100%;" @change="loadListData()" required>
                                <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                <option v-for="(item, index) in l_kec_saksi1" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Desa / Kelurahan </span>
                            <select class="f_select" v-model="form.des_kel_saksi1" style="width: 100%;" required>
                                <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                <option v-for="(item, index) in l_deskel_saksi1" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                            </select>
                        </div>
                    </div>
                </div>



                <div class="abu-800 text-labels">Alamat Saksi I</div>
                <input class="f_select" v-model="form.alamat_saksi1" type="text" placeholder="Alamat Saksi I" required>

                <!-- ===================== SAKSI 1 ===================== -->
                <!-- ===================== SAKSI 2 ===================== -->
                <div rounded class="bg-cyan-8 q-my-md">
                    <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b>DATA SAKSI 2</b></div>
                </div>
                <div class="abu-800 text-labels">NIK Saksi II</div>
                <input class="f_select" v-model="form.nik_saksi2" type="text" placeholder="NIK Saksi II" required>
                <div class="abu-800 text-labels">Nama Saksi II</div>
                <input class="f_select" v-model="form.nama_saksi2" type="text" placeholder="Nama Saksi II" required>
                <div class="abu-800 text-labels">Umur Saksi II</div>
                <input class="f_select" v-model="form.umur_saksi2" type="number" placeholder="Umur Saksi II" required>
                <div class="abu-800 text-labels">Pekerjaan Saksi II</div>
                <input class="f_select" v-model="form.pekerjaan_saksi2" type="text" placeholder="Pekerjaan Saksi II" required>
                <q-separator class="q-my-md" color="grey-12" inset />
                <div class="abu-800 text-labels">Alamat</div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Provinsi</span>
                            <select class="f_select" v-model="form.provinsi_saksi2" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Provinsi              </option>
                                <option v-for="(item, index) in l_prov_saksi2" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kabupaten / Kota</span>
                            <select class="f_select" v-model="form.kabupaten_saksi2" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                <option v-for="(item, index) in l_kab_saksi2" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kecamatan</span>
                            <select class="f_select" v-model="form.kecamatan_saksi2" style="width: 100%;" @change="loadListData()" required>
                                <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                <option v-for="(item, index) in l_kec_saksi2" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Desa / Kelurahan </span>
                            <select class="f_select" v-model="form.des_kel_saksi2" style="width: 100%;" required>
                                <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                <option v-for="(item, index) in l_deskel_saksi2" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                            </select>
                        </div>
                    </div>
                </div>



                <div class="abu-800 text-labels">Alamat Saksi II</div>
                <input class="f_select" v-model="form.alamat_saksi2" type="text" placeholder="Alamat Saksi II" required>

                <!-- ===================== SAKSI 2 ===================== -->
                
                <div class="abu-800 text-labels">File Lampiran</div>
                <q-file outlined v-model="form.file_lampiran" :dense="true" required>
                    <template v-slot:prepend> <q-icon name="attach_file" /> </template>
                </q-file>
            </div>
            </div> 
  
  
  
         
  
        </div>  
      </template>
    </MDL_ADD>
  
    <MDL_EDT :modal="$store.state.mdl_edit" :metode="editData"> 
        <template  v-slot:bodys>
        <div class="row"> 
  
            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                <div class="q-px-sm">
                    <span class="abu-800">Pemerintah Provinsi</span>
                    <select class="f_select" v-model="form.m_provinsi" style="width: 100%;" @change="loadListData()">
                        <option value="" :disabled="true">           Pilih Provinsi              </option>
                        <option v-for="(item, index) in l_prov" :key="item.m_provinsi" :value="item.m_provinsi" :disabled="true">  {{item.uraian}}         </option> 
                    </select>
                </div>
            </div>
  
            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                <div class="q-px-sm">
                    <span class="abu-800">Pemerintah Kabupaten / Kota</span>
                    <select class="f_select" v-model="form.m_kabupaten" style="width: 100%;" @change="loadListData()">
                        <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                        <option v-for="(item, index) in l_kab" :key="item.m_kabupaten" :value="item.m_kabupaten" :disabled="true">  {{item.uraian}}         </option> 
                    </select>
                </div>
            </div>
  
            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                <div class="q-px-sm">
                    <span class="abu-800">Kecamatan</span>
                    <select class="f_select" v-model="form.m_kecamatan" style="width: 100%;" @change="loadListData()" required>
                        <option value="">           Pilih Kecamatan              </option>
                        <option v-for="(item, index) in l_kec" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                    </select>
                </div>
            </div>
            <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                <div class="q-px-sm">
                    <span class="abu-800">Desa / Kelurahan {{ form.m_desa_kelurahan }}</span>
                    <select class="f_select" v-model="form.m_desa_kelurahan" style="width: 100%;" required>
                        <option value="">           Pilih Desa / Kelurahan              </option>
                        <option v-for="(item, index) in l_deskel" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                    </select>
                </div>
            </div>
  
  
            <div class="col-12  col-md-12 col-sm-12 col-xs-12">
            <div class="q-px-sm">
                
                <div class="abu-800 text-labels">Nama Kepala Keluarga</div>
                <input class="f_select" v-model="form.nama_kepala_keluarga" type="text" placeholder="Nama Kepala Keluarga" required>

                <div class="abu-800 text-labels">Nomor Kartu Keluarga</div>
                <input class="f_select" v-model="form.no_kk" type="text" placeholder="Nomor Kartu Keluarga" required>

                
                <!-- ===================== BAYI ===================== -->
                <br>


                <div rounded class="bg-cyan-8 q-my-md">
                    <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b>DATA BAYI / ANAK</b></div>
                </div>

                <div class="abu-800 text-labels">Nama Bayi</div>
                <input class="f_select" v-model="form.nama_bayi" type="text" placeholder="Nama Bayi" required>

                <div class="abu-800 text-labels">Jenis Kelamin</div>
                <select class="f_select" v-model="form.jenis_kelamin_bayi" style="width: 100%;" required>
                    <option  value="" disabled>  Pilih Jenis Kelamin         </option>  
                    <option  value="Laki - Laki"> Laki - Laki         </option>  
                    <option  value="Perempuan">  Perempuan         </option>  
                </select>
                <!-- <input class="f_select" v-model="form.jenis_kelamin_bayi" type="text" placeholder="Nama Bayi" required> -->
                
                <div class="abu-800 text-labels">Tempat Dilahirkan</div>
                <select class="f_select" v-model="form.tmpt_lahir_bayi" style="width: 100%;" required>
                    <option  value="" disabled>  Pilih Tempat Dilahirkan         </option>  
                    <option value="RS/RB"> RS/RB</option>
                    <option value="Puskesmas"> Puskesmas</option> 
                    <option value="Polindes"> Polindes</option> 
                    <option value="Rumah"> Rumah</option> 
                    <option value="Lainnya"> Lainnya</option> 
                </select>
                
                <div class="abu-800 text-labels" v-if="form.tmpt_lahir_bayi == 'Lainnya'">Tempat Dilahirkan Lainnya</div>
                <input class="f_select" v-if="form.tmpt_lahir_bayi == 'Lainnya'" v-model="form.tmpt_lahir_bayi_lainnya" type="text" placeholder="Tempat Dilahirkan Lainnya" required>
                
                <div class="abu-800 text-labels">Tempat Kelahiran</div>
                <input class="f_select" v-model="form.tempat_kelahiran_bayi" type="text" placeholder="Tempat Kelahiran" required>
                
                <div class="abu-800 text-labels">Hari Lahir</div>

                <select class="f_select" v-model="form.nama_hari" style="width: 100%;" required>
                    <option  value="" disabled>  Pilih Hari Lahir         </option>  
                    <option v-for="(namahari, index) in $store.state.nama_hari" :key="index" :value="namahari"> {{namahari}}</option>
                </select>

                <div class="abu-800 text-labels">Tanggal Lahir</div>
                <input class="f_select" v-model="form.tgl_lahir_bayi" type="date" placeholder="Tanggal Lahir" required>

                <div class="abu-800 text-labels">Pukul</div>
                <input class="f_select" v-model="form.pukul" type="time" placeholder="Pukul" required>

                <div class="abu-800 text-labels">Jenis Kelahiran</div>
                <select class="f_select" v-model="form.jenis_lahir"> 
                    <option >Tunggal</option>
                    <option >Kembar 2</option> 
                    <option >Kembar 3</option> 
                    <option >Kembar 4</option> 
                    <option >Lainnya</option> 
                </select>

                <div class="abu-800 text-labels" v-if="form.jenis_lahir == 'Lainnya'" >Jenis Kelahiran Lainnya</div>
                <input class="f_select" v-if="form.jenis_lahir == 'Lainnya'" v-model="form.jenis_lahir_lainnya" type="text" placeholder="Jenis Kelahiran Lainnya" required>

                <div class="abu-800 text-labels">Kelahiran Ke-</div>
                <input class="f_select" v-model="form.kelahiran_ke" type="number" placeholder="Kelahiran Ke-" required>

                <div class="abu-800 text-labels">Penolong Kelahiran</div>
                <select class="f_select" v-model="form.penolong_kelahiran"> 
                    <option >Dokter</option>
                    <option >Bidan/Perawat</option> 
                    <option >Dukun</option>  
                    <option >Lainnya</option> 
                </select>

                <div class="abu-800 text-labels" v-if="form.penolong_kelahiran == 'Lainnya' ">Penolong Kelahiran Lainnya</div>
                <input class="f_select" v-if="form.penolong_kelahiran == 'Lainnya' " v-model="form.penolong_kelahiran_lainnya" type="text" placeholder="Penolong Kelahiran Lainnya" required>

                <div class="abu-800 text-labels">Berat Bayi ({{ form.berat_bayi }}Kg)</div>
                <input class="f_select" v-model="form.berat_bayi" type="number" step="0.1" placeholder="Berat Bayi" required>

                <div class="abu-800 text-labels">Panjang Bayi ({{ form.panjang_bayi }}Cm)</div>
                <input class="f_select" v-model="form.panjang_bayi" type="number" step="0.1" placeholder="Nama Lengkap" required> 

                <!-- ===================== BAYI ===================== -->

                <!-- ===================== IBU ===================== -->
                <!-- <q-separator class="q-my-md" color="orange" inset /> -->
                <div rounded class="bg-cyan-8 q-my-md">
                    <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b>DATA IBU</b></div>
                </div>
                <!-- <q-separator class="q-my-md" color="orange" inset /> -->

                <div class="abu-800 text-labels">NIK Ibu</div>
                <input class="f_select" v-model="form.nik_ibu" type="text" placeholder="nik_ibu" required>
                <div class="abu-800 text-labels">Nama Ibu</div>
                <input class="f_select" v-model="form.nama_ibu" type="text" placeholder="nama_ibu" required>
                <div class="abu-800 text-labels">Tgl Lahir Ibu</div>
                <input class="f_select" v-model="form.tgl_lahir_ibu" type="date" placeholder="Tgl Lahir Ibu" required>
                <div class="abu-800 text-labels">Pekerjaan Ibu</div>
                <input class="f_select" v-model="form.pekerjaan_ibu" type="text" placeholder="pekerjaan_ibu" required>
                
                <q-separator class="q-my-md" color="grey-12" inset />
                <div class="abu-800 text-labels">Alamat</div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Provinsi</span>
                            <select class="f_select" v-model="form.provinsi_ibu" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Provinsi              </option>
                                <option v-for="(item, index) in l_prov_ibu" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kabupaten / Kota</span>
                            <select class="f_select" v-model="form.kabupaten_ibu" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                <option v-for="(item, index) in l_kab_ibu" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kecamatan</span>
                            <select class="f_select" v-model="form.kecamatan_ibu" style="width: 100%;" @change="loadListData()" required>
                                <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                <option v-for="(item, index) in l_kec_ibu" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Desa / Kelurahan </span>
                            <select class="f_select" v-model="form.des_kel_ibu" style="width: 100%;" required>
                                <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                <option v-for="(item, index) in l_deskel_ibu" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                            </select>
                        </div>
                    </div>
                </div>



                <div class="abu-800 text-labels">Alamat Ibu</div>
                <input class="f_select" v-model="form.alamat_ibu" type="text" placeholder="Alamat Ibu" required>
                <div class="abu-800 text-labels">Kewarganegaraan Ibu</div>
                <select class="f_select" v-model="form.kewarganegaraan_ibu"> 
                    <option >Warga Negara Indonesia (WNI)</option> 
                    <option >Lainnya</option> 
                </select>

                <div v-if="form.kewarganegaraan_ibu == 'Lainnya' " class="abu-800 text-labels">Kewarganegaraan Ibu Lainnya</div>
                <input v-if="form.kewarganegaraan_ibu == 'Lainnya' " class="f_select" v-model="form.kewarganegaraan_ibu_lainnya" type="text" placeholder="kewarganegaraan_ibu_lainnya" required>
                <div class="abu-800 text-labels">Tgl Catatan Perkawinan</div>
                <input class="f_select" v-model="form.tgl_catatan_perkawinan" type="date" placeholder="tgl_catatan_perkawinan" required>                
                <!-- ===================== IBU ===================== -->

                <!-- ===================== AYAH ===================== -->
                <div rounded class="bg-cyan-8 q-my-md">
                    <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b>DATA AYAH</b></div>
                </div>

                <div class="abu-800 text-labels">NIK Ayah</div>
                <input class="f_select" v-model="form.nik_ayah" type="text" placeholder="nik_ayah" required>
                <div class="abu-800 text-labels">Nama Ayah</div>
                <input class="f_select" v-model="form.nama_ayah" type="text" placeholder="nama_ayah" required>
                <div class="abu-800 text-labels">Tgl Lahir Ayah</div>
                <input class="f_select" v-model="form.tgl_lahir_ayah" type="date" placeholder="Tgl Lahir Ayah" required>
                <div class="abu-800 text-labels">Pekerjaan Ayah</div>
                <input class="f_select" v-model="form.pekerjaan_ayah" type="text" placeholder="pekerjaan_ayah" required>
                
                <q-separator class="q-my-md" color="grey-12" inset />
                <div class="abu-800 text-labels">Alamat</div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Provinsi</span>
                            <select class="f_select" v-model="form.provinsi_ayah" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Provinsi              </option>
                                <option v-for="(item, index) in l_prov_ayah" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kabupaten / Kota</span>
                            <select class="f_select" v-model="form.kabupaten_ayah" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                <option v-for="(item, index) in l_kab_ayah" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kecamatan</span>
                            <select class="f_select" v-model="form.kecamatan_ayah" style="width: 100%;" @change="loadListData()" required>
                                <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                <option v-for="(item, index) in l_kec_ayah" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Desa / Kelurahan </span>
                            <select class="f_select" v-model="form.des_kel_ayah" style="width: 100%;" required>
                                <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                <option v-for="(item, index) in l_deskel_ayah" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                            </select>
                        </div>
                    </div>
                </div>



                <div class="abu-800 text-labels">Alamat Ayah</div>
                <input class="f_select" v-model="form.alamat_ayah" type="text" placeholder="Alamat Ayah" required>
                <div class="abu-800 text-labels">Kewarganegaraan Ayah</div>
                <select class="f_select" v-model="form.kewarganegaraan_ayah"> 
                    <option >Warga Negara Indonesia (WNI)</option> 
                    <option >Lainnya</option> 
                </select>

                <div v-if="form.kewarganegaraan_ayah == 'Lainnya' " class="abu-800 text-labels">Kewarganegaraan Ayah Lainnya</div>
                <input v-if="form.kewarganegaraan_ayah == 'Lainnya' " class="f_select" v-model="form.kewarganegaraan_ayah_lainnya" type="text" placeholder="kewarganegaraan_ayah_lainnya" required>
                <!-- ===================== AYAH ===================== -->

                <!-- ===================== PELAPOR ===================== -->
                <div rounded class="bg-cyan-8 q-my-md">
                    <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b>DATA PELAPOR</b></div>
                </div>

                <div class="abu-800 text-labels">NIK Pelapor</div>
                <input class="f_select" v-model="form.nik_pelapor" type="text" placeholder="nik_pelapor" required>
                <div class="abu-800 text-labels">Nama Pelapor</div>
                <input class="f_select" v-model="form.nama_pelapor" type="text" placeholder="nama_pelapor" required>
                <div class="abu-800 text-labels">Umur Pelapor</div>
                <input class="f_select" v-model="form.umur_pelapor" type="number" placeholder="Umur Pelapor" required>
                <div class="abu-800 text-labels">Pekerjaan Pelapor</div>
                <input class="f_select" v-model="form.pekerjaan_pelapor" type="text" placeholder="pekerjaan_pelapor" required>
                
                <q-separator class="q-my-md" color="grey-12" inset />
                <div class="abu-800 text-labels">Alamat</div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Provinsi</span>
                            <select class="f_select" v-model="form.provinsi_pelapor" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Provinsi              </option>
                                <option v-for="(item, index) in l_prov_pelapor" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kabupaten / Kota</span>
                            <select class="f_select" v-model="form.kabupaten_pelapor" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                <option v-for="(item, index) in l_kab_pelapor" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kecamatan</span>
                            <select class="f_select" v-model="form.kecamatan_pelapor" style="width: 100%;" @change="loadListData()" required>
                                <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                <option v-for="(item, index) in l_kec_pelapor" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Desa / Kelurahan </span>
                            <select class="f_select" v-model="form.des_kel_pelapor" style="width: 100%;" required>
                                <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                <option v-for="(item, index) in l_deskel_pelapor" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                            </select>
                        </div>
                    </div>
                </div>



                <div class="abu-800 text-labels">Alamat Pelapor</div>
                <input class="f_select" v-model="form.alamat_pelapor" type="text" placeholder="Alamat Pelapor" required>
                <!-- ===================== PELAPOR ===================== -->
                <!-- ===================== SAKSI 1 ===================== -->
                <div rounded class="bg-cyan-8 q-my-md">
                    <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b>DATA SAKSI 1</b></div>
                </div>
                <div class="abu-800 text-labels">NIK Saksi I</div>
                <input class="f_select" v-model="form.nik_saksi1" type="text" placeholder="nik_saksi1" required>
                <div class="abu-800 text-labels">Nama Saksi I</div>
                <input class="f_select" v-model="form.nama_saksi1" type="text" placeholder="nama_saksi1" required>
                <div class="abu-800 text-labels">Umur Saksi I</div>
                <input class="f_select" v-model="form.umur_saksi1" type="number" placeholder="Umur Saksi I" required>
                <div class="abu-800 text-labels">Pekerjaan Saksi I</div>
                <input class="f_select" v-model="form.pekerjaan_saksi1" type="text" placeholder="pekerjaan_saksi1" required>
                
                <q-separator class="q-my-md" color="grey-12" inset />
                <div class="abu-800 text-labels">Alamat</div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Provinsi</span>
                            <select class="f_select" v-model="form.provinsi_saksi1" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Provinsi              </option>
                                <option v-for="(item, index) in l_prov_saksi1" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kabupaten / Kota</span>
                            <select class="f_select" v-model="form.kabupaten_saksi1" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                <option v-for="(item, index) in l_kab_saksi1" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kecamatan</span>
                            <select class="f_select" v-model="form.kecamatan_saksi1" style="width: 100%;" @change="loadListData()" required>
                                <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                <option v-for="(item, index) in l_kec_saksi1" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Desa / Kelurahan </span>
                            <select class="f_select" v-model="form.des_kel_saksi1" style="width: 100%;" required>
                                <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                <option v-for="(item, index) in l_deskel_saksi1" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                            </select>
                        </div>
                    </div>
                </div>



                <div class="abu-800 text-labels">Alamat Saksi I</div>
                <input class="f_select" v-model="form.alamat_saksi1" type="text" placeholder="Alamat Saksi I" required>

                <!-- ===================== SAKSI 1 ===================== -->
                <!-- ===================== SAKSI 2 ===================== -->
                <div rounded class="bg-cyan-8 q-my-md">
                    <div class="text-labels q-py-xs text-white text-center" style="font-size: 9pt;"><b>DATA SAKSI 2</b></div>
                </div>
                <div class="abu-800 text-labels">NIK Saksi II</div>
                <input class="f_select" v-model="form.nik_saksi2" type="text" placeholder="nik_saksi2" required>
                <div class="abu-800 text-labels">Nama Saksi II</div>
                <input class="f_select" v-model="form.nama_saksi2" type="text" placeholder="nama_saksi2" required>
                <div class="abu-800 text-labels">Umur Saksi II</div>
                <input class="f_select" v-model="form.umur_saksi2" type="number" placeholder="Umur Saksi II" required>
                <div class="abu-800 text-labels">Pekerjaan Saksi II</div>
                <input class="f_select" v-model="form.pekerjaan_saksi2" type="text" placeholder="pekerjaan_saksi2" required>
                
                <q-separator class="q-my-md" color="grey-12" inset />
                <div class="abu-800 text-labels">Alamat</div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Provinsi</span>
                            <select class="f_select" v-model="form.provinsi_saksi2" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Provinsi              </option>
                                <option v-for="(item, index) in l_prov_saksi2" :key="item.m_provinsi" :value="item.m_provinsi" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kabupaten / Kota</span>
                            <select class="f_select" v-model="form.kabupaten_saksi2" style="width: 100%;" @change="loadListData()">
                                <option value="" :disabled="true">           Pilih Kabupaten              </option> 
                                <option v-for="(item, index) in l_kab_saksi2" :key="item.m_kabupaten" :value="item.m_kabupaten" >  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
        
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Kecamatan</span>
                            <select class="f_select" v-model="form.kecamatan_saksi2" style="width: 100%;" @change="loadListData()" required>
                                <option value="" :disabled="true">           Pilih Kecamatan              </option>
                                <option v-for="(item, index) in l_kec_saksi2" :key="item.m_kecamatan" :value="item.m_kecamatan">  {{item.uraian}}         </option> 
                            </select>
                        </div>
                    </div>
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" > 
                        <div class="q-px-sm">
                            <span class="abu-800">Desa / Kelurahan </span>
                            <select class="f_select" v-model="form.des_kel_saksi2" style="width: 100%;" required>
                                <option value="" :disabled="true">           Pilih Desa / Kelurahan              </option>
                                <option v-for="(item, index) in l_deskel_saksi2" :key="item.m_desa_kelurahan" :value="item.m_desa_kelurahan">  {{item.uraian}}         </option>  
                            </select>
                        </div>
                    </div>
                </div>



                <div class="abu-800 text-labels">Alamat Saksi II</div>
                <input class="f_select" v-model="form.alamat_saksi2" type="text" placeholder="Alamat Saksi II" required>

                <!-- ===================== SAKSI 2 ===================== -->
                
                <div class="abu-800 text-labels">File Lampiran</div>
                <q-file outlined v-model="form.file_lampiran" :dense="true" >
                    <template v-slot:prepend> <q-icon name="attach_file" /> </template>
                </q-file>
            </div>
            </div> 
  
  
  
         
  
        </div>  
      </template>
    </MDL_EDT>
  
    <MDL_DEL :modal="$store.state.mdl_del" :metode="removeData"/>

    <q-dialog v-model="$store.state.mdl_view" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-primary">
                        <span class="q-ma-md text-white">LIHAT DATA</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-primary">
                        <a @click="$store.state.mdl_view=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <q-separator />
 
                <q-card-section>
                    <div class="q-px-xs q-py-md">
                        <table id="table_view" style="width: 100%;">
                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">WILAYAH PEMERINTAH</td> 
                            </tr> 
                            <tr>
                                <td width="50%">Pemerintah Provinsi</td>
                                <td width="50%">{{form_view.uraian_1_provinsi}}</td>
                            </tr> 
                            <tr>
                                <td>Pemerintah Kabupaten / Kota</td>
                                <td>{{form_view.uraian_1_kabupaten}}</td>
                            </tr> 
                            <tr>
                                <td>Kecamatan</td>
                                <td>{{form_view.uraian_1_kecamatan}}</td>
                            </tr> 
                            <tr>
                                <td>Desa / Kelurahan</td>
                                <td>{{form_view.uraian_1_desa}}</td>
                            </tr> 
                        </table>

                        <br>

                        <table id="table_view" style="width: 100%;">

                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">DATA PEMOHON</td> 
                            </tr>
                            <tr>
                                <td>Nama Kepala Keluarga</td>
                                <td>{{form.nama_kepala_keluarga}}</td>
                            </tr>
                            <tr>
                                <td>Nomor KK</td>
                                <td>{{form.no_kk}}</td>
                            </tr>
                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">DATA BAYI</td> 
                            </tr>
                            <tr>
                                <td>Nama Bayi</td>
                                <td>{{form.nama_bayi}}</td>
                            </tr>
                            <tr>
                                <td>Jenis Kelamin</td>
                                <td>{{form.jenis_kelamin_bayi}}</td>
                            </tr>
                            <tr>
                                <td>Tempat Lahir Bayi</td>
                                <td v-if="form.tmpt_lahir_bayi != 'Lainnya' ">{{form.tmpt_lahir_bayi}}</td>
                                <td v-else>{{form.tmpt_lahir_bayi_lainnya}}</td>
                            </tr>
                            <tr>
                                <td>Tempat Kelahiran Bayi</td>
                                <td>{{form.tempat_kelahiran_bayi}}</td>
                            </tr>
                            <tr>
                                <td>Hari Lahir</td>
                                <td>{{form.nama_hari}}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Lahir</td>
                                <td>{{form.tgl_lahir_bayi}}</td>
                            </tr>
                            <tr>
                                <td>Waktu Lahir</td>
                                <td>{{form.pukul}}</td>
                            </tr>
                            <tr>
                                <td>Jenis Lahir Bayi</td>
                                <td v-if="form.jenis_lahir != 'Lainnya' ">{{form.jenis_lahir}}</td>
                                <td v-else>{{form.jenis_lahir_lainnya}}</td>
                            </tr>
                            <tr>
                                <td>Kelahiran Ke - </td>
                                <td>{{form.kelahiran_ke}}</td>
                            </tr>
                            <tr>
                                <td>Penolong Kelahiran Bayi</td>
                                <td v-if="form.penolong_kelahiran != 'Lainnya' ">{{form.penolong_kelahiran}}</td>
                                <td v-else>{{form.penolong_kelahiran_lainnya}}</td>
                            </tr>
                            <tr>
                                <td>Berat Bayi </td>
                                <td>{{form.berat_bayi}}</td>
                            </tr>
                            <tr>
                                <td>Panjang Bayi </td>
                                <td>{{form.panjang_bayi}}</td>
                            </tr>

                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">DATA IBU</td> 
                            </tr>
                            <tr>
                                <td>NIK Ibu </td>
                                <td>{{form.nik_ibu}}</td>
                            </tr>
                            <tr>
                                <td>Nama Ibu </td>
                                <td>{{form.nama_ibu}}</td>
                            </tr>
                            <tr>
                                <td>Tgl Lahir Ibu </td>
                                <td>{{form.tgl_lahir_ibu}}</td>
                            </tr>
                            <tr>
                                <td>Pekerjaan Ibu </td>
                                <td>{{form.pekerjaan_ibu}}</td>
                            </tr>

                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_ibu_provinsi}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_ibu_kabupaten}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_ibu_kecamatan}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_ibu_desa}}</td>
                            </tr>

                            <tr>
                                <td>Alamat </td>
                                <td>{{form.alamat_ibu}}</td>
                            </tr>
                            <tr>
                                <td>Kewarga Negaraan</td>
                                <td v-if="form.kewarganegaraan_ibu != 'Lainnya' ">{{form.kewarganegaraan_ibu}}</td>
                                <td v-else>{{form.kewarganegaraan_ibu_lainnya}}</td>
                            </tr>
                            <tr>
                                <td>Tanggal Catatan Perkawinan </td>
                                <td>{{form.tgl_catatan_perkawinan}}</td>
                            </tr>

                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">DATA AYAH</td> 
                            </tr>
                            <tr>
                                <td>NIK Ayah </td>
                                <td>{{form.nik_ayah}}</td>
                            </tr>
                            <tr>
                                <td>Nama Ayah </td>
                                <td>{{form.nama_ayah}}</td>
                            </tr>
                            <tr>
                                <td>Tgl Lahir Ayah </td>
                                <td>{{form.tgl_lahir_ayah}}</td>
                            </tr>
                            <tr>
                                <td>Pekerjaan Ayah </td>
                                <td>{{form.pekerjaan_ayah}}</td>
                            </tr>

                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_ayah_provinsi}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_ayah_kabupaten}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_ayah_kecamatan}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_ayah_desa}}</td>
                            </tr>

                            <tr>
                                <td>Alamat </td>
                                <td>{{form.alamat_ayah}}</td>
                            </tr>
                            <tr>
                                <td>Kewarga Negaraan</td>
                                <td v-if="form.kewarganegaraan_ayah != 'Lainnya' ">{{form.kewarganegaraan_ayah}}</td>
                                <td v-else>{{form.kewarganegaraan_ayah_lainnya}}</td>
                            </tr>

                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">DATA PELAPOR</td> 
                            </tr>
                            <tr>
                                <td>NIK Pelapor </td>
                                <td>{{form.nik_pelapor}}</td>
                            </tr>
                            <tr>
                                <td>Nama Pelapor </td>
                                <td>{{form.nama_pelapor}}</td>
                            </tr> 
                            <tr>
                                <td>Umur Pelapor </td>
                                <td>{{form.umur_pelapor}}</td>
                            </tr>

                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_pelapor_provinsi}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_pelapor_kabupaten}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_pelapor_kecamatan}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_pelapor_desa}}</td>
                            </tr>

                            <tr>
                                <td>Alamat </td>
                                <td>{{form.alamat_pelapor}}</td>
                            </tr> 

                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">DATA SAKSI I</td> 
                            </tr>
                            <tr>
                                <td>NIK Saksi I </td>
                                <td>{{form.nik_saksi1}}</td>
                            </tr>
                            <tr>
                                <td>Nama Saksi I </td>
                                <td>{{form.nama_saksi1}}</td>
                            </tr> 
                            <tr>
                                <td>Umur Saksi I </td>
                                <td>{{form.umur_saksi1}}</td>
                            </tr>

                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_saksi1_provinsi}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_saksi1_kabupaten}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_saksi1_kecamatan}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_saksi1_desa}}</td>
                            </tr>

                            <tr>
                                <td>Alamat </td>
                                <td>{{form.alamat_saksi1}}</td>
                            </tr> 

                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">DATA SAKSI II</td> 
                            </tr>
                            <tr>
                                <td>NIK Saksi II </td>
                                <td>{{form.nik_saksi2}}</td>
                            </tr>
                            <tr>
                                <td>Nama Saksi II </td>
                                <td>{{form.nama_saksi2}}</td>
                            </tr> 
                            <tr>
                                <td>Umur Saksi II </td>
                                <td>{{form.umur_saksi2}}</td>
                            </tr>

                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_saksi2_provinsi}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_saksi2_kabupaten}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_saksi2_kecamatan}}</td>
                            </tr>
                            <tr>
                                <td>Provinsi </td>
                                <td>{{form_view.uraian_saksi2_desa}}</td>
                            </tr>

                            <tr>
                                <td>Alamat </td>
                                <td>{{form.alamat_saksi2}}</td>
                            </tr> 

                            <tr class="bg-light-blue-9">
                                <td class="text-white" colspan="2" width="100%">LAMPIRAN</td> 
                            </tr>
                            
                            <tr>
                                <td>Lampiran</td>
                                <td> 
                                    <q-btn color="blue-5" icon="source" size="sm" @click="$store.state.mdl_lihat_file=true, lihatFilex(form.file_lampiran)">
                                        <span class="q-ml-xs">lihat Lampiran</span>
                                        <q-tooltip class="bg-indigo" :offset="[0, -60]"> Lihat </q-tooltip>
                                    </q-btn>
                                </td>
                            </tr>
                        </table>
 
                    </div>

                </q-card-section>
                <q-separator />
    
                <q-card-actions align="right">
                    <div class="q-mx-xs q-gutter-xs" v-show="checkKelasUser('kabupaten') || checkKelasUser('administrator')">
                        <q-btn size="sm" label="Verifikasi Kabupaten" color="primary" @click="$store.state.mdl_verifikasi_kab = true" />
                        <q-btn size="sm" label="Tolak Kabupaten" color="negative" @click="$store.state.mdl_kembalikan_kab = true" />
                    </div>
                    <div class="q-mx-xs q-gutter-xs" v-show="checkKelasUser('verifikator') || checkKelasUser('administrator')">
                        <q-btn size="sm" :disable="form.status_kabupaten == 2" label="Verifikasi Kecamatan" color="primary"  @click="$store.state.mdl_verifikasi_kec= true" />
                        <q-btn size="sm" :disable="form.status_kabupaten == 2" label="Tolak Kecamatan" color="negative"  @click="$store.state.mdl_kembalikan_kec= true" />
                    </div>
                    <div class="q-mx-xs q-gutter-xs">
                        <q-btn size="sm" label="Kembali" color="negative" @click="$store.state.mdl_view = false"   />
                    </div>
                </q-card-actions>
                
   
        </q-card>
    </q-dialog>    
    
    <q-dialog v-model="$store.state.mdl_history" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-primary">
                        <span class="q-ma-md text-white">HISTORY</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-primary">
                        <a @click="$store.state.mdl_history=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <q-separator />
 
                <q-card-section>
                    <div class="q-px-lg q-py-md">
                        <q-timeline color="secondary">
                        <q-timeline-entry heading class="">
                            Timeline SOP
                        </q-timeline-entry>

                         

                        <q-timeline-entry icon="check">
                            <template v-slot:title>
                            INPUT DATA
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt).tgl}} |
                                {{UMUM.tglConvert(form.createdAt).time}}
                            </template>

                            <div>
                                Tahap pengunputan permohonan berhasil.
                            </div>
                        </q-timeline-entry>

                        <!-- ==================== -->

                        <q-timeline-entry icon="hourglass_empty" color="orange-8" v-if="form.status_kecamatan == 0">
                            <template v-slot:title>
                            TAHAPAN KECAMATAN
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt_kecamatan).tgl}} |
                                {{UMUM.tglConvert(form.createdAt_kecamatan).time}} 
                            </template>

                            <div>
                                Menunggu verifikasi kecamatan.
                            </div>
                        </q-timeline-entry>
                        
                        <q-timeline-entry icon="check" color="blue-8" v-if="form.status_kecamatan == 1" >
                            <template v-slot:title>
                            TAHAPAN KECAMATAN
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt_kecamatan).tgl}} |
                                {{UMUM.tglConvert(form.createdAt_kecamatan).time}} 
                            </template>

                            <div>
                                Data Telah Diverifikasi. <br>
                                {{ form.keterangan_kecamatan }}
                            </div>
                        </q-timeline-entry>

                        <q-timeline-entry icon="close" color="red-8" v-if="form.status_kecamatan == 2" >
                            <template v-slot:title>
                                TAHAPAN KECAMATAN
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt_kecamatan).tgl}} |
                                {{UMUM.tglConvert(form.createdAt_kecamatan).time}} 
                            </template>

                            <div>
                                Dikembalikan. <br>
                                {{ form.keterangan_kecamatan }}

                            </div>
                        </q-timeline-entry> 

                        <!-- ================================ -->

                        <q-timeline-entry icon="hourglass_empty" color="orange-8" v-if="form.status_kabupaten == 0">
                            <template v-slot:title>
                            TAHAPAN KABUPATEN
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt_kabupaten).tgl}} |
                                {{UMUM.tglConvert(form.createdAt_kabupaten).time}} 
                            </template>

                            <div>
                                Menunggu verifikasi kabupaten
                            </div>
                        </q-timeline-entry>
                        
                        <q-timeline-entry icon="check" color="blue-8" v-if="form.status_kabupaten == 1" >
                            <template v-slot:title>
                            TAHAPAN KABUPATEN
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt_kabupaten).tgl}} |
                                {{UMUM.tglConvert(form.createdAt_kabupaten).time}} 
                            </template>

                            <div>
                                Data Telah Diverifikasi. <br>
                                {{form.keterangan_kabupaten}}
                            </div>
                        </q-timeline-entry>

                        <q-timeline-entry icon="close" color="red-8" v-if="form.status_kabupaten == 2" >
                            <template v-slot:title>
                                TAHAPAN KABUPATEN
                            </template>
                            <template v-slot:subtitle>
                                {{UMUM.tglConvert(form.createdAt_kabupaten).tgl}} |
                                {{UMUM.tglConvert(form.createdAt_kabupaten).time}} 
                            </template>

                            <div>
                                Dikembalikan. <br>
                                {{ form.keterangan_kabupaten }}
                            </div>
                        </q-timeline-entry> 


                        
                        </q-timeline>
                    </div>

                </q-card-section>
                <q-separator />
    
                <q-card-actions align="center">
                    <!-- <q-btn size="sm" label="Tambah" color="primary" type="submit" /> -->
                    <q-btn size="sm" label="Kembali" color="negative" @click="$store.state.mdl_history = false"   />
                </q-card-actions>
                
   
        </q-card>
    </q-dialog>    



    <q-dialog v-model="$store.state.mdl_verifikasi_kec" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-primary">
                        <span class="q-ma-md text-white">VERIFIKASI</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-primary">
                        <a @click="$store.state.mdl_verifikasi_kec=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <q-separator />
 
                <q-card-section>
                    <div class="q-px-lg q-py-md">
                        <div class="abu-800 text-labels">Masukan Informasi Verifikasi</div>
                        <div class="abu-800 text-labels q-mb-xs">* Informasi ini berupa keterangan dari verifikator yang akan di lihat oleh pemohon dan verifikator</div>
                        <q-input v-model="form.keterangan_kecamatan" filled type="textarea" style="width:100%"/>
                    </div>
                </q-card-section>
                <q-separator />
    
                <q-card-actions align="center">
                    <q-btn size="sm" label="Kirim" color="primary" type="submit" @click="editDataKecamatan('diterima')" />
                    <q-btn size="sm" label="Kembali" color="negative" @click="$store.state.mdl_verifikasi_kec = false"   />
                </q-card-actions>
                
   
        </q-card>
    </q-dialog>    

    <q-dialog v-model="$store.state.mdl_kembalikan_kec" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-negative">
                        <span class="q-ma-md text-white">KEMBALIKAN</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-negative">
                        <a @click="$store.state.mdl_kembalikan_kec=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <q-separator />
 
                <q-card-section>
                    
                    <div class="q-px-lg q-py-md">
                        <div class="abu-800 text-labels">Masukan Informasi Pengembalian</div>
                        <div class="abu-800 text-labels q-mb-xs">* Informasi ini berupa keterangan dari verifikator mengapa data di kembalikan yang akan di lihat oleh pemohon</div>
                        <q-input v-model="form.keterangan_kecamatan" filled type="textarea" style="width:100%"/>
                    </div>

                </q-card-section>
                <q-separator />
    
                <q-card-actions align="center">
                    <q-btn size="sm" label="Tambah" color="primary" @click="editDataKecamatan('dikembalikan')" />
                    <q-btn size="sm" label="Kembali" color="negative" @click="$store.state.mdl_kembalikan_kec = false"   />
                </q-card-actions>
                
   
        </q-card>
    </q-dialog>    
   

    <q-dialog v-model="$store.state.mdl_verifikasi_kab" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-primary">
                        <span class="q-ma-md text-white">VERIFIKASI</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-primary">
                        <a @click="$store.state.mdl_verifikasi_kab=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <q-separator />
 
                <q-card-section>
                    <div class="q-px-lg q-py-md">
                        <div class="abu-800 text-labels">Masukan Informasi Verifikasi</div>
                        <div class="abu-800 text-labels q-mb-xs">* Informasi ini berupa keterangan dari verifikator yang akan di lihat oleh pemohon</div>
                        <q-input v-model="form.keterangan_kabupaten" filled type="textarea" style="width:100%"/>
                        
                        <!-- <div class="abu-800 text-labels">Masukan Informasi Verifikasi</div>
                        <q-input v-model="form.keterangan_kabupaten" filled type="textarea" style="width:100%"/> -->
                        
                        <div class="abu-800 text-labels">Masukan File PDF</div> 
                        <q-file outlined square :dense="true" v-model="form.email_file" label="Upload Foto KTP" filled counter>
                            <template v-slot:prepend>
                                <q-icon name="attach_file" />
                            </template>
                        </q-file>  

                        <!-- <div class="abu-800 text-labels">Masukan pesan yang akan di kirim ke email pemohon</div>
                        <q-input v-model="form.email_html" filled type="textarea" style="width:100%"/>  -->

                    </div>
                </q-card-section>
                <q-separator />
    
                <q-card-actions align="center">
                    <q-btn size="sm" label="Kirim" color="primary" type="submit" @click="editDataKabupaten('diterima')" />
                    <q-btn size="sm" label="Kembali" color="negative" @click="$store.state.mdl_verifikasi_kab = false"   />
                </q-card-actions>
                
   
        </q-card>
    </q-dialog>    

    <q-dialog v-model="$store.state.mdl_kembalikan_kab" persistent>
        <q-card style="width: 700px; max-width: 80vw;"> 
            <div class="fit row wrap justify-center items-center content-start">
                <div class="col-11">
                    <div class="q-pa-md text-center bg-negative">
                        <span class="q-ma-md text-white">KEMBALIKAN</span>
                    </div>
                </div>
                <div class="col-1">
                    <div clickable class="q-pa-md text-center bg-negative">
                        <a @click="$store.state.mdl_kembalikan_kab=false" href="javascript:void(0)" style="text-decoration: none;">
                            <span class="q-ma-md text-white"><b>x</b></span>
                        </a>
                    </div>
                </div>

            </div>
            <q-separator />
 
                <q-card-section>
                    
                    <div class="q-px-lg q-py-md">
                        <div class="abu-800 text-labels">Masukan Informasi Pengembalian</div>
                        <div class="abu-800 text-labels q-mb-xs">* Informasi ini berupa keterangan dari verifikator mengapa data di kembalikan yang akan di lihat oleh pemohon</div>
                        <q-input v-model="form.keterangan_kabupaten" filled type="textarea" style="width:100%"/>
                    </div>

                </q-card-section>
                <q-separator />
    
                <q-card-actions align="center">
                    <q-btn size="sm" label="Tambah" color="primary" @click="editDataKabupaten('dikembalikan')" />
                    <q-btn size="sm" label="Kembali" color="negative" @click="$store.state.mdl_kembalikan_kab = false"   />
                </q-card-actions>
                
   
        </q-card>
    </q-dialog>    
   
    <!-- <MDL_FILEX :modal="$store.state.mdl_del" :filex="form.email_file"/> -->

    <q-dialog v-model="$store.state.mdl_lihat_file" persistent>
            <q-card style="width: 700px; max-width: 80vw;"> 
                <div class="fit row wrap justify-center items-center content-start">
                    <div class="col-11">
                        <div class="q-pa-md text-center bg-primary">
                            <span class="q-ma-md text-white">LIHAT FILE</span>
                        </div>
                    </div>
                    <div class="col-1">
                        <div clickable class="q-pa-md text-center bg-primary">
                            <a href="javascript:void(0)" style="text-decoration: none;" v-close-popup>
                                <span class="q-ma-md text-white"><b>x</b></span>
                            </a>
                        </div>
                    </div>

                </div>
                <q-separator />
 
                    <q-card-section style="height: 630px !important; width: auto">   
                        <q-img v-if="$store.state.gambarx" :src="$store.state.url.URL_APP+'uploads/'+$store.state.filex" spinner-color="white" style="height:auto; width: 100%" />
                        <object v-else :data="$store.state.url.URL_APP+'uploads/'+$store.state.filex" type="application/pdf" width="100%" height="600px">
                            <p>Apabila file tidak langsung terbuka, sliahkan klik <a :href="$store.state.url.URL_APP+'uploads/'+$store.state.filex"> link ini untuk ke PDF!</a></p>
                        </object> 
                    </q-card-section>
                    <q-separator />
        
                    <q-card-actions align="center"> 
                        <q-btn size="sm" label="Kembali" color="negative"  v-close-popup  />
                    </q-card-actions> 
            </q-card>
        </q-dialog> 
  
    <!-- ================================= MODAL =================================  -->
  
  </div>
   
  
  </template>
  
  
  
  <script>
   
    import FETCHING from "../../library/fetching";
    import UMUM from "../../library/umum";
    import uniqid from 'uniqid';
  
    export default {
        name: 'HelloWorld',
        components: {
    },
  
    data() {
        return {
          FETCHING    :FETCHING,
          UMUM        :UMUM,
  
          search          :'',
  
          list_data       :[],
  
          filter:{
              data_ke     : 1,
              page_limit  : 10,
              cari_value  : '',
              cari_level  : '',
              page_last   :  0,

              prov      :'74',
              kab       :'7412',
              kec       :'',
              deskel    :'',

              status    :'0',
          },


          form: { 
              id                            : '',
              file_lampiran                 : null,
              file_lampiranOld              : null,
              modul                         : 'sk_kelahiran1',
              m_provinsi                    : '74',
              m_kabupaten                   : '7412',
              m_kecamatan                   : '',
              m_desa_kelurahan              : '', 
              nama_kepala_keluarga          : '',
              no_kk                         : '',
              nama_bayi                     : '',
              jenis_kelamin_bayi            : '',
              tmpt_lahir_bayi               : '',
              tmpt_lahir_bayi_lainnya       : '',
              tempat_kelahiran_bayi         : '',
              nama_hari                     : '',
              tgl_lahir_bayi                : '',
              pukul                         : '',
              jenis_lahir                   : '',
              jenis_lahir_lainnya           : '',
              kelahiran_ke                  : '',
              penolong_kelahiran            : '',
              penolong_kelahiran_lainnya    : '',
              berat_bayi                    : '',
              panjang_bayi                  : '',
              nik_ibu                       : '',
              nama_ibu                      : '',
              tgl_lahir_ibu                     :'',
              pekerjaan_ibu                     :'',

              provinsi_ibu                      :'',
              kabupaten_ibu                     :'',
              kecamatan_ibu                     :'',
              des_kel_ibu                       :'',

              alamat_ibu                        :'',
              kewarganegaraan_ibu               :'',
              kewarganegaraan_ibu_lainnya       :'',
              tgl_catatan_perkawinan            :'',
              nik_ayah                          :'',
              nama_ayah                         :'',
              tgl_lahir_ayah                    :'',
              pekerjaan_ayah                    :'',

              provinsi_ayah                     :'',
              kabupaten_ayah                    :'',
              kecamatan_ayah                    :'',
              des_kel_ayah                      :'',

              alamat_ayah                       :'',
              kewarganegaraan_ayah              :'',
              kewarganegaraan_ayah_lainnya      :'',
              nik_pelapor                       :'',
              nama_pelapor                      :'',
              umur_pelapor                      :'',
              pekerjaan_pelapor                 :'',

              provinsi_pelapor                  :'',
              kabupaten_pelapor                 :'',
              kecamatan_pelapor                 :'',
              des_kel_pelapor                   :'',

              alamat_pelapor                    :'',
              
              nik_saksi1                        :'',
              nama_saksi1                       :'',
              umur_saksi1                       :'',
              pekerjaan_saksi1                  :'',

              provinsi_saksi1                   :'',
              kabupaten_saksi1                  :'',
              kecamatan_saksi1                  :'',
              des_kel_saksi1                    :'',

              alamat_saksi1                     :'',

              nik_saksi2                        :'',
              nama_saksi2                       :'',
              umur_saksi2                       :'',
              pekerjaan_saksi2                  :'',

              provinsi_saksi2                   :'',
              kabupaten_saksi2                  :'',
              kecamatan_saksi2                  :'',
              des_kel_saksi2                    :'',

              alamat_saksi2                     :'',  

                status                      : 0,
                keterangan                  : 0,
                keterangan_deskel           : '',
                keterangan_kecamatan        : '',
                keterangan_kabupaten        : '',
                status_deskel               : 0,
                status_kecamatan            : 0,
                status_kabupaten            : 0,
                createdBy                   : '',
                createdAt                   : '',
                createdAt_deskel            : '',
                createdAt_kecamatan         : '',
                createdAt_kabupaten         : '',

              file_terbitan                     : null,
              emailPemohon                      :'',
              email_file                        :null,
              email_fileOld                     :'',
              email_from                        :'',
              email_to                          :'',
              email_subject                     :'',
              email_html                        :'',
              
            
            },
  



            form_view : {
                uraian_1_provinsi          : "",
                uraian_1_kabupaten         : "",
                uraian_1_kecamatan         : "",
                uraian_1_desa              : "",    

                uraian_ibu_provinsi          : "",
                uraian_ibu_kabupaten         : "",
                uraian_ibu_kecamatan         : "",
                uraian_ibu_desa              : "",    
                uraian_ayah_provinsi          : "",
                uraian_ayah_kabupaten         : "",
                uraian_ayah_kecamatan         : "",
                uraian_ayah_desa              : "",    
                uraian_pelapor_provinsi          : "",
                uraian_pelapor_kabupaten         : "",
                uraian_pelapor_kecamatan         : "",
                uraian_pelapor_desa              : "",    
                uraian_saksi1_provinsi          : "",
                uraian_saksi1_kabupaten         : "",
                uraian_saksi1_kecamatan         : "",
                uraian_saksi1_desa              : "",    
                uraian_saksi2_provinsi          : "",
                uraian_saksi2_kabupaten         : "",
                uraian_saksi2_kecamatan         : "",
                uraian_saksi2_desa              : "",    
            },
  
            l_prov        :[],
            l_kab         :[],
            l_kec         :[],
            l_deskel      :[], 

            f_prov          : [],
            f_kab           : [],
            f_kec           : [],
            f_deskel        : [],

            l_prov_ibu      : [],
            l_kab_ibu       : [],
            l_kec_ibu       : [],
            l_deskel_ibu    : [],

            l_prov_ayah      : [],
            l_kab_ayah       : [],
            l_kec_ayah       : [],
            l_deskel_ayah    : [],

            l_prov_pelapor      : [],
            l_kab_pelapor       : [],
            l_kec_pelapor       : [],
            l_deskel_pelapor    : [],

            l_prov_saksi1      : [],
            l_kab_saksi1       : [],
            l_kec_saksi1       : [],
            l_deskel_saksi1    : [],

            l_prov_saksi2      : [],
            l_kab_saksi2       : [],
            l_kec_saksi2       : [],
            l_deskel_saksi2    : [],
  
        }
    },
    methods: { 
        klikOk(){
            console.log('Ok Ok');
        },
  
        async getView(){  
            this.$store.commit('loadingStart')
            var a = await FETCHING.fetch_view(this.$store.state.url.URL_PERMOHONAN_KELAHIRAN+'view', JSON.stringify({
                data_ke     : this.filter.data_ke,
                page_limit  : this.filter.page_limit,
                cari_value  : this.filter.cari_value,
                status      : this.filter.status,

                f_prov      : this.filter.prov,
                f_kab       : this.filter.kab,
                f_kec       : this.filter.kec,
                f_des       : this.filter.deskel,

            })
            )
            this.list_data          = a.data
            this.filter.page_last   = a.jmlData;
            console.log(a);
            this.$store.commit('loadingStop')
        },
  
        async addData(){  
            
            this.$store.commit('loadingStart')

            var formData = new FormData(); 
            formData.append("data", JSON.stringify(this.form));
            formData.append("file", this.form.file); 
            formData.append("file_lampiran", this.form.file_lampiran);

            var a = await FETCHING.fetch_postx_file(this.$store.state.url.URL_PERMOHONAN_KELAHIRAN+'addData', formData)         
            this.$store.state.mdl_add = false
            await UMUM.notifikasi(a)
            this.$store.commit('loadingStop') 
            this.getView();
        }, 
        async editData(){  
            this.$store.commit('loadingStart')
            var formData = new FormData(); 
            formData.append("data", JSON.stringify(this.form));
            formData.append("file", this.form.file); 
            formData.append("file_lampiran", this.form.file_lampiran);

            var a = await FETCHING.fetch_postx_file(this.$store.state.url.URL_PERMOHONAN_KELAHIRAN+'editData', formData)         
            this.$store.state.mdl_add = false
            await UMUM.notifikasi(a)
            this.$store.commit('loadingStop') 
            this.getView();
        }, 
  
        async removeData(){  
            this.$store.commit('loadingStart')
            var a = await FETCHING.fetch_postx(this.$store.state.url.URL_PERMOHONAN_KELAHIRAN+'removeData', JSON.stringify(this.form) ) 
            this.$store.commit('loadingStop')
            await UMUM.notifikasi(a)
            this.$store.state.mdl_del = false
            this.getView();  
        },
  
        async editDataKecamatan(status){  
            this.$store.commit('loadingStart')
            var a = await FETCHING.fetch_postx(this.$store.state.url.URL_PERMOHONAN_KELAHIRAN+'editDataKecamatan', JSON.stringify({
                id : this.form.id,
                keterangan_kecamatan : this.form.keterangan_kecamatan,
                createdAt_kecamatan : UMUM.NOW(),
                kategorix : status
            })) 
            this.$store.commit('loadingStop')
            await UMUM.notifikasi(a)
            this.$store.state.mdl_del = false
            this.getView();  
        },
        async editDataKabupaten(status){  
            this.form.email_html = this.form.keterangan_kabupaten
            this.$store.commit('loadingStart')

            var formData = new FormData();   
            formData.append("id", this.form.id);
            formData.append("keterangan_kabupaten", this.form.keterangan_kabupaten);
            formData.append("createdAt_kabupaten", UMUM.NOW());
            formData.append("kategorix", status);

            formData.append("email_file", this.form.email_file); 
            formData.append("email_fileOld", this.form.email_fileOld);  
            formData.append("email_to", this.form.email_to)
            formData.append("email_subject", this.form.email_subject)  
            formData.append("email_html", this.form.email_html)


            var a = await FETCHING.fetch_postx_file(this.$store.state.url.URL_PERMOHONAN_KELAHIRAN+'editDataKabupaten', formData) 
            this.$store.commit('loadingStop')
            
            
            await UMUM.notifikasi(a)
            this.$store.state.mdl_del = false
            this.getView();  
        },
  
        async loadListData(){
            this.$store.commit('loadingStart')
            this.l_prov         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.l_kab          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.form.m_provinsi }))
            this.l_kec          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.form.m_kabupaten }))
            this.l_deskel       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.form.m_kecamatan }))

            this.f_prov         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.f_kab          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.filter.prov }))
            this.f_kec          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.filter.kab }))
            this.f_deskel       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.filter.kec }))

            this.l_prov_ibu         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.l_kab_ibu          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.form.provinsi_ibu }))
            this.l_kec_ibu          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.form.kabupaten_ibu }))
            this.l_deskel_ibu       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.form.kecamatan_ibu }))

            this.l_prov_ayah         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.l_kab_ayah          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.form.provinsi_ayah }))
            this.l_kec_ayah          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.form.kabupaten_ayah }))
            this.l_deskel_ayah       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.form.kecamatan_ayah }))

            this.l_prov_pelapor         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.l_kab_pelapor          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.form.provinsi_pelapor }))
            this.l_kec_pelapor          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.form.kabupaten_pelapor }))
            this.l_deskel_pelapor       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.form.kecamatan_pelapor }))

            this.l_prov_saksi1         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.l_kab_saksi1          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.form.provinsi_saksi1 }))
            this.l_kec_saksi1          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.form.kabupaten_saksi1 }))
            this.l_deskel_saksi1       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.form.kecamatan_saksi1 }))

            this.l_prov_saksi2         = await FETCHING.fetch_getx(this.$store.state.url.URL_GET_WILAYAH + 'provinsi')
            this.l_kab_saksi2          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kabupaten', JSON.stringify({ cari:this.form.provinsi_saksi2 }))
            this.l_kec_saksi2          = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'kecamatan', JSON.stringify({ cari:this.form.kabupaten_saksi2 }))
            this.l_deskel_saksi2       = await FETCHING.fetch_postx(this.$store.state.url.URL_GET_WILAYAH + 'deskel', JSON.stringify({ cari:this.form.kecamatan_saksi2 }))

            this.$store.commit('loadingStop') 
        },
  
        selectData(data){ 
           console.log(data);
          //  m_desa_kelurahan
  
            this.form.id                            = data.id
            this.form.file_lampiran                 = data.file_lampiran
            this.form.file_lampiranOld              = data.file_lampiranOld
            this.form.modul                         = data.modul
            this.form.m_provinsi                    = data.m_provinsi
            this.form.m_kabupaten                   = data.m_kabupaten
            this.form.m_kecamatan                   = data.m_kecamatan
            this.form.m_desa_kelurahan              = data.m_desa_kelurahan
            this.form.nama_kepala_keluarga          = data.nama_kepala_keluarga
            this.form.no_kk                         = data.no_kk
            this.form.nama_bayi                     = data.nama_bayi
            this.form.jenis_kelamin_bayi            = data.jenis_kelamin_bayi
            this.form.tmpt_lahir_bayi               = data.tmpt_lahir_bayi
            this.form.tmpt_lahir_bayi_lainnya       = data.tmpt_lahir_bayi_lainnya
            this.form.tempat_kelahiran_bayi         = data.tempat_kelahiran_bayi
            this.form.nama_hari                     = data.nama_hari
            this.form.tgl_lahir_bayi                = data.tgl_lahir_bayi
            this.form.pukul                         = data.pukul
            this.form.jenis_lahir                   = data.jenis_lahir
            this.form.jenis_lahir_lainnya           = data.jenis_lahir_lainnya
            this.form.kelahiran_ke                  = data.kelahiran_ke
            this.form.penolong_kelahiran            = data.penolong_kelahiran
            this.form.penolong_kelahiran_lainnya    = data.penolong_kelahiran_lainnya
            this.form.berat_bayi                    = data.berat_bayi
            this.form.panjang_bayi                  = data.panjang_bayi
            this.form.nik_ibu                       = data.nik_ibu
            this.form.nama_ibu                      = data.nama_ibu
            this.form.tgl_lahir_ibu                 = data.tgl_lahir_ibu
            this.form.pekerjaan_ibu                 = data.pekerjaan_ibu
            this.form.provinsi_ibu                  = data.provinsi_ibu
            this.form.kabupaten_ibu                 = data.kabupaten_ibu
            this.form.kecamatan_ibu                 = data.kecamatan_ibu
            this.form.des_kel_ibu                   = data.des_kel_ibu
            this.form.alamat_ibu                    = data.alamat_ibu
            this.form.kewarganegaraan_ibu           = data.kewarganegaraan_ibu
            this.form.kewarganegaraan_ibu_lainnya   = data.kewarganegaraan_ibu_lainnya
            this.form.tgl_catatan_perkawinan        = data.tgl_catatan_perkawinan
            this.form.nik_ayah                      = data.nik_ayah
            this.form.nama_ayah                     = data.nama_ayah
            this.form.tgl_lahir_ayah                = data.tgl_lahir_ayah
            this.form.pekerjaan_ayah                = data.pekerjaan_ayah
            this.form.provinsi_ayah                 = data.provinsi_ayah
            this.form.kabupaten_ayah                = data.kabupaten_ayah
            this.form.kecamatan_ayah                = data.kecamatan_ayah
            this.form.des_kel_ayah                  = data.des_kel_ayah
            this.form.alamat_ayah                   = data.alamat_ayah
            this.form.kewarganegaraan_ayah          = data.kewarganegaraan_ayah
            this.form.kewarganegaraan_ayah_lainnya  = data.kewarganegaraan_ayah_lainnya
            this.form.nik_pelapor                   = data.nik_pelapor
            this.form.nama_pelapor                  = data.nama_pelapor
            this.form.umur_pelapor                  = data.umur_pelapor
            this.form.pekerjaan_pelapor             = data.pekerjaan_pelapor
            this.form.provinsi_pelapor              = data.provinsi_pelapor
            this.form.kabupaten_pelapor             = data.kabupaten_pelapor
            this.form.kecamatan_pelapor             = data.kecamatan_pelapor
            this.form.des_kel_pelapor               = data.des_kel_pelapor
            this.form.alamat_pelapor                = data.alamat_pelapor
            this.form.nik_saksi1                    = data.nik_saksi1
            this.form.nama_saksi1                   = data.nama_saksi1
            this.form.umur_saksi1                   = data.umur_saksi1
            this.form.pekerjaan_saksi1              = data.pekerjaan_saksi1
            this.form.provinsi_saksi1               = data.provinsi_saksi1
            this.form.kabupaten_saksi1              = data.kabupaten_saksi1
            this.form.kecamatan_saksi1              = data.kecamatan_saksi1
            this.form.des_kel_saksi1                = data.des_kel_saksi1
            this.form.alamat_saksi1                 = data.alamat_saksi1
            this.form.nik_saksi2                    = data.nik_saksi2
            this.form.nama_saksi2                   = data.nama_saksi2
            this.form.umur_saksi2                   = data.umur_saksi2
            this.form.pekerjaan_saksi2              = data.pekerjaan_saksi2
            this.form.provinsi_saksi2               = data.provinsi_saksi2
            this.form.kabupaten_saksi2              = data.kabupaten_saksi2
            this.form.kecamatan_saksi2              = data.kecamatan_saksi2
            this.form.des_kel_saksi2                = data.des_kel_saksi2
            this.form.alamat_saksi2                 = data.alamat_saksi2

            this.form.status                        = data.status
            this.form.keterangan                    = data.keterangan
            this.form.keterangan_deskel             = data.keterangan_deskel
            this.form.keterangan_kecamatan          = data.keterangan_kecamatan
            this.form.keterangan_kabupaten          = data.keterangan_kabupaten
            this.form.status_deskel                 = data.status_deskel
            this.form.status_kecamatan              = data.status_kecamatan
            this.form.status_kabupaten              = data.status_kabupaten
            this.form.createdBy                     = data.createdBy
            this.form.createdAt                     = data.createdAt
            this.form.createdAt_deskel              = data.createdAt_deskel
            this.form.createdAt_kecamatan           = data.createdAt_kecamatan
            this.form.createdAt_kabupaten           = data.createdAt_kabupaten  

            this.form.file_terbitan                 = data.file_terbitan
            this.form.emailPemohon                  = data.emailPemohon
            this.form.email_file                    = data.email_file
            this.form.email_fileOld                 = data.email_fileOld
            this.form.email_from                    = data.email_from
            this.form.email_to                      = data.email_to
            this.form.email_subject                 = data.email_subject
            this.form.email_html                    = data.email_html    
            
            this.form_view.uraian_1_provinsi        = data.uraian_1_provinsi
            this.form_view.uraian_1_kabupaten       = data.uraian_1_kabupaten
            this.form_view.uraian_1_kecamatan       = data.uraian_1_kecamatan
            this.form_view.uraian_1_desa            = data.uraian_1_desa

            this.form_view.uraian_ibu_provinsi        = data.uraian_ibu_provinsi
            this.form_view.uraian_ibu_kabupaten       = data.uraian_ibu_kabupaten
            this.form_view.uraian_ibu_kecamatan       = data.uraian_ibu_kecamatan
            this.form_view.uraian_ibu_desa            = data.uraian_ibu_desa
            this.form_view.uraian_ayah_provinsi       = data.uraian_ayah_provinsi
            this.form_view.uraian_ayah_kabupaten      = data.uraian_ayah_kabupaten
            this.form_view.uraian_ayah_kecamatan      = data.uraian_ayah_kecamatan
            this.form_view.uraian_ayah_desa           = data.uraian_ayah_desa
            this.form_view.uraian_pelapor_provinsi    = data.uraian_pelapor_provinsi
            this.form_view.uraian_pelapor_kabupaten   = data.uraian_pelapor_kabupaten
            this.form_view.uraian_pelapor_kecamatan   = data.uraian_pelapor_kecamatan
            this.form_view.uraian_pelapor_desa        = data.uraian_pelapor_desa
            this.form_view.uraian_saksi1_provinsi     = data.uraian_saksi1_provinsi
            this.form_view.uraian_saksi1_kabupaten    = data.uraian_saksi1_kabupaten
            this.form_view.uraian_saksi1_kecamatan    = data.uraian_saksi1_kecamatan
            this.form_view.uraian_saksi1_desa         = data.uraian_saksi1_desa
            this.form_view.uraian_saksi2_provinsi     = data.uraian_saksi2_provinsi
            this.form_view.uraian_saksi2_kabupaten    = data.uraian_saksi2_kabupaten
            this.form_view.uraian_saksi2_kecamatan    = data.uraian_saksi2_kecamatan
            this.form_view.uraian_saksi2_desa         = data.uraian_saksi2_desa            
            
  
            this.loadListData()
        },

        checkStatus(data){
            if (data == 2) {
                // return 'bg-deep-orange-4'
            } else if (data == 1) {
                // return 'bg-teal-2'
            }else{

            }
        },

        checkKelasUser(data){
            var prof = JSON.parse(localStorage.profile) 
            var kelas = prof.profile.kelas

            if (kelas == data) {
                return true
            } 
            else{
                return false

            }
        },

        cek_btn_editDel(data){  
            var a = data.status 
            console.log();
            // var status_kab = data.status_kabupaten  

            var prof = JSON.parse(localStorage.profile) 
            var kelas = prof.profile.kelas

            if (kelas == 'vertikal' || kelas == 'pemohon') {
                if (a == 1) {
                    return true
                }else{
                    return false
                }
            }
        },

        bukaTab(linknya){
            window.open(linknya);
        },

        lihatFilex(filename){
            // console.log(filename);
            var ext = filename.split('.').pop().toLowerCase(); 
            if (ext == "pdf") {
                this.$store.state.pdfx = true
                this.$store.state.filex = filename

                // return true
            } else {
                this.$store.state.gambarx = false
                this.$store.state.filex = filename 
            } 

        },
        
        isPdfOrImage(filename) {
        // Mendapatkan ekstensi file
            var ext = filename.split('.').pop().toLowerCase();

            // Array ekstensi gambar yang diizinkan
            var imageExts = ['jpg', 'jpeg', 'png', 'gif'];

            // Memeriksa apakah ekstensi adalah PDF atau gambar
            if (ext === 'pdf' ) {
                console.log(ext);
                return true;
            }else{
                console.log(ext);
                return false
            }
        }

        
  
  
    },
    mounted() {
        this.getView()
        this.loadListData()
        this.checkKelasUser()
    },
    }
  
  </script>
  
  
   